<template>
  <div class="payment-onetime-offer-product" v-loading="loading">
    <h2>{{ productData.planName }}</h2>
    <div class="inner">
      <div class="point-cover">
        <p class="generally">
          通常：<span>{{ mainProductData.price * bulkBuyingMonths | localeNum }}</span
          >円（税込）で<br class="sp-only" /><span>{{
            mainProductData.point * bulkBuyingMonths | localeNum
          }}</span
          >ポイント
        </p>
        <div class="triangle">▼</div>
        <p class="bulk-buying">
          まとめ買い：<br class="sp-only" /><span>{{
            productData.price | localeNum
          }}</span
          ><span class="small">円（税込）</span>で<br class="sp-only" /><span>{{
            productData.point | localeNum
          }}</span
          ><span class="small">ポイント</span>
        </p>
      </div>
      <div class="gain">
        <p>
          <span> {{ productData.discountData.percentage | localeNum }}</span
          >%<br />オトク
        </p>
      </div>
      <el-button
        @click="settleOneTimeOffer"
        type="primary"
        style="background-color: #1c6db5; border: none"
        >{{ productData.planName }}をする</el-button
      >
      <p v-html="pointComent" class="coment"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    temporaryRegisterToken: String,
    productId: String,
    pointComent: String,
  },
  data() {
    return {
      loading: false,
      bulkBuyingMonths: 0,
    };
  },
  computed: {
    ...mapGetters(["rt", "mainProductId"]),
    productData() {
      return this.$store.getters.productData(this.productId);
    },
    mainProductData() {
      return this.$store.getters.productData(this.mainProductId);
    },
  },
  created() {
    //
    switch (this.productId) {
      case "34":
        this.bulkBuyingMonths = 2;
        break;

      case "36":
        this.bulkBuyingMonths = 6;
        break;

      default:
        // no action.
        break;
    }
  },
  methods: {
    settleOneTimeOffer() {
      if (
        confirm(
          "クレカ情報の再入力なく、まとめ買いの決済をします。よろしかったですか？"
        )
      ) {
        this.loading = true;

        //
        let params = new URLSearchParams();
        params.append("token", this.temporaryRegisterToken);
        params.append("product_id", this.productId);
        this.$axios
          .post(`${this.rt}/l/api/payment/settle-thanks-page-offer`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$router.push("/complete2/ch3HQAKNvRLHUBBLGKLCaCBxJjbCUTxW");
            } else if (response.data.status === 400) {
              alert("決済に失敗しました。");
            } else if (response.data.status === 409) {
              alert(response.data.message);
            } else if (response.data.status === 440) {
              alert(response.data.message);
              location.reload();
            } else {
              alert(this.apiErrorMessage);
            }
            this.loading = false;
            return;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            return;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-onetime-offer-product {
  text-align: center;
  margin: 50px 0;
  border: solid 5px #f15e62;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    margin-right: 20px;
    margin-left: 20px;
  }
  h2 {
    background-color: #f15e62;
    color: white;
    margin: -1px;
    padding: 15px 0;
    vertical-align: bottom;
    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
  .inner {
    padding: 20px;
    position: relative;
    .point-cover {
      width: 55%;
      margin: 0 auto;
      text-align: left;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .generally {
        font-size: 16px;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
          letter-spacing: -0.03em;
          text-align: center;
        }
        span {
          font-size: 26px;
          margin-right: 5px;
          margin-left: 5px;
          @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-right: 2px;
            margin-left: 2px;
          }
        }
      }
      .triangle {
        font-size: 30px;
        text-align: center;
      }
      .bulk-buying {
        font-weight: bold;
        margin-top: 0;
        @media screen and (max-width: 767px) {
          line-height: 2.2em;
        }
        span {
          color: #f15e62;
          font-size: 34px;
        }
        .small {
          font-size: 16px;
        }
      }
    }
    .gain {
      position: absolute;
      width: 120px;
      height: 120px; /*高さ*/
      border-radius: 50%; /*角丸*/
      border: 4px solid #1c6db5;
      top: 20px;
      right: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
        top: 40%;
        right: 10px;
      }

      p {
        font-weight: bold;
        color: #1c6db5;
        font-size: 20px;
        line-height: 1.5em;
        margin: 10px 0 0 5px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
          margin: 5px 0 0 5px;
        }
        span {
          font-size: 36px;
          @media screen and (max-width: 767px) {
            font-size: 26px;
          }
        }
      }
    }
  }
  .el-button {
    padding: 15px 50px;
    @media screen and (max-width: 767px) {
      padding: 10px 0;
      width: 98%;
    }
    &:hover {
      background-color: #75a6ce !important;
    }
  }
  .sp-only {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .coment {
    @media screen and (max-width: 767px) {
      line-height: 1.5em;
      min-height: 50px;
    }
  }
}
</style>
