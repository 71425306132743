<template>
  <div class="FormButton">
      <a :href="link">
      <div class="form_btn">
        <p>{{ButtonText}}</p>
        <img :src="rt+'/img/lp/more_btn_mark_wt.png'" alt="矢印アイコン" />
      </div>
      </a>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "FormButton",
  props: {
      ButtonText:String,
      link:String
  },
  data() {
    return {
      rt: store.state.rt
    };
  }
};
</script>

<style lang="scss" scoped>

.FormButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_btn {
  width: 550px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #1c6db5;
  border-radius: 50px;
  @media screen and (max-width: $max-width) {
      width: 100%;
      padding: 0 10px;
    }
  p {
    font-weight: bold;
    color: white;
    font-size: 23px;
    letter-spacing: 0.05em;
    margin: 15px 0 15px 10px;
    @media screen and (max-width: $max-width) {
      font-size: 16px;
    }
  }
  img {
    width: 50px;
    @media screen and (max-width: $max-width) {
      width: 25px;
    }
  }
}
a:hover {
    opacity: 0.8;
}

</style>
