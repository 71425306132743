<template>
  <div class="complete">
    <div class="wrap" style="margin-top: 50px">
      <!--  -->
      <div class="red-bold imp">
        <p>※ご注意ください※</p>
        <p style="margin-top: 20px">
          当画面をまだ閉じないでください。<br
            class="sp-only"
          />必ずご確認ください。
        </p>
        <p class="sp-mt">
          もっともお得にアドタスカルを利用できる、ポイントのまとめ買いのお手続きが<br
            class="sp-only"
          />まだ完了していません。
        </p>
      </div>
      <!-- https://qiita.com/potdig/items/ede34833ccb8f46aa8d4 -->
      <template v-if="displayOnetimeOffer === true">
        <h1 class="top-coment">
          ポイントのまとめ買いで、<br class="sp" />今だけお得にアドタスカルが<br
            class="sp"
          />利用できます！
        </h1>
        <div>
          <p>
            当ページ限定でアドタスカルのポイントをお値打ち価格でまとめ買いできます。ポイントの有効期限は購入後1年間です。
          </p>
          <p>
            ポイントのまとめ買いをご希望の場合は、以下からご希望のプランの決済をお願いいたします。
          </p>
          <p>
            なお、ポイントのまとめ買いは、初回お試し決済後から{{
              thanksOnetimeOfferMinutes
            }}分間のみ可能となっています。{{
              thanksOnetimeOfferMinutes
            }}分間を経過し、以下のタイマーの表示が0になりましたら、ポイントのまとめ買いはできなくなります。
          </p>
        </div>
        <div class="countdown-timer-box">
          <div class="timer-speech-bubble">
            <img :src="`${rt}/img/lp/pa2002209-001.png`" alt="ふきだし１" />
            <h2>ポイントの<br class="sp-only" />まとめ買い残り時間</h2>
            <img :src="`${rt}/img/lp/pa2002209-002.png`" alt="ふきだし2" />
          </div>
          <countdown
            class="countdown"
            :start-time="new Date()"
            :end-time="new Date(thanksOnetimeOfferEndsAtUnix)"
            auto-start
          >
            <template v-slot:process="{ timeObj }">
              <p class="timer">{{ timeObj.m }}:{{ timeObj.s }}</p>
            </template>
            <template v-slot:finish>
              <p class="timer">00:00</p>
            </template>
          </countdown>
        </div>
        <OnetimeOfferProduct
          :temporaryRegisterToken="temporaryRegisterToken"
          :productId="'34'"
          :pointComent="'まずはオトクに<br class=sp-only>アドタスカルを使ってみたい人へ'"
        />
        <OnetimeOfferProduct
          :temporaryRegisterToken="temporaryRegisterToken"
          :productId="'36'"
          :pointComent="'アドタスカルをガッツリ使いたい人へ'"
        />
        <div class="red-bold imp2">
          <p>
            ここまでの内容について<br
              class="sp-only"
            />ご確認ありがとうございました。
          </p>
          <p>
            まとめ買いをする場合は、<br
              class="sp-only"
            />ご希望のプランの決済をお願いします。
          </p>
          <p>
            まとめ買いをしない場合は、<br
              class="sp-only"
            />当ページをお閉じください。
          </p>
        </div>
      </template>
    </div>
    <div class="wrap">
      <Notification :type="'default'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnetimeOfferProduct from "@/components/Complete/OnetimeOfferProduct.vue";
import Notification from "@/components/Complete/Notification.vue";

export default {
  components: {
    OnetimeOfferProduct,
    Notification,
  },
  props: {
    temporaryRegisterToken: String,
  },
  data() {
    return {
      displayOnetimeOffer: false,
      thanksOnetimeOfferMinutes: 0,
      thanksOnetimeOfferEndsAtUnix: 0,
    };
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  created() {
    this.getThanksData();
  },
  methods: {
    getThanksData() {
      this.$axios
        .get(
          `${this.rt}/l/api/register/get-thanks-data?temporary_register_token=${this.temporaryRegisterToken}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.thanksOnetimeOfferMinutes =
              response.data.thanksOnetimeOfferMinutes;
            this.displayOnetimeOffer = response.data.displayOnetimeOffer;
            this.thanksOnetimeOfferEndsAtUnix =
              response.data.thanksOnetimeOfferEndsAtUnix * 1000; // jsとPHPでunixは3桁ズレるので
          } else {
            this.$router.push("/");
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.imp {
  p {
    font-size: 22px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .sp-mt {
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
}
.imp2 {
  p {
    @media screen and (max-width: 767px) {
      font-size: 19px;
      margin-bottom: 40px;
    }
  }
}
.top-coment {
  font-size: 32px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}
.countdown-timer-box {
  .timer-speech-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    img {
      width: 40px;
      @media screen and (max-width: 767px) {
        width: 30px;
      }
    }
  }
  h2 {
    font-size: 30px;
    text-align: center;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      font-size: 24px;
      padding: 0 20px;
    }
  }
  .countdown {
    p.timer {
      font-size: 120px;
      text-align: center;
      color: #1c6db5;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 100px;
        line-height: 1em;
      }
    }
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
</style>
