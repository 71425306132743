<template>
  <footer>
    <div class="category">
      <div class="block">
        <ul>
          <li>
            -
            <router-link to="/">トップページ</router-link>
          </li>
          <li>
            -
            <router-link to="/google-cv">Google広告成果連携ツール</router-link>
          </li>
          <li>
            -
            <router-link to="/yahoo-cv">Yahoo!検索広告成果連携ツール</router-link>
          </li>
          <li>
            -
            <router-link to="/yda-cv">YDA成果連携ツール</router-link>
          </li>
          <li>
            -
            <router-link to="/microsoft-cv">Microsoft広告成果連携ツール</router-link>
          </li>
        </ul>
      </div>
      <div class="block">
        <!-- <ul>
          <li>
            -
            <router-link to="/popup">ポップアップ</router-link>
          </li>
          <li>
            -
            <router-link to="/overlay">オーバーレイ表示</router-link>
          </li>
        </ul>-->
      </div>
      <div class="block">
        <ul>
          <li>
            -
            <router-link to="/contact-form">お問い合わせ</router-link>
          </li>
          <li>
            -
            <router-link to="/kiyaku">利用規約</router-link>
          </li>
          <li>
            -
            <router-link to="/tokuteisho">特定商取引法に基づく表記</router-link>
          </li>
          <li>
            -
            <router-link to="/privacy-policy">プライバシーポリシー</router-link>
          </li>
        </ul>
      </div>
    </div>
    <p class="pc">Copyright ©adtasukaru.com All Rights Reserved.</p>
    <p class="sp">
      Copyright ©adtasukaru.com
      <br />All Rights Reserved.
    </p>
    <!-- <p class="pink" style="margin-top: 15px;color: #f56c6c;font-weight: bold;">※正常に表示されない場合は、ブラウザを更新してください。</p> -->
  </footer>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
export default {
  name: "category",
  props: {
    msg: String
  }
};
</script>

<style lang="scss" scoped>
footer {
  padding: 50px 0 30px;
  // height: 70px;
  background: #1c6db5;
  // display:flex;
  // justify-content: center;
  text-align: center;
  @media screen and (max-width: $max-width) {
    padding-top: 30px;
  }

  .category {
    display: flex;
    justify-content: space-around;
    // width: 1100px;
    width: 850px;
    margin: 0 auto 30px;
    @media screen and (max-width: $max-width) {
      width: 100%;
      flex-wrap: wrap;
    }
    .block {
      @media screen and (max-width: $max-width) {
        width: 70%;
      }
      ul {
        @media screen and (max-width: $max-width) {
          padding-left: 20px;
        }

        li {
          color: white;
          line-height: 2em;
          text-align: left;
          list-style: none;
          @media screen and (max-width: $max-width) {
            width: 100%;
          }
        }
      }
    }
  }

  p {
    color: #fff;
    margin: 0;
  }
  a {
    color: white;
  }
  a:hover {
    opacity: 0.8;
  }
}
</style>
