<template>
  <div class="privileges">
    <div class="head">
      <span>特典{{ privilegeData.no }}</span>
    </div>
    <div class="main">
      <span class="privilege_title">{{ privilegeData.title }}</span>
      <div class="privilege_detail">{{ privilegeData.text1 }}</div>
      <img
        :src="`${rt}/img/lp/${privilegeData.imagePath}`"
        :alt="`${privilegeData.imageAlt}`"
      />
    </div>
    <div class="foot">
      <span>{{ privilegeData.text2 }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Privileges",
  props: {
    privilegeData: Array,
  },
  data() {
    return {};
  },
  components: {
    // Template
  },
  computed: {
    ...mapGetters(["rt"]),
  },
};
</script>

<style lang="scss" scoped>
.privileges {
  padding: 0 4vw 1px 4vw;
  background-color: #f2f4f4;
  text-align: center;
  margin: 0 0 50px 0;
  .head {
    padding: 20px 0;
    span {
      background-color: #f56c6c;
      color: #ffffff;
      padding: 5px 10px;
      display: inline-block;
      margin: 10px;
      font-weight: bold;
    }
  }
  .main {
    margin: 0 0 30px 0;
    .privilege_title {
      font-size: 25px;
      font-weight: bold;
      border-bottom: 4px solid #f56c6c;
      padding: 0 11px 20px 11px;
      display: inline-block;
    }
    .privilege_detail {
      margin: 40px 0;
    }
    img {
      width: -webkit-fill-available;
    }
  }
  .foot {
    text-align: left;
    margin: 0 0 60px 0;
    white-space: pre-wrap;
  }
}
</style>
