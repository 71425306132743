<template>
  <div class="PopupInstallation">
    <div class="wrap">
    <p class="title">設置の流れ</p>
    <div class="step_all">
      <div class="step_cover" :style="{ backgroundImage: 'url(' + rt + '/img/lp/bg_img.png)' }">
        <div class="step">
          <p class="number"><span class="STEP">Step</span>01</p>
        </div>
        <div class="step_txt_title">
          <p>お申し込み</p>
        </div>
        <p class="step_txt">お申込み後、すぐにアカウントが発行され、すぐにご利用できます。</p>
      </div>
      <div class="step_cover" :style="{ backgroundImage: 'url(' + rt + '/img/lp/bg_img.png)' }">
        <div class="step">
          <p class="number"><span class="STEP">Step</span>02</p>
        </div>
        <div class="step_txt_title">
          <p>サイト登録</p>
        </div>
        <p class="step_txt">ポップアップを設置したいサイトを登録します。</p>
      </div>
      <div class="step_cover" :style="{ backgroundImage: 'url(' + rt + '/img/lp/bg_img.png)' }">
        <div class="step">
          <p class="number"><span class="STEP">Step</span>03</p>
        </div>
        <div class="step_txt_title">
          <p class="pc">
            ポップアップ
            <br />作成
          </p>
          <p class="sp">
            ポップアップ作成
          </p>
        </div>
        <p class="step_txt">表示したいポップアップをエディタ機能で作成、または、画像指定します。</p>
      </div>
      <div class="step_cover" :style="{ backgroundImage: 'url(' + rt + '/img/lp/bg_img.png)' }">
        <div class="step">
          <p class="number"><span class="STEP">Step</span>04</p>
        </div>
        <div class="step_txt_title">
          <p>
            タグの発行と
            <br />貼り付け
          </p>
        </div>
        <p class="step_txt">発行されたタグを広告サイトに貼り付けます。</p>
      </div>
      <div class="step_cover" :style="{ backgroundImage: 'url(' + rt + '/img/lp/bg_img.png)' }">
        <div class="step">
          <p class="number"><span class="STEP">Step</span>05</p>
        </div>
        <div class="step_txt_title">
          <p>利用開始</p>
        </div>
        <p class="step_txt">ポップアップの利用がスタートとなります。</p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "PopupInstallation",
  props: {},
  data() {
    return {
      rt: store.state.rt
    };
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 80px;
  @media screen and (max-width: $max-width) {
    margin-top: 50px;
  }
}
.step_all {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .step_cover {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 200px;
    height: 280px;
    padding: 0 20px;
    box-sizing: border-box;
    // position: relative;
    @media screen and (max-width: $max-width) {
      width: 90%;
      height: 240px;
      background-size: 100% 100%;
    }

    .step {
      padding-top: 15px;
      .number {
        font-size: 28px;
        font-weight: bold;
        margin: 20px 0 30px;
        // padding-bottom: 10px;
        color: #1c6db5;
        @media screen and (max-width: $max-width) {
          font-size: 30px;
        }
      .STEP {
        font-size: 14px;
        font-weight: bold;
        color: #1c6db5;
        margin-right: 5px;
        @media screen and (max-width: $max-width) {
          font-size: 16px;
        }
      }
      }
    }
    .step_txt_title {
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.1em;
      margin: 10px 0;
      height: 52px;
      @media screen and (max-width: $max-width) {
        height: auto;
          
        }
      p {
        line-height: 1.2em;
        font-size: 22px;
        color: #1c6db5;
        @media screen and (max-width: $max-width) {
          font-size: 22px;
          margin: 0;
        }
      }
    }
    .step_txt {
      font-size: 14px;
      // padding-top: 15px;
      @media screen and (max-width: $max-width) {
        font-size: 16px;
        padding: 0 20px;
      }
    }
  }
}
</style>
