<template>
  <div class="QA">
    <p class="title">
      {{ tools[tool.codeCamel].name2 }}<br class="sp" />についてよくある質問
    </p>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          設定が難しそうです。プログラミング知識がなくても大丈夫でしょうか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          設定に際してプログラミングの知識は必要ありません。また、マニュアルをフルステップでご用意していますので、スムーズに設定していただけます。<br />
          分からないことや不明点があった際にもご質問していただけましたら、1営業日以内にはご返信させていただきます。
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          MCV（アフィリリンクのクリック）で最適化しているのですが、アドタスカルを利用するメリットはありますか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          <!-- あります。アドタスカルをご利用いただくことで、最終コンバージョンでの最適化をすることができます。 -->
          あります。最適化は今までどおりMCVで行いながら、アドタスカルで最終CVを計測することで、MCVR（アフィリリンクのクリック率）は高いが、CVに繋がらないキーワード、広告、ターゲティング（年齢、性別など）などが分かります。そのデータと元に効果が悪いところを停止するなどメンテナンスの精度が高まります。
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">使用縛り回数はありますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          ございません。
          <!-- アドタスカルの1契約で、各広告媒体において何アカウントでも利用可能です。また、ASPも何社でも利用可能で、ASPプログラムも何個でも登録可能です。 -->
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">複数の広告アカウントを使用する場合は、追加で料金はかかりますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          アドタスカルの1契約で、各広告媒体において何アカウントでも利用可能です。また、ASPも何社でも利用可能で、案件も何個でも登録可能です。
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">複数のドメインを使用する場合は、追加で料金はかかりますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          かかりません。
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          サンクスページにCVタグを設置できないクライアントでも最終CVで計測できますか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          可能です。アドタスカルを使えば、CVタグを設置する必要がなくなるので、CVタグが設置不可のクライアントでも最終CVを計測できますし、最終CVで最適化も可能です。
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          コンバージョンしたキーワードはASPにバレますか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          コンバージョンしたキーワードはご自身の広告アカウントでしか確認することができないので、ASPや他にバレることはありません。
        </p>
      </div>
    </div>

    <!-- <div v-show="tool.codeKebab === 'google-cv'" class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">wbaridパラメータをアップロードすることはできますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          はい、可能です。
        </p>
      </div>
    </div> -->
    
    <!-- <div v-show="tool.codeKebab === 'yahoo-cv'" class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">ディスプレイ広告でもアドタスカルを利用できますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          ディスプレイ広告はオフラインコンバージョン機能ができないため、アドタスカルの利用はできません。検索広告でのみご利用いただけます。
        </p>
      </div>
    </div> -->
    <!-- <div v-show="tool.codeKebab === 'yahoo-cv'" class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">YDAでもアドタスカルを利用できますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          できます。ただし、別ツールとなるため、料金は別途発生します。詳細は、<router-link to="/yda-cv">YDA成果連携ツールのページ</router-link>をご確認ください。
        </p>
      </div>
    </div> -->
    <!-- <div v-show="tool.codeKebab === 'yda-cv'" class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">Yahoo!検索広告でもアドタスカルを利用できますか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          できます。ただし、別ツールとなるため、料金は別途発生します。詳細は、<router-link to="/yahoo-cv">Yahoo!検索広告成果連携ツールのページ</router-link>をご確認ください。
        </p>
      </div>
    </div> -->

    <!-- <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">ASP側の設定作業は簡単でしょうか？</p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          すでに提携が完了している以下のASPに関しては、簡単に連携可能です。<br /><br />
          ・Felmat<br />
          ・SLVRbullet<br />
          ・セレス<br />
          ・レントラックス<br />
          ・サルクルー<br />
          ・SCAN<br />
          ・famAD<br />
          ・fam<br />
          ・Link-AG<br />
          ・afb<br />
          ・アクセストレード(※)<br />
          ・Mobee2<br />
          ・A8.net(※)<br />
          ・JANet<br />
          ・メディパートナー<br />
          ・BULK-AD<br />
          ・@tension<br />
          ・Tempura-Link<br />
          ・SIXPACK-C<br />
          ・twowin<br />
          ・ゴリラテ<br />
          ・LADDER<br />
          ・インペリアル<br />
          ・Self Leap<br />
          ・Arrows<br />
          ・i-counter<br />
          ・ADRIP<br />
          ・アシスタ<br />
          ・TGアフィリエイト<br />
          ・akibito<br />
          ・SSアフィリエイト<br />
          ・Valley Consulting<br />
          ・TOSHOアフィリエイト<br />
          ・AD-LAVI<br />
          ・m-Ads<br />
          ・PRESCO<br />
          ・MOG<br />
          ・成果報酬110番<br />
          ・アドボンバー<br />
          ・DecoTra<br />
          ・AIGATE<br />
          ・Finebind<br />
          ・Circuit X<br />
          ・トリックトラック<br />
          ・project-ad<br />
          ・Funny Style<br />
          ・Gro-Fru<br />
          ・ウェブソア（CATS）<br />
          <br />
          (※)アクセストレードとA8.netの自動連携の利用は、ASPでの審査がございます。審査通過後に、自動連携の利用ができます。
        </p>
      </div>
    </div> -->

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          販売ページにないASPの連携を希望していますが、連携可能でしょうか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          新規ASPとの連携依頼も受け付けております。詳細は、お問い合わせフォームからお尋ねくださいませ。
          <!-- 現在、連携の準備をしているASP、連携対応しているがユーザーごとの審査が必要なASPもございます。また、ユーザーからの一定数の希望があるASPについては、連携していく方針です。詳細は、お問い合わせフォームからお尋ねくださいませ。 -->
        </p>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          サポートに際して、ユーザーのデータはアドタスカルに見られてしまいますか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <div>
          <p class="txt">
            前提として、いかなるサービスも利用者の登録情報などを保存しているため、アドタスカルに限ったことではありませんが、システムの仕様上、アドタスカルのタグを設置しているサイトのURL・案件名・成果数・報酬単価は、アドタスカルにてデータを保存しています。データの保存はしておりますが、利用者と締結する利用規約を誠実に遵守し、サポート以外の目的で利用者のデータをアドタスカルが閲覧・取得することはございません。
          </p>
          <p class="txt">
            サポートに際して、多くのケースでは、利用者のデータを確認することなくサポートできます。しかし、ユーザーによるタグの設置間違いのような設定ミスなどユーザー個別で発生している事象については、ユーザー個別のデータを確認しないと原因の特定ができない場合があります。このような場合、サポート担当からその旨をお伝えし、「利用者からのデータ提供でサポートできる場合は、利用者から当該の情報をサポートまでお送りいただくことで」「利用者からのデータ提供でサポートできない場合は、利用者からの許可を得た上でデータの確認による」サポートをさせていただきます。
            そのため、情報の提供をいただけない場合や、閲覧への同意が得られない場合は、引き続きのサポートができないことがあること、もしくは、こちらの指示のもと、利用者様自身で確認を行っていただくことになる場合があることをご了承の上、アドタスカルをお使いいただけますと幸いです。
          </p>
        </div>
      </div>
    </div>

    <div class="box_cover">
      <div class="QA_box">
        <p class="Q">Q</p>
        <p class="txt_q">
          ASPを使用せずに直クライアントの運用をしているのですが、この場合アドタスカルは使えないのでしょうか？
        </p>
      </div>
      <div class="QA_box">
        <p class="A">A</p>
        <p class="txt">
          カートシステム、計測システムともアドタスカルは連携しているので利用可能です。現在は以下の6種類と連携済です。<br />
          <br />
          ecforce、AD EBis、リピスト、アフィリコードシステム、AFAD、CATS
          <br />
          <br />
          ※別途、料金がかかります。<br />
          ※ユーザー様からのリクエストに応じて、連携先を追加予定です。
        </p>
      </div>
    </div>

    <div class="support">
      <p class="txt_size">
        初期の設定や日々の利用をスムーズにしていただけるよう、アドタスカルでは以下を充実させています。
      </p>
      <ul>
        <li>フルステップのマニュアル</li>
        <li>よくある質問集</li>
        <li>メールサポート</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "QA",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools", "apMinValue"]),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.QA {
  padding-bottom: 50px;
}

.b {
  font-weight: bold;
}
.title {
  margin: 0 0 50px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    margin-top: 0;
  }
}
.box_cover {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #ccc;
  .QA_box {
    display: flex;
    align-items: flex-start;
    .Q {
      font-size: 50px;
      font-weight: bold;
      color: #1c6db5;
      margin: 0;
      @media screen and (max-width: $max-width) {
        font-size: 40px;
      }
    }
    .A {
      font-size: 50px;
      font-weight: bold;
      color: #f15e62;
      color: #ccc;
      margin: 0;
      @media screen and (max-width: $max-width) {
        font-size: 40px;
      }
    }
    .txt_q {
      font-size: 22px;
      padding-left: 35px;
      font-weight: bold;
      color: #1c6db5;
      @media screen and (max-width: $max-width) {
        font-size: 19px;
        padding-left: 25px;
        margin-block-start: 0.5em;
      }
    }
    .txt {
      font-size: 18px;
      padding-left: 35px;
      @media screen and (max-width: $max-width) {
        font-size: 16px;
        padding-left: 25px;
        margin-block-start: 0.5em;
      }
    }
  }
}
.box_cover:last-child {
  border-bottom: none;
}

.support {
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 20px 50px;
  margin-top: 70px;
  margin-bottom: 80px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 10px;
    margin-top: 50px;
    padding: 20px;
  }
  ul {
    li {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
