<template>
  <div class="login">
    <el-row type="flex" justify="center">
      <h1>ログイン</h1>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="16">
        <el-input type="text" placeholder="登録メールアドレス" v-model="email">
          <template slot="prepend">ログインID</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-bottom:5px;">
      <el-col :span="16">
        <el-input type="password" placeholder="123456789abcdefg" v-model="pass">
          <template slot="prepend">パスワード</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row v-show="type === 0" type="flex" justify="center">
      <el-col :span="16">
        <span class="desc">パスワードをお忘れの場合は<router-link to="/password/" class="here">コチラ</router-link>。</span>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 30px;">
      <el-col :span="6" style="text-align:center">
        <div @click="login">
          <el-button type="primary">ログイン</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from '@/store'

export default {
  name: 'Login',
  props: {
    type: Number
  },
  components: {
  },
  data() {
    return {
      rt: store.state.rt,
      email: '',
      pass: ''
    }
  },
  methods: {
    login() {
      if(
        this.email === '' ||
        this.pass === ''
      ) {
        alert('入力されていない項目があります。');
        return;
      }else {
        let params = new URLSearchParams();
        params.append("type",0);//0:Home, 1:Ctrls
        params.append("email",this.email);
        params.append("pass",this.pass);
        axios.post(this.$store.state.rt+"/api/lp/login.php", params)
        .then(res => {
          console.log(res);
          if(res.data === "OK") {
            location.href = "/";
          }else {
            alert('ログインできませんでした。');
            return;
          }
        })
        .catch(err => {
          console.log(err);
        });
      }


    },

  }
}
</script>

<style lang="scss" scoped>
  .desc {
    color: #999;
  }
  a.here {
    text-decoration: underline;
  }
</style>
