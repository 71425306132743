<template>
  <div class="register-and-settle" v-loading="loading">
    <el-row type="flex" justify="center">
      <el-col :span="20" class="sp-max">
        <!-- <div class="card-brands-cover">
          <img class="card-brands" :src="`${rt}/img/card-5brand.png`" alt="" />
        </div> -->
        <table class="typeB">
          <tr>
            <th>
              <div class="flex">
                <p class="header-title">メールアドレス（ログインID）</p>
                <p class="icon">必須</p>
              </div>
            </th>
            <td>
              <div class="input-cover">
                <el-row :gutter="5">
                  <el-col :span="12" :xs="24">
                    <el-input
                      v-model="formData.email"
                      @blur="checkInputs('email')"
                      placeholder="example@adtasukaru.com"
                      :class="{ 'not-entered': completed.email === false }"
                      type="email"
                    ></el-input>
                  </el-col>
                </el-row>
                <div v-show="alerts.email === true" class="alert">
                  メールアドレスを正しく入力してください。
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div class="flex">
                <p class="header-title">お名前</p>
                <p class="icon">必須</p>
              </div>
            </th>
            <td>
              <div class="input-cover">
                <el-row :gutter="5">
                  <el-col :span="6" :xs="12">
                    <el-input
                      v-model="formData.name01"
                      @blur="checkInputs('name01')"
                      placeholder="鈴木"
                      :class="{ 'not-entered': completed.name01 === false }"
                    ></el-input>
                  </el-col>
                  <el-col :span="6" :xs="12">
                    <div class="input-cover">
                      <el-input
                        v-model="formData.name02"
                        @blur="checkInputs('name02')"
                        placeholder="太郎"
                        :class="{ 'not-entered': completed.name02 === false }"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <div
                  v-show="alerts.name01 === true || alerts.name02 === true"
                  class="alert"
                >
                  お名前を正しく入力してください。
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div class="flex">
                <p class="header-title">クレジットカード番号</p>
                <p class="icon">必須</p>
              </div>
            </th>
            <td>
              <div class="input-cover">
                <el-row>
                  <el-col :span="18" :xs="24">
                    <div class="card-input">
                      <el-input
                        v-model="cardData.cardNo"
                        @blur="checkInputs('cardNo')"
                        placeholder="（半角数字、ハイフン(-)不要）"
                        class="no-spin"
                        :class="{ 'not-entered': completed.cardNo === false }"
                        type="number"
                      ></el-input>
                      <!-- <img class="card-brands" :src="`${rt}/img/card-5brand.png`" alt=""> -->
                    </div>
                  </el-col>
                </el-row>
                <div v-show="alerts.cardNo === true" class="alert">
                  14~16桁の半角数字で入力してください。
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div class="flex">
                <p class="header-title">有効期限（月／年）</p>
                <p class="icon">必須</p>
              </div>
            </th>
            <td>
              <div class="input-cover">
                <el-row :gutter="5" type="flex" align="middle">
                  <el-col :span="4" :xs="12">
                    <el-input
                      v-model="cardData.expireMonth"
                      @blur="checkInputs('expireMonth')"
                      placeholder="月"
                      class="no-spin"
                      :class="{
                        'not-entered': completed.expireMonth === false,
                      }"
                      type="number"
                    ></el-input>
                  </el-col>
                  <el-col :span="1" class="pc">
                    <div
                      style="font-size: 20px; color: #aaa; text-align: center"
                    >
                      /
                    </div>
                  </el-col>
                  <el-col :span="4" :xs="12">
                    <el-input
                      v-model="cardData.expireYear"
                      @blur="checkInputs('expireYear')"
                      placeholder="年"
                      class="no-spin"
                      :class="{ 'not-entered': completed.expireYear === false }"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-row>
                <div
                  v-show="
                    alerts.expireMonth === true || alerts.expireYear === true
                  "
                >
                  <span class="alert"
                    >それぞれ2桁の半角数字で入力してください。</span
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div class="flex">
                <p class="header-title">セキュリティコード</p>
                <p class="icon">必須</p>
              </div>
            </th>
            <td>
              <div class="input-cover">
                <el-row :gutter="15">
                  <el-col :span="5" :xs="24">
                    <el-input
                      v-model="cardData.securityCode"
                      @blur="checkInputs('securityCode')"
                      placeholder="3桁or4桁"
                      class="no-spin"
                      :class="{
                        'not-entered': completed.securityCode === false,
                      }"
                      type="number"
                    ></el-input>
                  </el-col>
                </el-row>
                <div v-show="alerts.securityCode === true" class="alert">
                  3~4桁の半角数字で入力してください。
                </div>
              </div>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row
      v-if="affiliaterData.givesPrivilege === true"
      class="privilege-notification"
      type="flex"
      justify="center"
    >
      <el-col :xs="24" :sm="12" :md="12">
        <h4>
          本申し込みには{{
            privilegeData.displayProviderName
          }}様からの特典がつきます
        </h4>
        <table>
          <tr>
            <th class="checkbox" rowspan="2"><el-checkbox :value="true" /></th>
            <th>
              <p>{{ privilegeData.privilegeName }}</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>{{ privilegeData.description }}</p>
            </td>
          </tr>
        </table>
        <p>
          ※アドタスカルのいずれかのツールの「自動インポート成功」をすると、特典をアドタスカル管理画面上からダウンロードすることができます。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-row>
          <el-col style="text-align: center">
            <div>
              <span style="font-size: 14px; color: #808080; margin-right: 5px"
                >▶</span
              ><a :href="`${rt}/files/pdf/terms.pdf`" target="_blank"
                >アドタスカル利用規約</a
              >（クリックして内容をご確認ください。）
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="text-align: center">
            <label
              ><input
                type="checkbox"
                v-model="checked"
                style="margin-right: 5px"
              />本サービスの利用にはアドタスカル利用規約が適用されます。</label
            >
          </el-col>
        </el-row>
        <el-row
          v-if="
            completed.email === true &&
            completed.name01 === true &&
            completed.name02 === true &&
            completed.cardNo === true &&
            completed.expireMonth === true &&
            completed.expireYear === true &&
            completed.securityCode === true
          "
        >
          <el-col>
            <div class="settle-button-cover">
              <el-button
                @click="registerAndSettle"
                class="success"
                type="success"
                >確定</el-button
              >
            </div>
            <p class="settle-caution">
              ※お試しは、ポイントの有効期限が3ヶ月間です。
            </p>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col>
            <div class="settle-button-cover">
              <el-button class="not-entered" type="info">確定</el-button>
            </div>
            <p class="settle-caution" style="color: red">
              ※未入力の必須項目があります
            </p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <!-- <h3 class="pink">ポイントの有効期限について</h3>
        <p>
          ポイントの有効期限は、"購入した本日から1年後の月末"です（例：2021年1月10日にポイントを購入した場合、そのポイントの有効期限は2022年1月31日となります）。
        </p> -->
        <h3 class="pink">ログイン情報のメールが届かない場合</h3>
        <p>
          ご登録のメールアドレスにアドタスカルからメールが届かない場合は、迷惑メールボックスの中をご確認ください。<br />
          迷惑メールボックスにもメールが届いていない場合は、登録アドレスの間違いなどが考えられますので、<router-link
            to="/contact-form"
            >こちら</router-link
          >からお問い合わせをお願いいたします。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RegisterAndSettle",
  components: {},
  props: {
    productId: String,
  },
  data() {
    return {
      loading: false,
      completed: {
        email: false,
        name01: false,
        name02: false,
        cardNo: false,
        expireMonth: false,
        expireYear: false,
        securityCode: false,
      },
      alerts: {
        email: false,
        name01: false,
        name02: false,
        cardNo: false,
        expireMonth: false,
        expireYear: false,
        securityCode: false,
      },
      formData: {
        email: "",
        name01: "",
        name02: "",
      },
      cardData: {
        cardNo: "",
        expireMonth: "",
        expireYear: "",
        securityCode: "",
      },
      privilegeCheck: true,
      checked: true,
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "affiliaterData",
      "apiErrorMessage",
      "queryData",
      "privilegeData",
    ]),
  },
  created() {
    // //↓↓↓ テスト用 ↓↓↓
    // this.formData = {
    //   email: "example@gmail.com",
    //   name01: "田中",
    //   name02: "太郎",
    // };
    // this.cardData = {
    //   cardNo: "4111111111111111",
    //   expireMonth: "01",
    //   expireYear: "24",
    //   securityCode: "123",
    // };
    // //↑↑↑ テスト用 ↑↑↑

    let expireYear = 2021;
    let expireYearValue = 21;
    let expireYears = [];
    for (let index = 0; index < 30; index++) {
      expireYears.push({
        text: String(expireYear++),
        value: String(expireYearValue++),
      });
    }
    this.years = expireYears;
  },
  methods: {
    registerAndSettle() {
      //loading中ははじく
      if (this.loading === false) {
        this.loading = true;
      } else {
        alert("決済処理中です。しばらくお待ちください。");
        return;
      }

      let vm = this; //getTokenの第2引数で"this"が使えないので変換
      if (process.env.NODE_ENV !== "production") {
        //テスト環境
        window.Multipayment.init("tshop00038502");
      } else {
        //本番環境
        window.Multipayment.init("9200002644627");
      }
      window.Multipayment.getToken(
        {
          cardno: this.cardData.cardNo,
          expire: this.cardData.expireYear + this.cardData.expireMonth,
          securitycode: this.cardData.securityCode,
          holdername: "",
          tokennumber: "1",
        },
        function (getTokenResponse) {
          // console.log(getTokenResponse);
          if (getTokenResponse.resultCode === "000") {
            //
            let queryData = {
              utm_source:
                vm.$route.query.utm_source !== undefined
                  ? vm.$route.query.utm_source
                  : "",
              utm_medium:
                vm.$route.query.utm_medium !== undefined
                  ? vm.$route.query.utm_medium
                  : "",
              tool_id:
                vm.$route.query.tool_id !== undefined
                  ? vm.$route.query.tool_id
                  : "",
            };
            let params = new URLSearchParams();
            params.append("product_id", vm.productId);
            params.append("form_data", JSON.stringify(vm.formData));
            params.append("query_data", JSON.stringify(queryData));
            params.append(
              "token_data",
              JSON.stringify(getTokenResponse.tokenObject)
            );
            params.append("checked", vm.checked);
            vm.$axios
              .post(`${vm.rt}/api/payment/settle2.php`, params)
              .then((response) => {
                console.log(response);
                if (response.data.status === 200) {
                  //一応、空にしておく
                  vm.formData.email = "";
                  vm.formData.name01 = "";
                  vm.formData.name02 = "";
                  vm.cardData.cardNo = "";
                  vm.cardData.expireMonth = "";
                  vm.cardData.expireYear = "";
                  vm.cardData.securityCode = "";

                  //遷移
                  location.href = `/complete/${response.data.temporaryRegisterToken}`;
                } else if (
                  response.data.status === 400 ||
                  response.data.status === 409 ||
                  response.data.status === 500
                ) {
                  alert(response.data.message);
                  vm.loading = false;
                  return;
                } else {
                  alert(this.apiErrorMessage);
                  vm.loading = false;
                  return;
                }
              })
              .catch((err) => {
                vm.loading = false;
                console.log(err);
              });
          } else {
            let params = new URLSearchParams();
            params.append("product_id", vm.productId);
            params.append("form_data", JSON.stringify(vm.formData));
            params.append(
              "token_data",
              JSON.stringify(getTokenResponse.tokenObject)
            );
            params.append("checked", vm.checked);
            params.append("error_code", getTokenResponse.resultCode);
            vm.$axios
              .post(`${vm.rt}/api/payment/judge-token-error.php`, params)
              .then((res) => {
                console.log(res);
                alert(res.data.errorMessage);
                vm.loading = false;
                return;
              })
              .catch((err) => {
                vm.loading = false;
                console.log(err);
              });
          }
        }
      );
    },
    checkInputs(object) {
      //valueと正規表現を定義
      switch (object) {
        case "email":
          if (
            this.formData.email.match(
              /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
            )
          ) {
            this.completed.email = true;
            this.alerts.email = false;
          } else {
            this.completed.email = false;
            this.alerts.email = true;
          }
          break;

        case "name01":
          if (
            this.formData.name01 !== "" &&
            this.formData.name01.match(/^[\u3040-\u309F|\u4E00-\u9FFF]+$/)
          ) {
            this.completed.name01 = true;
            this.alerts.name01 = false;
          } else {
            this.completed.name01 = false;
            this.alerts.name01 = true;
          }
          break;

        case "name02":
          if (
            this.formData.name02 !== "" &&
            this.formData.name02.match(/^[\u3040-\u309F|\u4E00-\u9FFF]+$/)
          ) {
            this.completed.name02 = true;
            this.alerts.name02 = false;
          } else {
            this.completed.name02 = false;
            this.alerts.name02 = true;
          }
          break;

        case "cardNo":
          if (this.cardData.cardNo.match(/^([0-9]{14,16})$/)) {
            this.completed.cardNo = true;
            this.alerts.cardNo = false;
          } else {
            this.completed.cardNo = false;
            this.alerts.cardNo = true;
          }
          break;

        case "expireMonth":
          if (this.cardData.expireMonth.match(/^([0-9]{2})$/)) {
            this.completed.expireMonth = true;
            this.alerts.expireMonth = false;
          } else {
            this.completed.expireMonth = false;
            this.alerts.expireMonth = true;
          }
          break;

        case "expireYear":
          if (this.cardData.expireYear.match(/^([0-9]{2})$/)) {
            this.completed.expireYear = true;
            this.alerts.expireYear = false;
          } else {
            this.completed.expireYear = false;
            this.alerts.expireYear = true;
          }
          break;

        case "securityCode":
          if (this.cardData.securityCode.match(/^([0-9]{3,4})$/)) {
            this.completed.securityCode = true;
            this.alerts.securityCode = false;
          } else {
            this.completed.securityCode = false;
            this.alerts.securityCode = true;
          }
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-brands-cover {
  margin-bottom: 5px;
  .card-brands {
    width: 350px;
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
  }
}
// .card-input {
//   display: flex;
//   align-items: center;
//   @media screen and (max-width: 767px) {
//     display: block;
//   }
//   .card-brands {
//     width: 180px;
//     margin-left: 20px;
//     @media screen and (max-width: 767px) {
//       width: 100%;
//       margin: 0;
//       margin-top: 10px;
//     }
//   }
// }
h3.pink {
  color: #f56c6c;
  margin-bottom: 5px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    box-sizing: border-box;
    padding: 0 7px;
  }
  .title {
    text-align: center;
    flex-grow: 10;
    height: 20px;
    line-height: 20px;
  }
  .icon {
    width: 2.5em;
    height: 25px;
    line-height: 25px;
    font-size: 0.75em;
    color: white;
    background-color: red;
  }
}
.head {
  background-color: rgb(28, 109, 181);
  text-align: center;
  h1 {
    margin: 0.5em 0;
    font-size: 33px;
    color: white;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}

table.typeB {
  tr {
    th,
    td {
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    th {
      width: 35%;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
      }
    }
    td {
      @media screen and (max-width: 767px) {
        padding: 2px 0 8px;
      }
      .input-cover {
        position: relative;
        .alert {
          position: absolute;
          color: red;
          font-size: 14px;
          @media screen and (max-width: 767px) {
            position: initial;
          }
        }
        :deep(.el-input) {
          width: 100%;
          .el-input__inner {
            @media screen and (max-width: 767px) {
              font-size: 20px;
              height: 55px;
              border-color: #999;
            }
          }
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
        :deep(.not-entered) {
          input {
            background-color: #ffd9f0;
          }
        }
      }
    }
  }
}

.privilege-notification {
  h4 {
    font-size: 20px;
    color: #f15e62;
    text-align: center;
    border-bottom: solid 2px #f15e62;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    tr {
      th,
      td {
        border: solid 1px #ddd;
        padding: 10px;
        p {
          margin: 0;
        }
      }
      th {
        text-align: left;
      }
      th.checkbox {
        text-align: center;
        width: 30px;
        background-color: #eee;
      }
    }
  }
  p {
  }
}

.settle-button-cover {
  text-align: center;
  .el-button {
    width: 300px;
    height: 50px;
    font-size: 24px;
    border: 1px solid white;
    box-shadow: inset 0 0 15px rgba(200, 241, 189, 0.3);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
    background: #f1e767;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#727164),
      to(#feb645)
    );
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 22px;
    }
  }
  .success {
    background: -webkit-linear-gradient(top, #65d746 0%, #25a807 100%);
    background: linear-gradient(to bottom, #65d746 0%, #25a807 100%);
  }
  .not-entered {
    cursor: not-allowed;
    background: -webkit-linear-gradient(top, #d8d8d8 0%, #adadad 100%);
    background: linear-gradient(to bottom, #d8d8d8 0%, #adadad 100%);
  }
}
p.settle-caution {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
}
</style>