<template>
  <div class="BonusPoint">
    <div class="point_plan">
      <div class="bg_board">
        <table class="pc">
          <template v-for="toolCode in [(tool.codeKebab === 'tools' ? 'googleCv' : tool.codeCamel)]">
            <tr
              v-for="bonusPointData in tools[toolCode].bonusPointList"
              :key="bonusPointData.bonusId"
            >
              <th>{{ bonusPointData.name }}</th>
              <td>{{ bonusPointData.description }}</td>
              <td class="point">{{ bonusPointData.point }}ポイント</td>
            </tr>
          </template>
        </table>
        <div class="sp">
          <template v-for="toolCode in [(tool.codeKebab === 'tools' ? 'googleCv' : tool.codeCamel)]">
            <table
              v-for="bonusPointData in tools[toolCode].bonusPointList"
              :key="bonusPointData.bonusId"
            >
              <tr>
                <th class="title">{{ bonusPointData.name }}</th>
              </tr>
              <tr class="border">
                <td>{{ bonusPointData.description }}</td>
              </tr>
              <tr class="border">
                <td class="point">{{ bonusPointData.point }}ポイント</td>
              </tr>
            </table>
          </template>
        </div>
      </div>
      <p v-if="tool.codeKebab === 'tools'">上記以外にもツール個別で獲得できるボーナスポイントがあります。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "BonusPoint",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
};
</script>

<style lang="scss" scoped>
.bg_board {
  background-color: #f4f4f4;
  padding: 30px 40px;
  border-radius: 15px;
  @media screen and (max-width: $max-width) {
    padding: 20px;
  }
}

.point_plan {
  .plan_name {
    font-size: 24px;
    @media screen and (max-width: $max-width) {
      text-align: center;
    }
  }
  .tax {
    text-align: right;
  }
  table {
    border-collapse: collapse;
    color: #4d4d4d;
    th {
      width: 280px;
      border: 1px solid #ccc;
      padding: 13px 10px;
      font-size: 18px;
      background-color: #1c6db5;
      color: white;
      @media screen and (max-width: $max-width) {
        padding: 10px 8px;
        font-size: 17px;
      }
    }
    td {
      border: 1px solid #ccc;
      font-size: 18px;
      background-color: white;
      padding: 0 10px;
      @media screen and (max-width: $max-width) {
        padding: 10px;
        font-size: 16px;
      }
      &.point {
        text-align: center;
        width: 120px;
      }
    }
  }
}

.sp {
  table {
    @media screen and (max-width: $max-width) {
      margin: 0 auto;
    }
  }
  table + table {
    margin-top: 10px;
  }
}
</style>
