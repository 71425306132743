<template>
  <div class="policy-cover">
    <p class="policy-big-title">プライバシーポリシー</p>
    <div class="txt">
      <p>株式会社グレイトヘルプ（以下「当社」といいます。）は、「個人情報の保護に関する法律」（平成15年法律第57号、（以下「個人情報保護法」といいます。）に基づく個人データの適正な取り扱いの確保について組織として取り組むために、本プライバシーポリシーを定めます。</p>
      <div class="block">
        <p class="policy-title">1.関係法令・ガイドラインの遵守</p>
        <p>当社は、個人情報保護法その他の法令及び個人情報保護委員会のガイドラインその他のガイドラインを遵守して、個人データの適正な取り扱いを行います。</p>
      </div>
      <div class="block">
        <p class="policy-title">2.個人情報の取得・利用</p>
        <p>当社は、個人情報を取得する際には、利用目的を公表又は通知し（本プライバシーポリシーによる公表を含みます。）、又、直接ご本人様から契約書その他の書面（電磁的記録を含む）に記載された個人情報を取得する場合には予め利用目的を明示し、適法かつ公正な手段によって取得いたします。 当社は、利用目的の達成に必要な範囲内で、適正に個人情報を利用いたします。 当社は、個人情報を取得する際には、利用目的を公表又は通知し（本プライバシーポリシーによる公表を含みます。）、又、直接ご本人様から契約書その他の書面（電磁的記録を含む）に記載された個人情報を取得する場合には予め利用目的を明示し、適法かつ公正な手段によって取得いたします。 当社は、利用目的の達成に必要な範囲内で、適正に個人情報を利用いたします。 また、当社は、入会申し込みの途中でも利用規約への許諾があった場合には当社のプライバシーポリシーに従い、ご注文フォームに入力されたお客様の情報を取得し、利用する場合があります。</p>
      </div>
      <div class="block">
        <p class="policy-title">3.個人情報の利用目的</p>
        <p>当社が取得した個人情報は、事業運営に関する下記の業務に利用するものとします。</p>
        <ul>
          <li>商品代金の決済及び与信判断、債権管理のため</li>
          <li>各種お問合わせや健康に関するご相談及びアフターサービス対応のため</li>
          <li>当社からのお知らせ（メールマガジン及びハガキや冊子などの印刷物）をお送りさせていただくため</li>
          <li>統計的に処理した個人情報を集約し、商品の開発及びマーケティング、サービスの向上等に役立てるため</li>
          <li>賞品の当選発表及び商品の発送のため</li>
          <li>コメント及び体験談のご投稿内容を、各サイト及びメールマガジン、広告媒体等で公開するため</li>
          <li>商品を購入いただいたお客様に、当社からのお知らせ（メールマガジン及びハガキや冊子などの印刷物）をお送りさせていただくため</li>
          <li>従業員（採用応募者、退職者含む）の雇用や労務管理のため</li>
          <li>その他上記に付随する業務に利用するため</li>
        </ul>
        <span>
          ※お客様との電話応対時においては、ご注文・ご意見・ご要望・お問合わせ内容等の正確な把握、今後のサービス向上のために、通話を録音させていただく場合があります。<br />
          ※当社への情報のご提供はすべて任意となっております。 ただし、依頼する情報をご提供いただけない場合は、正常なサービスをご提供できない場合があります。当社は、お客様がお取引を完了しておらず、情報の入力途中であった場合においても、当社のプライバシーポリシーに従い、当社の予約フォームに入力されたお客様の情報を取得し、利用する場合があります。
        </span>
      </div>
      <div class="block">
        <p class="policy-title">4.個人情報の共同利用について</p>
        <p>当社はお取引先様に関する個人情報をつぎのとおり共同利用させていただきます。</p>
        <ol>
          <li>個人情報の項目</li>
          <span>会社名（屋号）、住所、郵便番号、所属、役職、氏名、電話番号、電子メールアドレス、支払口座、その他お取引先様からご提供いただいた当該お取引先様の情報</span>
          <li>共同利用の範囲</li>
          <span>当社の関係会社（財務諸表等の用語、様式及び作成方法に関する規則（昭和38年大蔵省令第59号、その後の改正も含む。）第8条（定義）８項で定義するものをいう。）</span>
          <li>利用目的</li>
          <ul>
            <li>提案、サービス、セミナー、アンケート実施等の業務実施に係るご案内ご連絡</li>
            <li>出版物、新聞、季節のご挨拶等の送付</li>
            <li>債権の回収</li>
            <li>債務の支払い</li>
          </ul>
          <li>個人データの管理について責任を有する会社の名称、住所、代表等</li>
          <span>共同利用する個人データについては当社が責任を負います。当社の住所及び代表者については当社HPの会社概要をご参照ください。</span>
        </ol>
      </div>
      <div class="block">
        <p class="policy-title">5.個人情報の第三者提供について</p>
        <p>当社は、当サイトにおいてお客様よりご提示頂いた個人情報を、第三者に開示、譲渡、貸与いたしません。ただし、以下のいずれかに該当する場合は、その限りではありません。</p>
        <ul>
          <li>お客様の同意がある場合</li>
          <li>お客様のお問い合わせやご質問にお答えする為に、当社の業務提携企業に開示する必要がある場合。</li>
          ※弊社が合併、分社化、営業譲渡等で、個人情報を提供する場合を除き、個人情報を第三者へ提供することはありません。
          <li>お客様に明示した目的・サービスの為に事前に秘密保持契約を締結した業務委託企業および協力企業に対して開示する必要がある場合。</li>
          <li>人命、身体や財産等に対して差し迫った危険があり、緊急の必要性が生じた場合。</li>
          <li>個人情報保護法、その他法令に基づく個人情報の開示が求められた場合。</li>
        </ul>
      </div>
      <div class="block">
        <p class="policy-title">6.個人情報の収集について</p>
        <p>当社は、個人データについて、漏洩、滅失または既存の防止等、その管理のために必要且つ適切な安全管理措置を講じます。また、個人データを取り扱う従業員や委託先（再委託先を含みます。）に対して、必要かつ適切な監督を行います。</p>
      </div>
      <div class="block">
        <p class="policy-title">7.個人情報の開示等の請求</p>
        <p>当社は、個人情報の照会・訂正・利用停止・消去等のご要望があったときは、所定の手続きでご本人様であることを確認の上、すみやかに対応します。本プライバシーポリシーに関してご質問がある場合や権利行使される場合は下記8のお問合せ先にご連絡ください。</p>
      </div>
      <div class="block">
        <p class="policy-title">8.お問合せ先</p>
        <p>当社における個人データの取り扱いに関するご質問や苦情に関しては下記のお問い合わせ先にご連絡ください。</p>
        <p>株式会社グレイトヘルプ個人情報相談担当窓口</p>
        <p><a href="https://adtasukaru.com/contact-form" target="_blank" rel="noopener noreferrer">https://adtasukaru.com/contact-form</a></p>
      </div>
      <div class="block">
        <p class="policy-title">9.クッキーの使用について</p>
        <p>当社のサイト上では、WEBブラウザを通じて、お客様のコンピュータにクッキー（Cookie）という情報を送り通信を管理することが有ります。お客様ごとに画面の遷移を維持したり、当サイトでお買い物などの操作をしていただくために必要なものです。なお、Cookieを無効に設定されますと、当サイトのサービスを正常にご利用いただくことができませんので、予めご了承ください。</p>
      </div>
      <div class="block">
        <p class="policy-title">10.GoogleAnalyticsの利用について</p>
        <p>当社サイトでは、お客様の当社サイトの訪問状況を把握するためにGoogle社のサービスであるGoogleAnalyticsを利用しています。 当社のサイトでGoogleAnalyticsを利用しますと、当社が発行するCookieをもとにしてGoogle社がお客様の当社サイトの訪問履歴を収集、記録、分析します。 当社はGoogle社からその分析結果を受け取り、お客様の当社サイトの訪問状況を把握します。 GoogleAnalyticsにより収集、記録、分析されたお客様の情報には、特定の個人を識別する情報は一切含まれません。また、それらの情報は、Google社により同社のプライバシーポリシーに基づいて管理されます。 お客様は、ブラウザのアドオン設定でGoogleAnalyticsを無効にすることにより、当社のGoogleAnalytics利用によるご自身の情報の収集を停止することも可能です。GoogleAnalyticsの無効設定は、Google社によるオプトアウトアドオンのダウンロードページで「GoogleAnalyticsオプトアウトアドオン」をダウンロード及びインストールし、ブラウザのアドオン設定を変更することで実施することができます。なお、お客さまがGoogleAnalyticsを無効設定した場合、お客さまが訪問する本サイト以外のウェブサイトでもGoogleAnalyticsは無効になりますが、お客さまがブラウザのアドオンを再設定することにより、再度GoogleAnalyticsを有効にすることも可能です。GoogleAnalyticsの利用規約に関する説明については、GoogleAnalyticsのサイトを、Google社のプライバシーポリシーに関する説明については同社のサイトをご覧ください。</p>
        <p>〈GoogleAnalyticsの利用規約〉</p>
        <p><a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">https://marketingplatform.google.com/about/analytics/terms/jp/</a></p>
        <p>〈Google社のプライバシーポリシー〉</p>
        <p><a href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=ja</a></p>
        <p>〈GoogleAnalyticsのオプトアウトアドオン〉</p>
        <p><a href="https://tools.google.com/dlpage/gaoptout?hl=ja" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=ja</a></p>
      </div>
      <div class="block">
        <p class="policy-title">11.ログについて</p>
        <p>お客様が当社ウェブサイトをアクセスされたことについて、その操作の情報をアクセスログという形で記録しています。 このログは個人を特定できる情報を含むものではありませんが、今後のサイトの利便性向上のためや、万一問題が発生した際の原因追及、利用状況に関する統計・分析処理などに使用するために採取をしており、それ以外の目的には使用いたしません。</p>
      </div>
      <div class="block">
        <p class="policy-title">12.リンク先に関する免責について</p>
        <p>当サイトでは、ユーザーのプライバシーを保護するための最大限の努力をすることを約束いたします。 ただし、当社のサイト上にあるリンク先の、第三者企業においては、当サイトの責任範囲外となり、その第三者企業が設定するプライバシーポリシーに準じることとなります。</p>
      </div>
      <div class="block">
        <p class="policy-title">13.SSLの使用について</p>
        <p>当社サイトでは、会員登録および、資料請求情報の入力、アンケート回答の際など、個人情報が送受信されるページにおいて、 SSL（Secure Socket Layer）による暗号化通信を使用し、お客様の個人情報を外部の第三者が通信傍受できないよう対策を行っています。</p>
      </div>
      <p>2022年12月1日改訂</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy-policy",
  title: "プライバシーポリシー",
  components: {},
  data() {
    return {
      tool: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  methods: {},
};
</script>






<style lang="scss" scoped>
.policy-cover {
  width: 1100px;
  margin: 70px auto 120px;
  @media screen and (max-width: $max-width) {
    width: 100%;
    margin: 50px auto 80px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  p {
    line-height: 1.8em;
  }
}
ol>li {
  list-style-type: none;
	counter-increment: cnt;
}
ol>li::before {
  content: "(" counter(cnt) ") ";
}

.policy-big-title {
  text-align: center;
  font-size: 28px;
}
.txt {
  margin: 40px 0;
}
.block {
  margin: 50px 0;
  .policy-title {
    font-size: 18px;
    font-weight: bold;
    margin-block-end: 0;
    // color: #1c6db5;
  }
  .number {
    // font-size: 18px;
    // font-weight: bold;
    // color: #1c6db5;
    margin-right: 5px;
  }
  p {
    margin-block-end: 0;
    margin-block-start: 0;
  }
  .small-block {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0 20px;
  }
}
</style>
