<template>
<div id="Template">

<div class="pc">
  <div class="cover" :style="'background-image: url('+rt+'/img/lp/tool_bg_img.png);'">
    <div class="txt_cover">
      <p class="point_title">{{TemplateTitle}}</p>
      <p class="point_txt">{{TemplateText}}</p>
    </div>
    <img :src="rt+'/img/lp/'+DataName" :alt="altText" />
  </div>
</div>

<div class="sp">
  <div class="cover">
    <div class="txt_cover">
      <p class="point_title">{{TemplateTitle}}</p>
      <p class="point_txt">{{TemplateText}}</p>
    </div>
    <img :src="rt+'/img/lp/'+DataName" :alt="altText" />
  </div>
</div>

</div>

</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "Template",
  props: {
    TemplateTitle: String,
    TemplateText: String,
    DataName: String,
    altText: String
  },
  data() {
    return {
      rt: store.state.rt
    };
  }
};
</script>

<style lang="scss" scoped>
.cover {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap-reverse;
  }
  .txt_cover {
    width: 440px;
    height: 260px;
    padding: 30px 40px;
    border-radius: 7px;
    box-sizing: border-box;
    @media screen and (max-width: 767px) {
    height: auto;
    width: 100%; 
    padding: 10px 20px;
    background-color: #D4E0ED;
  }
  }
 
  .point_title {
    font-size: 30px;
    font-weight: bold;
    // color: #1c6db5;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 0.05em;
    @media screen and (max-width: 767px) {
      margin-block-start: 0;
      font-size: 24px;
    }
  }
  .point_txt {

  }
}
img {
  box-shadow: 8px 10px 20px -9px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  margin-top: 50px;
  @media screen and (max-width: $max-width) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 25px;
    border-radius: 15px;
  }
}
</style>
