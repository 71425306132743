var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PopupInstallation"},[_c('div',{staticClass:"wrap"},[_c('p',{staticClass:"title"},[_vm._v("設置の流れ")]),_c('div',{staticClass:"step_all"},[_c('div',{staticClass:"step_cover",style:({ backgroundImage: 'url(' + _vm.rt + '/img/lp/bg_img.png)' })},[_vm._m(0),_vm._m(1),_c('p',{staticClass:"step_txt"},[_vm._v("お申込み後、すぐにアカウントが発行され、すぐにご利用できます。")])]),_c('div',{staticClass:"step_cover",style:({ backgroundImage: 'url(' + _vm.rt + '/img/lp/bg_img.png)' })},[_vm._m(2),_vm._m(3),_c('p',{staticClass:"step_txt"},[_vm._v("ポップアップを設置したいサイトを登録します。")])]),_c('div',{staticClass:"step_cover",style:({ backgroundImage: 'url(' + _vm.rt + '/img/lp/bg_img.png)' })},[_vm._m(4),_vm._m(5),_c('p',{staticClass:"step_txt"},[_vm._v("表示したいポップアップをエディタ機能で作成、または、画像指定します。")])]),_c('div',{staticClass:"step_cover",style:({ backgroundImage: 'url(' + _vm.rt + '/img/lp/bg_img.png)' })},[_vm._m(6),_vm._m(7),_c('p',{staticClass:"step_txt"},[_vm._v("発行されたタグを広告サイトに貼り付けます。")])]),_c('div',{staticClass:"step_cover",style:({ backgroundImage: 'url(' + _vm.rt + '/img/lp/bg_img.png)' })},[_vm._m(8),_vm._m(9),_c('p',{staticClass:"step_txt"},[_vm._v("ポップアップの利用がスタートとなります。")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('p',{staticClass:"number"},[_c('span',{staticClass:"STEP"},[_vm._v("Step")]),_vm._v("01")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step_txt_title"},[_c('p',[_vm._v("お申し込み")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('p',{staticClass:"number"},[_c('span',{staticClass:"STEP"},[_vm._v("Step")]),_vm._v("02")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step_txt_title"},[_c('p',[_vm._v("サイト登録")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('p',{staticClass:"number"},[_c('span',{staticClass:"STEP"},[_vm._v("Step")]),_vm._v("03")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step_txt_title"},[_c('p',{staticClass:"pc"},[_vm._v(" ポップアップ "),_c('br'),_vm._v("作成 ")]),_c('p',{staticClass:"sp"},[_vm._v(" ポップアップ作成 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('p',{staticClass:"number"},[_c('span',{staticClass:"STEP"},[_vm._v("Step")]),_vm._v("04")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step_txt_title"},[_c('p',[_vm._v(" タグの発行と "),_c('br'),_vm._v("貼り付け ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('p',{staticClass:"number"},[_c('span',{staticClass:"STEP"},[_vm._v("Step")]),_vm._v("05")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step_txt_title"},[_c('p',[_vm._v("利用開始")])])
}]

export { render, staticRenderFns }