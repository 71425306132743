<template>
  <div class="plan-table">
    <el-row type="flex" justify="center">
      <el-col :span="20" class="sp-max">
        <div class="pc">
          <table class="plans">
            <thead>
              <tr>
                <th colspan="2">
                  <div>
                    {{ productData["planName"] }}プラン（初回お試し限定価格）
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <div>料金</div>
                </th>
                <td>
                  <div>
                    <span style="text-decoration: line-through">
                      通常価格：{{ productData["price"] | localeNum }}円
                      <span class="small00">（税込）</span>
                    </span>
                    <span
                      style="color: #f56c6c; font-weight: bold; font-size: 20px"
                    >
                      <br />▼ <br />初回お試し価格：100円
                      <span class="small00">（税込）</span>
                    </span>
                    <br />
                    （2回目以降{{ productData["price"] | localeNum }}円/税込）
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div>付与ポイント数</div>
                </th>
                <td>
                  <div>{{ productData["point"] | localeNum }}ポイント</div>
                </td>
              </tr>
              <template v-if="tool.codeKebab === 'tools'">
                <template
                  v-for="(toolCodeCamel, index) in ['googleCv', 'yahooCv', 'ydaCv', 'facebookCv','microsoftCv', 'squadbeyondCv']"
                >
                  <tr :key="index">
                    <th>
                      <div>{{ tools[toolCodeCamel].name2 }}の消費ポイント</div>
                    </th>
                    <td>
                      <div v-if="
                      toolCodeCamel === 'googleCv' || toolCodeCamel === 'yahooCv'|| 
                      toolCodeCamel === 'microsoftCv' || toolCodeCamel === 'ydaCv' ||
                      toolCodeCamel === 'squadbeyondCv'
                      ">
                        1日あたり{{
                          tools[toolCodeCamel].point | localeNum
                        }}ポイント
                      </div>
                      <div v-else-if="toolCodeCamel === 'facebookCv'">
                        <span>1日あたり{{tools[toolCodeCamel].point | localeNum}}ポイント</span>
                        <span v-show="isCampaign === true"><p style="text-align: center; color: #f56c6c; font-weight: bold;">2022年12月31日まで：1日あたり100ポイント</p></span>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-else>
                <th>
                  <div>{{ tools[tool.codeCamel].name2 }}の消費ポイント</div>
                </th>
                <td>
                  <div>
                    1日あたり{{
                      tools[tool.codeCamel].point | localeNum
                    }}ポイント
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div>ポイント有効期限</div>
                </th>
                <td>
                  <div>
                    お試しのみ購入後3ヶ月間
                    <br />
                    <span style="font-size: 14px">通常は購入後1年間（※）</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div>サポート</div>
                </th>
                <td>
                  <div>メール</div>
                </td>
              </tr>
              <tr>
                <th>
                  <div>自動更新のタイミング</div>
                </th>
                <td class="left">
                  <div>
                    <p>
                      自動更新の設定中は、ポイント残高が{{
                        (apMinValue - 1) | localeNum
                      }}になる直前に決済が執行されます。自動更新設定中はツールの利用がストップすることはございません。
                    </p>
                    <p>
                      「自動更新」は管理画面でいつでも停止できます（縛りはありません）。
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div>決済方法</div>
                </th>
                <td>
                  <div style="text-align: center; margin-bottom: 7px">
                    クレジットカードのみ
                  </div>
                  <div style="text-align: center">
                    <img
                      class="card-brands"
                      :src="`${rt}/img/card-5brand.png`"
                      alt
                      width="80%"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sp">
          <table>
            <tr>
              <th class="title">料金</th>
            </tr>
            <tr class="border">
              <td>
                <div>
                  <span style="text-decoration: line-through">
                    通常価格：{{ productData["price"] | localeNum }}円
                    <span class="small00">（税込）</span>
                  </span>
                  <span
                    style="color: #f56c6c; font-weight: bold; font-size: 20px"
                  >
                    <br />▼ <br />初回お試し価格：100円
                    <span class="small00">（税込）</span>
                  </span>
                  <br />
                  （2回目以降{{ productData["price"] | localeNum }}円/税込）
                </div>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <th class="title">付与ポイント数</th>
            </tr>
            <tr class="border">
              <td>{{ productData["point"] | localeNum }}ポイント</td>
            </tr>
          </table>
          <table>
            <!-- <tr>
              <th class="title">{{ tools[tool.codeCamel].name2 }}の消費ポイント</th>
            </tr>
            <tr class="border">
              <td>1日あたり{{ tools[tool.codeCamel].point | localeNum }}ポイント</td>
            </tr> -->
            <template v-if="tool.codeKebab === 'tools'">
              <template
                v-for="(toolCodeCamel, index) in ['googleCv', 'yahooCv','facebookCv','microsoftCv']"
              >
                <tr :key="index">
                  <th class="title">
                    <div style="font-size:15.5px;">{{ tools[toolCodeCamel].name2 }}の消費ポイント</div>
                  </th>
                </tr>
                <tr :key="index + 100">
                  <td>
                    <div v-if="toolCodeCamel === 'facebookCv'">
                      1日あたり{{
                        tools[toolCodeCamel].point | localeNum
                      }}ポイント
                        <span v-show="isCampaign === true"><p style="text-align: center; color: #f56c6c; font-weight: bold;font-size:14px;">2022年12月31日まで：1日あたり100ポイント</p></span>
                    </div>
                    <div v-else>
                      1日あたり{{
                        tools[toolCodeCamel].point | localeNum
                      }}ポイント
                    </div>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <th class="title">
                  <div>{{ tools[tool.codeCamel].name2 }}の消費ポイント</div>
                </th>
              </tr>
              <tr>
                <td>
                  <div>
                    1日あたり{{
                      tools[tool.codeCamel].point | localeNum
                    }}ポイント
                  </div>
                </td>
              </tr>
            </template>
          </table>
          <table>
            <tr>
              <th class="title">ポイント有効期限</th>
            </tr>
            <tr class="border">
              <td>
                <div>
                  お試しのみ購入後3ヶ月間
                  <br />
                  <span style="font-size: 14px">通常は購入後1年間（※）</span>
                </div>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <th class="title">サポート</th>
            </tr>
            <tr class="border">
              <td>メール</td>
            </tr>
          </table>
          <table>
            <tr>
              <th class="title">自動更新のタイミング</th>
            </tr>
            <tr class="border">
              <td class="left">
                <p>
                  自動更新の設定中は、ポイント残高が{{
                    (apMinValue - 1) | localeNum
                  }}になる直前に決済が執行されます。自動更新設定中はツールの利用がストップすることはございません。
                </p>
                <p>
                  「自動更新」は管理画面でいつでも停止できます（縛りはありません）。
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <th class="title">決済方法</th>
            </tr>
            <tr class="border">
              <td>
                <p style="text-align: center; margin: 0 0 5px">
                  クレジットカードのみ
                </p>
                <div style="text-align: center">
                  <img
                    class="card-brands"
                    :src="`${rt}/img/card-5brand.png`"
                    alt
                    width="100%"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <h4 style="font-size: 14px; margin-bottom: 5px">
          (※)ポイントの有効期限について
        </h4>
        <p style="font-size: 14px; margin-top: 5px">
          ポイントの有効期限は、"購入した本日から1年後の月末"です（例：2021年1月10日にポイントを購入した場合、そのポイントの有効期限は2022年1月31日となります）。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlanTable",
  components: {},
  props: {
    productId: String,
  },
  data() {
    return {
      isCampaign: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools", "apMinValue"]),
    productData() {
      return this.$store.getters.productData(this.productId);
    },
  },
  created(){
    this.checkCampaign();
  },
  methods: {
    checkCampaign(){
      let nowDate = new Date();
      let limitDate = new Date('2023-01-01');
      
      if (nowDate < limitDate) {
        this.isCampaign = true;
      } else {
        this.isCampaign = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
table.plans {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  thead {
    tr {
      th {
        font-weight: bold;
        background-color: rgb(28, 109, 181);
        color: white;
        padding: 20px;
      }
    }
  }
  tbody {
    tr {
      th {
        background-color: #e1ecf6;
        width: 340px;
      }
    }
  }
  tr {
    th {
      font-weight: normal;
    }
    th,
    td {
      border: 1px solid #666;
      text-align: center;
      padding: 18px;
      div {
        p {
          margin: 2px;
          text-align: start;
        }
      }
    }
  }
}
.small00 {
  font-size: 14px !important;
}
.left {
  @media screen and (max-width: $max-width) {
    text-align: left !important;
  }
}
</style>