<template>
  <div class="GreatValue">
    <div class="title_cover">
        <div class="GreatValue_title">
          <div class="check_icon">
            <p>Check!</p>
          </div>
            <p>アドタスカルがお得な理由</p>
        </div>
    </div>
    <div class="GreatValue_txt">
        <div class="box">
          <Template012
            :TemplateTitle="'月末にPVの未消化が出る毎月更新制ではありません！'"
          />
            <!-- <p class="GV_title">
              月末にPVの未消化が出る毎月更新制ではありません！  
            </p> -->
            <p class="txt_size">
              アドタスカルは「月額更新制」ではなく、「ポイント消化制（ポイントの有効期限１年間）」のため、PV未消化の無駄が発生しません（残ったPV数を翌月へ持ち越せる）。ポイントの有効期限は1年間なので、使い切れないことは、まずありません。  
            </p>
        </div>
        <div class="box">
          <Template012
            :TemplateTitle="'使ったツール、使った回数のみポイント消化の対象となります！'"
          />
            <!-- <p class="GV_title">
              使ったツール、使った回数のみポイント消化の対象となります！  
            </p> -->
            <p class="txt_size">
              アドタスカルは、使ったツール、使った回数のみポイント消化の対象となります。自分が使わないツールまで料金に含めた価格設定を取っていません。  
            </p>
        </div>
        <div class="box">
          <Template012
            :TemplateTitle="'契約期間の縛りが一切ありません！'"
          />
            <!-- <p class="GV_title">
              契約期間の縛りが一切ありません！  
            </p> -->
            <p class="txt_size">
              自動更新プランも管理画面から、ボタン１つで自動更新のオフができます。  
            </p>
        </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
import Template012 from "@/components/Template012.vue";

export default {
  name: "GreatValue",
  props: {},
  components: {
    Template012,
  },
  data() {
    return {
      rt: store.state.rt
    };
  },
};
</script>

<style lang="scss" scoped>
.GreatValue {
    padding: 0 70px 110px;
    // background-color: #d8e6f2;
    margin-bottom: 120px;
        @media screen and (max-width: $max-width) {
          padding: 0 0 110px;

        }
}
.title_cover {
    .GreatValue_title {
        position: relative;
        width: 750px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $max-width) {
          width: 100%;
        }
        p {
            z-index: 2;
            font-weight: bold;
            font-size: 40px;
            letter-spacing: 0.1em;
            color: #1c6db5;
            margin: 0;
            @media screen and (max-width: $max-width) {
              font-size: 33px;
        }
        }
        .check_icon {
            position: absolute;
            top: -50px;
            left: 0;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            // background-color: yellow;
            background-color: #fdef6a;
            // background-color: #f15e62;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            @media screen and (max-width: $max-width) {
              top: -80px;
              width: 80px;
              height: 80px;
            }
            p {
              font-weight: bold;
              font-size: 18px;
              letter-spacing: 0.1em;
              transform:rotate(-20deg);
              // color: white;
              // color: #4d4d4d;
            @media screen and (max-width: $max-width) {
              font-size: 15px;
            }
            }
        }
}
}
.GreatValue_txt {
    .box {
        // .GV_title {
        //     font-size: 28px;
        //     font-weight: bold;
        //     color: #1c6db5;
        //     letter-spacing: 0.05em;
        //     margin-bottom: 0;
        //     margin-top: 50px;
        // }
        .template012_cover {
          padding-bottom: 0;
          margin-top: 60px;
        @media screen and (max-width: $max-width) {
          margin-top: 35px;
        }
        }
    }
}

</style>
