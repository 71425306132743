<template>
  <div class="ToolLineup">
    <p v-if="type !== 'B'" class="title pc">アドタスカルのツールラインナップ</p>
    <p v-if="type !== 'B'" class="title sp">
      アドタスカルの
      <br />ツールラインナップ
    </p>

    <div class="line_up2-cover">
      <div class="line_up2">
        <div class="logo-cover">
          <img
            class="cv-logo"
            :src="rt + '/img/lp/google-ads.png'"
            alt="Google広告成果連携"
          />
        </div>
        <div class="tool_box">
          <p class="tool_name">
            Google広告<br />成果連携ツール<span v-if="type === 'B'"
              ><br /><router-link :to="`/google-cv`"
                ><el-button style="margin-top: 12px"
                  >詳細を見てみる</el-button
                ></router-link
              ></span
            >
          </p>
        </div>
      </div>

      <div class="line_up2 yahoo">
        <div class="logo-cover">
          <img
            class="yahoo-logo"
            :src="rt + '/img/yahoo-icon.png'"
            alt="yahooJapan"
          />
        </div>
        <div class="tool_box">
          <p class="tool_name">
            Yahoo!検索広告<br />成果連携ツール<span v-if="type === 'B'"
              ><br /><router-link :to="`/yahoo-cv`"
                ><el-button style="margin-top: 12px"
                  >詳細を見てみる</el-button
                ></router-link
              ></span
            >
          </p>
        </div>
      </div>
      <div class="line_up2">
        <div class="logo-cover">
          <img class="tt-logo" :src="rt + '/img/tool15.png'" alt="yda" />
          <!-- <img
            class="tt-logo"
            :src="rt + '/img/lp/microsoft-cv/microsoft-logo.png'"
            alt="microsoft"
          /> -->
        </div>
        <div class="tool_box">
          <p class="tool_name">
            YDA<br />成果連携ツール<span v-if="type === 'B'"
              ><br /><router-link :to="`/yda-cv`"
                ><el-button style="margin-top: 12px"
                  >詳細を見てみる</el-button
                ></router-link
              ></span
            >
          </p>
        </div>
      </div>

      <div class="line_up2">
        <div class="logo-cover">
          <img
            class="fb-logo"
            :src="rt + '/img/lp/facebook.png'"
            alt="Facebook"
          />
        </div>
        <div class="tool_box">
          <p class="tool_name" style="margin-bottom: 0px">
            Facebook広告<br />コンバージョンAPI連携ツール
          </p>
          <span v-if="type === 'B'">
            <p class="ready">※利用可能。<br />詳細ページ準備中</p>
          </span>
          
        </div>
      </div>

      <div class="line_up2">
        <div class="logo-cover">
          <img class="tt-logo" :src="rt + '/img/tool12.png'" alt="microsoft" />
          <!-- <img
            class="tt-logo"
            :src="rt + '/img/lp/microsoft-cv/microsoft-logo.png'"
            alt="microsoft"
          /> -->
        </div>
        <div class="tool_box">
          <p class="tool_name">
            Microsoft広告<br />成果連携ツール<span v-if="type === 'B'"
              ><br /><router-link :to="`/microsoft-cv`"
                ><el-button style="margin-top: 12px"
                  >詳細を見てみる</el-button
                ></router-link
              ></span
            >
          </p>
        </div>
      </div>
      <!-- <div class="line_up2">
        <div class="logo-cover">
          <img class="tt-logo" :src="rt + '/img/tool14.png'" alt="lap" />
        </div>
        <div class="tool_box">
          <p class="tool_name">
            LAP<br />成果連携ツール<span v-if="type === 'B'"
              ></span
            >
          </p>
        </div>
      </div> -->
      <!-- <div class="line_up2">
        <div class="logo-cover">
          <img class="tt-logo" :src="rt + '/img/tool11.png'" alt="tiktok" />
        </div>
        <div class="tool_box">
          <p class="tool_name">
            TikTok広告<br />成果連携ツール<span v-if="type === 'B'"
              ></span
            >
          </p>
        </div>
      </div> -->
      <div class="line_up2">
        <div class="logo-cover">
          <img class="tt-logo" :src="rt + '/img/tool16.png'" alt="Squad beyond" />
        </div>
        <div class="tool_box">
          <p class="tool_name" style="margin-bottom: 0px">
            Squad beyond<br />成果連携ツール
          </p>
          <span v-if="type === 'B'">
            <p class="ready">※利用可能。<br />詳細ページ準備中</p>
          </span>
        </div>
      </div>
      <div class="line_up2">
      </div>
      <div class="line_up2">
      </div>
    </div>
    <div v-if="type === 'B'">
      <p class="kome">
        ※Google社・Yahoo!社・Facebook社・Microsoft社・SIVA社が提供するツールではございません。<br />
      </p>
      <div class="tool-cover">
        <h3 style="margin-bottom: 0">開発状況について</h3>
        <p style="margin-top: 10px">
          ツールの開発状況やリリース日時の情報を得たい場合は、<a
            href="https://twitter.com/adtasukaru"
            target="_blank"
            rel="noopener noreferrer"
            >アドタスカル公式Twitterアカウント</a
          >のフォローをお願いいたします。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ToolLineup",
  props: {
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
};
</script>

<style lang="scss" scoped>
.ToolLineup {
  @media screen and (max-width: $max-width) {
    padding-bottom: 40px;
  }
}
.title {
  margin-top: 90px;
  @media screen and (max-width: $max-width) {
    margin-top: 50px;
  }
}
.line_up {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 35px;
  margin-top: 40px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap-reverse;
    align-items: center;
    background-position: 0% 5%;
    margin-top: 0;
    padding-bottom: 15px;
  }
  img {
    margin-left: 60px;
    @media screen and (max-width: $max-width) {
      margin-left: 0;
      width: 46%;
    }
  }
}
.line_up2-cover {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .line_up2 {
    width: 22%;
    padding-bottom: 0;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    @media screen and (max-width: $max-width) {
      width: 45%;
      align-items: center;
      background-position: 0% 5%;
      padding-bottom: 30px;
      margin-top: 0;
    }
    .logo-cover {
      width: 100%;
      background-color: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 280px;
      @media screen and (max-width: 767px) {
        height: 50%;
        width: 100%;
      }
      .cv-logo {
        width: 80%;
        @media screen and (max-width: $max-width) {
          width: 65% !important;
          padding: 0 0 20px;
        }
      }
      .fb-logo {
        width: 80%;
        @media screen and (max-width: $max-width) {
          width: 70% !important;
          padding: 30px 0;
        }
      }
      .yahoo-logo {
        width: 80%;
        @media screen and (max-width: $max-width) {
          width: 70% !important;
          padding: 30px 0;
        }
      }
      .tt-logo {
        // width: 170px;
        width: 80%;
        @media screen and (max-width: $max-width) {
          width: 65% !important;
          padding: 30px 0;
        }
      }
    }
    img {
      @media screen and (max-width: $max-width) {
        width: 46%;
        margin-right: 0;
      }
    }
    .tool_box {
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
      .tool_name {
        font-size: 24px;
        font-weight: bold;
        color: #1c6db5;
        text-align: center;
        margin-bottom: 50px;
        @media screen and (max-width: $max-width) {
          margin-top: 5px;
          margin-block-end: 0;
          font-size: 20px;
        }
      }
      .ready {
        color: #1c6db5;
        text-align: center;
        font-size: 20px;
        margin-top: 10px;
      }
      .btn_cover {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
        @media screen and (max-width: $max-width) {
          margin-top: 30px;
        }
        .more_btn {
          background-color: #1c6db5;
          border-radius: 5px;
          display: block;
          padding: 5px 10px;
          width: 100px;
          text-align: center;
          p {
            color: white;
            margin-block-start: 0;
            margin-block-end: 0;
          }
        }
      }
    }
  }
}
.pinpoint-style {
  margin-top: 25px !important;
}

.yahoo {
  padding-bottom: 60px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 0;
  }
}
.tool-cover {
  padding-top: 50px;
  padding-bottom: 100px;
}
.kome {
  font-size: 14px;
  text-align: right;
  margin-top: 0;
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}
</style>
