<template>
  <div class="tokuteisho">
    <div class="wrap">
      <h1>特定商取引法に基づく表記</h1>

      <table>
        <tbody>
          <tr>
            <th>販売会社名</th>
            <td>株式会社グレイトヘルプ</td>
          </tr>
          <tr>
            <th>運営総括責任者</th>
            <td>小関貴久</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>岐阜県岐阜市加納桜田町2-5-2</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>058-214-9005</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>inquiry@adtasukaru.com</td>
          </tr>
          <tr>
            <th>販売URL</th>
            <td>https://adtasukaru.com</td>
          </tr>
          <tr>
            <th>お支払い方法</th>
            <td>クレジットカード</td>
          </tr>
          <tr>
            <th>販売価格帯</th>
            <td>{{ productData.price2 | localeNum }}円～18,000円（税別）</td>
          </tr>
          <tr>
            <th>商品代金以外に必要な費用<br />（送料、手数料、消費税等）</th>
            <td>消費税</td>
          </tr>
          <tr>
            <th>継続課金の解約条件・解約方法</th>
            <td>
              解約は、アドタスカル管理画面から「継続課金(自動更新)の設定」を解除することで常時可能です。
            </td>
          </tr>
          <tr>
            <th>返品について</th>
            <td>購入後の返品はできません。</td>
          </tr>
          <tr>
            <th>表現、及び、商品に関する注意書き</th>
            <td>
              当サービスは、必ずしも効果や利益を保証したものではございません。
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      tool: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "mainProductId"]),
    productData() {
      return this.$store.getters.productData(this.mainProductId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.tokuteisho {
  padding-top: 20px;
  margin-bottom: 150px;
  table {
    border: 1px solid #ccc;
    margin: 0 auto;
    border-collapse: collapse;
    th {
      border: 1px solid #ccc;
      background-color: #f2f2f2;
      padding: 12px 0;
      color: #4d4d4d;
      font-size: 17px;
      letter-spacing: 0.05em;
      text-align: center;
      width: 35%;
    }
    td {
      border: 1px solid #ccc;
      padding: 12px 30px;
      font-size: 17px;
      letter-spacing: 0.05em;
      @media screen and (max-width: $max-width) {
        text-align: center;
      }
    }
  }
}
</style>
