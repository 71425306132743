<template>
  <div class="tools02-contents">
    <div class="MAIN_IMG">
      <div v-if="tool.codeKebab === 'tools'">
        <img
          class="main_img pc"
          :src="`${rt}/img/lp/top/top.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
        <img
          class="main_img sp"
          :src="`${rt}/img/lp/top/top-sp.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
      </div>
      <div v-if="tool.codeKebab === 'google-cv'">
        <img
          class="main_img pc"
          :src="`${rt}/img/lp/google-cv/top.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
        <img
          class="main_img sp"
          :src="`${rt}/img/lp/google-cv/top-sp.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
      </div>
      <div v-else-if="tool.codeKebab === 'yahoo-cv'">
        <img
          class="main_img pc"
          :src="`${rt}/img/lp/yahoo-cv/top.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
        <img
          class="main_img sp"
          :src="`${rt}/img/lp/yahoo-cv/top-sp.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
      </div>
      <div v-else-if="tool.codeKebab === 'microsoft-cv'">
        <img
          class="main_img pc"
          :src="`${rt}/img/lp/microsoft-cv/top-micro.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
        <img
          class="main_img sp"
          :src="`${rt}/img/lp/microsoft-cv/top-micro-sp.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
      </div>
      <div v-else-if="tool.codeKebab === 'yda-cv'">
        <img
          class="main_img pc"
          :src="`${rt}/img/lp/yda-cv/top-yda.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
        <img
          class="main_img sp"
          :src="`${rt}/img/lp/yda-cv/top-yda-sp.jpg`"
          alt="アドタスカルトップページメインイメージ画像"
        />
      </div>
    </div>

    <div class="bg_color" style="padding: 20px 0">
      <div class="wrap" style="margin-top: 0">
        <Trial00 />
      </div>
    </div>

    <div class="wrap">
      <h1>
        広告成果連携ツール
        <br class="sp" />「アドタスカル」とは？
      </h1>
      <div class="movie-space">
        <iframe
          src="https://player.vimeo.com/video/678034881?h=fe36ed5b20"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <p class="point-space-padding txt_size">
        広告成果連携ツール「アドタスカル」は、
        <span v-if="tool.codeKebab === 'google-cv'"
          >Google広告やFacebook広告</span
        >
        <span v-else-if="tool.codeKebab === 'facebook-cv'"
          >Facebook広告やYahoo!検索広告</span
        >
        <span v-else-if="tool.codeKebab === 'yahoo-cv'"
          >Yahoo!検索広告やGoogle広告</span
        >
        <span v-else-if="tool.codeKebab === 'microsoft-cv'"
          >Microsoft広告やGoogle広告</span
        >
        <span v-else-if="tool.codeKebab === 'yda-cv'"
          >YDA、Yahoo!検索広告、Google広告</span
        >
        <span v-else-if="tool.codeKebab === 'tools'"
          >Google広告やYahoo!検索広告</span
        >などの各広告媒体の成果計測パラメータを、自社のサイト上のアフィリエイトリンクに引き継ぎ、ASP管理画面上の成果データにパラメータを反映させ、その成果データをASPから各広告媒体の管理画面へ自動/半自動でアップロードする便利ツールです。アドタスカルによって、広告媒体管理画面上の成果とASP管理画面上の成果の乖離をなくすことができます。
        <span v-if="tool.codeKebab === 'google-cv'"
          >iOSのアップデートによって付与されるようになったwbraidパラメータも対応しています。</span
        >
        <span v-if="tool.codeKebab === 'tools'"
          >※Facebook広告コンバージョンAPI連携ツールはアドタスカルを利用しても成果の乖離が一部発生します。</span
        >
      </p>
      <div v-if="tool.codeKebab === 'tools'">
        <Lineup :type="'B'" />
      </div>
      <div v-else class="cooperation">
        <div class="medium-cover">
          <div class="medium" style="padding-bottom: 0">
            <p class="category-title">対応済の媒体</p>
            <div class="medium-logo-intend">
              <div class="medium-logo-intend-inner">
                <img
                  :src="rt + '/img/lp/google-cv/google-logo-p.jpg'"
                  alt="google-cv"
                />
                <img
                  :src="rt + '/img/lp/yahoo-cv/yahoo-logo-pc.jpg'"
                  alt="yahooJapan"
                />
                <img
                  :src="rt + '/img/tool15.png'"
                  alt="yda"
                />
                
              </div>
              <div class="medium-logo-intend-inner">
                <img
                  :src="rt + '/img/lp/facebook-cv/fb-logo-pc.jpg'"
                  alt="facebook"
                />
                <img
                  :src="rt + '/img/tool12.png'"
                  alt="microsoft"
                  style="width: 140px"
                />
                <img
                  :src="rt + '/img/tool16.png'"
                  alt="squadbeyond"
                />
                <!-- <img
                  :src="rt + '/img/lp/microsoft-cv/ms-logo-pc.jpg'"
                  alt="microdoft"
                /> -->
                <!-- <img :src="rt + '/img/youtube.jpg'" alt="youtube" /> -->
              </div>
            </div>
          </div>
          <!-- <p style="font-size: 14px">
            <span v-if="tool.codeKebab !== 'google-cv'">
              ※直近のiOSのアップデートにより、現在、iOSのYouTubeアプリ経由の成果が取れない状況となっております。そのため、YouTube広告、ファインドキャンペーンにおいては、アドタスカルを使用しても成果の乖離が発生してしまいます。YouTube広告、ファインドキャンペーンをメインでご利用のアフィリエイター様は、この点についてご注意をお願いいたします。
            </span>
          </p> -->
          <!-- <div class="medium">
            <p class="category-title">対応予定の媒体</p>
            <div class="medium-logo-intend">
              <div class="medium-logo-intend-inner" style="width: inherit">
                <img
                  :src="rt + '/img/lp/tiktok.png'"
                  alt="TikTok"
                  class="developing"
                  style="margin-top: 5px"
                />
              </div>
              <p class="txt-pointstyle">
                <span style="font-weight: bold; font-size: 15px"
                  >開発状況について</span
                ><br />
                ツールの開発状況やリリース日時の情報を得たい場合は、
                <a
                  href="https://twitter.com/adtasukaru"
                  target="_blank"
                  rel="noopener noreferrer"
                  >アドタスカル公式Twitterアカウント</a
                >のフォローをお願いいたします。
              </p>
            </div>
            <p class="pointstyle small-txt">
              ※Google社・Yahoo!社・Facebook社・Microsoft社・LINE社・TikTok社が提供するツールではございません
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="bg_color">
      <div class="wrap">
        <h1>アド系ASP50社超 ＋ カート/計測システム6社と連携</h1>
        <div class="cooperation">
          <div class="medium-cover">
            <div class="medium" style="padding-bottom: 0">
              <p class="category-title">連携済みのASP</p>
              <div class="medium-logo">
                <img
                  :src="`${rt}/img/asp-logos/felmat-logo.jpg`"
                  alt="felmat"
                />
                <img
                  :src="`${rt}/img/asp-logos/slvrbullet-logo.jpg`"
                  alt="slvrbullet"
                />
                <img :src="`${rt}/img/asp-logos/ceres-logo.jpg`" alt="ceres" />
                <img
                  :src="`${rt}/img/asp-logos/rentracks-logo.jpg`"
                  alt="rentracks"
                />
                <img
                  :src="`${rt}/img/asp-logos/sarucrew-logo.jpg`"
                  alt="sarucrew"
                />
                <img :src="`${rt}/img/asp-logos/scan-logo.jpg`" alt="scan" />
                <img :src="`${rt}/img/asp-logos/fam-logo.jpg`" alt="fam" />
                <img :src="`${rt}/img/asp-logos/fam2-logo.jpg`" alt="fam2" />
                <img
                  :src="`${rt}/img/asp-logos/link_a-logo.jpg`"
                  alt="link_a"
                />
                <img :src="`${rt}/img/asp-logos/afb-logo.jpg`" alt="afb" />
                <img
                  :src="`${rt}/img/asp-logos/accesstrade-logo-kome.jpg`"
                  alt="accesstrade"
                />
                <img :src="`${rt}/img/asp-logos/mobee-logo.jpg`" alt="mobee" />
                <img
                  :src="`${rt}/img/asp-logos/a8net-logo-kome.jpg`"
                  alt="a8net"
                />
                <img :src="`${rt}/img/asp-logos/janet-logo.jpg`" alt="janet" />
                <img
                  :src="`${rt}/img/asp-logos/medipartner-logo.jpg`"
                  alt="medipartner"
                />
                <img
                  :src="`${rt}/img/asp-logos/bulkad-logo.jpg`"
                  alt="bulkad"
                />
                <img
                  :src="`${rt}/img/asp-logos/atmarktension-logo.jpg`"
                  alt="atmarktension"
                />
                <img
                  :src="`${rt}/img/asp-logos/tempuralink-logo.jpg`"
                  alt="tempuralink"
                />
                <img
                  :src="`${rt}/img/asp-logos/sixpack-logo.jpg`"
                  alt="sixpack"
                />
                <img
                  :src="`${rt}/img/asp-logos/twowin-logo.jpg`"
                  alt="twowin"
                />
                <img
                  :src="`${rt}/img/asp-logos/gorilatte-logo.jpg`"
                  alt="gorilatte"
                />
                <img :src="`${rt}/img/asp-logos/afad-logo.jpg`" alt="afad" />
                <img
                  :src="`${rt}/img/asp-logos/imperial-logo.jpg`"
                  alt="imperial"
                />
                <img
                  :src="`${rt}/img/asp-logos/selfleap-logo.jpg`"
                  alt="selfleap"
                />
                <img
                  :src="`${rt}/img/asp-logos/arrows-logo.jpg`"
                  alt="arrows"
                />
                <img
                  :src="`${rt}/img/asp-logos/icounter-logo.jpg`"
                  alt="icounter"
                />
                <img
                  :src="`${rt}/img/asp-logos/adtrip-logo.jpg`"
                  alt="adtrip"
                />
                <img
                  :src="`${rt}/img/asp-logos/ashisuta-logo.jpg`"
                  alt="ashisuta"
                />
                <img
                  :src="`${rt}/img/asp-logos/linksharetg-logo.jpg`"
                  alt="linksharetg"
                />
                <img
                  :src="`${rt}/img/asp-logos/akibito-logo.jpg`"
                  alt="akibito"
                />
                <img :src="`${rt}/img/asp-logos/ss_af-logo.jpg`" alt="ss_af" />
                <img
                  :src="`${rt}/img/asp-logos/vly_cns-logo.jpg`"
                  alt="vly_cns"
                />
                <img
                  :src="`${rt}/img/asp-logos/tosho_af-logo.jpg`"
                  alt="tosho_af"
                />
                <img
                  :src="`${rt}/img/asp-logos/ad_lavi-logo.jpg`"
                  alt="ad_lavi"
                />
                <img :src="`${rt}/img/asp-logos/m_ads-logo.jpg`" alt="m_ads" />
                <img
                  :src="`${rt}/img/asp-logos/presco-logo.jpg`"
                  alt="presco"
                />
                <img
                  :src="`${rt}/img/asp-logos/ad_mogra-logo.jpg`"
                  alt="ad_mogra"
                />
                <img
                  :src="`${rt}/img/asp-logos/af_110-logo.jpg`"
                  alt="af_110"
                />
                <img
                  :src="`${rt}/img/asp-logos/adbomber-logo.jpg`"
                  alt="adbomber"
                />
                <img
                  :src="`${rt}/img/asp-logos/decotra-logo.jpg`"
                  alt="decotra"
                />
                <img
                  :src="`${rt}/img/asp-logos/aigate-logo.jpg`"
                  alt="aigate"
                />
                <img
                  :src="`${rt}/img/asp-logos/finebind-logo.jpg`"
                  alt="finebind"
                />
                <img
                  :src="`${rt}/img/asp-logos/circuit-logo.jpg`"
                  alt="circuit"
                />
                <img
                  :src="`${rt}/img/asp-logos/ac_trictrac-logo.jpg`"
                  alt="ac_trictrac"
                />
                <img
                  :src="`${rt}/img/asp-logos/project_ad-logo.jpg`"
                  alt="project_ad"
                />
                <img
                  :src="`${rt}/img/asp-logos/funny_style-logo.jpg`"
                  alt="funny_style"
                />
                <img
                  :src="`${rt}/img/asp-logos/gro_fru-logo.jpg`"
                  alt="gro_fru"
                />
                <img
                  :src="`${rt}/img/asp-logos/webthor-logo.jpg`"
                  alt="webthor"
                />
                <!-- <img :src="`${rt}/img/asp-logos/dummy.jpg`" alt="dummy" />
                <img :src="`${rt}/img/asp-logos/dummy.jpg`" alt="dummy" /> -->
              </div>
            </div>
            <p>(※1)自動連携の利用に関しては、ASP側での審査があります。</p>
            <!-- <p>(※2)アドタスカルとの連携を希望されるASP様は、お問い合わせからご連絡くださいませ。</p> -->
            <p>
              (※2)アドタスカルとの連携を希望されるASP様は、お問い合わせからご連絡くださいませ。「アフィリコード・システム」「CATS」「Link-A」などのアフィリエイトシステムをご利用のASP様は優先的に連携させていただきます。
            </p>
          </div>
        </div>
        <div class="cooperation cart-system-list">
          <div class="medium-cover">
            <div class="medium" style="padding-bottom: 0">
              <p class="category-title">連携済みカート/計測システム</p>
              <div class="medium-logo">
                <img
                  :src="`${rt}/img/asp-logos/ecforce-logo.jpg`"
                  alt="ecforce"
                  class="cart-logo"
                />
                <img
                  :src="`${rt}/img/asp-logos/adebis-logo.jpg`"
                  alt="adebis"
                  class="cart-logo"
                />
                <img :src="`${rt}/img/asp-logos/rpst-logo.jpg`"
                 alt="rpst"
                 class="cart-logo"
                 />
                <img
                  :src="`${rt}/img/asp-logos/cart_affilicode-logo.jpg`"
                  alt="cart_affilicode"
                  class="cart-logo"
                />
                <img
                  :src="`${rt}/img/asp-logos/cart_afad-logo.jpg`"
                  alt="cart_afad"
                  class="cart-logo"
                />
                <img :src="`${rt}/img/asp-logos/cart_cats-logo.jpg`" 
                  alt="cart_cats"
                  class="cart-logo"
                />
                
                <!-- <img :src="`${rt}/img/asp-logos/dummy.jpg`" alt="dummy" />
                <img :src="`${rt}/img/asp-logos/dummy.jpg`" alt="dummy" /> -->
              </div>
            </div>
            <p>(※)カート/計測システムの利用には別途料金が発生します。詳細は<a href="#cost_cart">コチラ</a>。</p>
            <!-- <p>(※2)アドタスカルとの連携を希望されるASP様は、お問い合わせからご連絡くださいませ。</p> -->
            <p>(※)ユーザー様からのリクエストに応じて、連携先を追加予定です。</p>
          </div>
        </div>
      </div>
      <div v-if="tool.codeKebab === 'tools'" class="wrap" style="margin-top: 0">
        <UiVoice
          :voiceIdList="[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '14',
            '15',
            '16',
          ]"
        />
      </div>
    </div>

    <div v-if="tool.codeKebab !== 'tools'" class="wrap">
      <p class="title">
        {{ tool.mediaName }}を利用する人なら
        <br class="sp" />誰もが抱えている悩み・・・
      </p>
      <p class="cv-txt pc">
        ASPの管理画面では成果が発生しているのに、{{
          tool.mediaName
        }}の管理画面では
        <br />成果データが計測されていない、成果の乖離が発生している・・そのせいで・・
      </p>
      <p class="cv-txt sp">
        ASPの管理画面では成果が発生しているのに、{{
          tool.mediaName
        }}の管理画面では成果データが計測されていない、成果の乖離が発生している・・そのせいで・・
      </p>
      <div class="check-cover00">
        <ul
          class="check check-icon-li"
          :style="`list-style: url('${rt}/img/lp/check-mark.png');`"
        >
          <li class="check-list">自動最適化が崩れる</li>
          <li class="check-list">正確な分析ができない</li>
          <li v-show="tool.codeKebab === 'google-cv'" class="check-list">
            コンバージョンキーワードが分からない
          </li>
          <li class="check-list">ASPにタグの再設置を依頼する必要がある</li>
          <li class="check-list">CVが発火されるか心配</li>
          <li class="check-list">
            これらの問題の解決や対応など、無駄な作業に時間を使っている
          </li>
        </ul>
      </div>

      <p class="txt_size check-txt pc">
        このような問題を抱えながら、アドを続けていくことはできますか？
        <br />成果が正確に計測されずにイライラする思いをまだ続けますか？
        <br />今後もタグの再設置依頼を何度しますか？
      </p>
      <p class="txt_size check-txt sp">
        このような問題を抱えながら、アドを続けていくことはできますか？
        <br />
        <br />成果が正確に計測されずにイライラする思いをまだ続けますか？
        <br />
        <br />今後もタグの再設置依頼を何度しますか？
      </p>
      <p class="copy-txt">
        アドタスカルを使用すれば、このような問題を解決できます！
      </p>
    </div>

    <div v-if="tool.codeKebab !== 'tools'" class="bg_color">
      <div class="wrap">
        <div class="ResultAndEffect">
          <p class="title">
            {{ tool.mediaName }}の管理画面で
            <br class="sp" />成果を正確に計測する方法
          </p>
          <p class="txt_size">
            {{
              tool.mediaName
            }}の管理画面とASPの管理画面で成果発生数の乖離をなくすには、{{
              tool.mediaName
            }}が公式で提供する「{{ (tool.codeKebab === 'yda-cv')?'コンバージョンAPI機能':'オフラインコンバージョン' }}機能」を使用することがそのノウハウとなります。
            <br />
            <br />
            {{
              tool.mediaName
            }}の「{{ (tool.codeKebab === 'yda-cv')?'コンバージョンAPI機能':'オフラインコンバージョン' }}機能」を利用し、以下の作業をすることで、{{
              tool.mediaName
            }}の管理画面とASPの管理画面で成果発生数の乖離をなくすことができます。
          </p>

          <div class="movie-space">
            <iframe
              v-if="tool.codeKebab === 'google-cv'"
              src="https://player.vimeo.com/video/535678330?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="LP内の動画1"
            ></iframe>
            <iframe
              v-else-if="tool.codeKebab === 'yahoo-cv'"
              src="https://player.vimeo.com/video/645477840?h=ec896d8559"
              width="640"
              height="480"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              v-else-if="tool.codeKebab === 'microsoft-cv'"
              src="https://player.vimeo.com/video/778829774?h=4df40dfcd9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="640"
              height="480"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="howto">
            <p class="howto-title">
              {{
                tool.mediaName
              }}の{{ (tool.codeKebab === 'yda-cv')?'コンバージョンAPI機能':'オフラインコンバージョン' }}機能を用いた成果数の乖離防止方法
            </p>
            <p class="howto-step">
              <span>1</span
              >記事LPや中間ページに対して広告パラメータを引き継ぐ設定をする
            </p>
            <p class="howto-step">
              <span>2</span
              >使用しているASPから成果データをCSV形式でダウンロードする
            </p>
            <p class="howto-step">
              <span>3</span>
              各ASPの成果データを{{ tool.mediaName }}{{ (tool.codeKebab === 'yda-cv')?'にAPI送信する':'指定の形式に書き換える' }}
            </p>
            <template v-if="tool.codeKebab === 'yda-cv'">
            </template>
            <template v-else>
              <p class="howto-step">
                <span>4</span>
                {{ tool.mediaName }}の管理画面に成果データをアップロードする
              </p>
            </template>
          </div>

          <div>
            <p class="txt_size check-txt">
              {{ (tool.codeKebab === 'yda-cv')?'コンバージョンAPI機能':'オフラインコンバージョン' }}機能を利用した{{
                tool.mediaName
              }}の成果乖離防止は、ここで説明した方法で実現できますが、実際に自分で実行しようと思うと、以下をご自身で対応する必要があります。
            </p>
          </div>

          <div class="check-cover00">
            <ul
              class="check check-icon-li"
              :style="`list-style: url('${rt}/img/lp/check-mark.png');`"
            >
              <li class="check-list">
                パラメータを引き継ぐプログラムを作る必要がある
              </li>
              <li class="check-list">
                各ASPの管理画面から手動で成果データを取得する必要がある
              </li>
              <li class="check-list">
                各ASPの成果データを{{
                  tool.mediaName
                }}指定の形式に書き換える必要がある
              </li>
              <li class="check-list">
                各ASPの成果データを{{
                  tool.mediaName
                }}の管理画面にインポートする必要がある
              </li>
            </ul>
          </div>
          <p class="copy-txt">この作業をアドタスカルなら自動化できます！</p>
        </div>
      </div>
    </div>

    <div v-if="tool.codeKebab !== 'tools'" class="wrap">
      <p class="title">
        {{ tools[tool.codeCamel].name2 }}
        <br class="sp" />の利用について
      </p>
      <div class="movie-space">
        <iframe
          v-if="tool.codeKebab === 'google-cv'"
          src="https://player.vimeo.com/video/535679114?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="LP内の動画2"
        ></iframe>
        <iframe
          v-else-if="tool.codeKebab === 'yahoo-cv'"
          src="https://player.vimeo.com/video/645478380?h=ccdeabb203"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          v-else-if="tool.codeKebab === 'microsoft-cv'"
          src="https://player.vimeo.com/video/780586809?h=1f38034429&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p class="txt_size">
        アドタスカルでは、以下の設定をしていただくことで、{{
          tool.mediaName
        }}の成果連携の自動化を実現することができます。
      </p>

      <div class="howto02">
        <p class="howto-step">
          <span>1</span>
          {{ tool.mediaName }}管理画面で{{ (tool.codeKebab === 'yda-cv')?'コンバージョンAPI機能':'オフラインコンバージョン' }}を設定
        </p>
        <p class="txt_size">
          お使いの{{
            tool.mediaName
          }}アカウントにて{{ (tool.codeKebab === 'yda-cv')?'コンバージョンAPI機能':'オフラインコンバージョン' }}の設定をします。
        </p>

        <p class="howto-step">
          <span>2</span>
          {{ tool.mediaName }}アカウントとASPプログラム情報の登録
        </p>
        <p class="txt_size">
          お使いの{{
            tool.mediaName
          }}アカウントの情報と、そのアカウントに紐づくASPのプログラム情報をアドタスカルの管理画面へ登録します。{{
            tool.mediaName
          }}アカウントとASPのプログラムは何個でも登録可能です。
        </p>

        <p class="howto-step"><span>3</span>パラメータ引き継ぎ設定</p>
        <p class="txt_size">
          アフィリエイトリンクに{{
            tool.mediaName
          }}のパラメータを自動で付与し、そのパラメータをASP成果データに反映させるための設定を行います。
        </p>

        <p class="howto-step"><span>4</span>成果データの連携</p>
        <p class="txt_size">
          ASPの成果データを{{
            tool.mediaName
          }}の管理画面へアップロードします。完全自動化を実現する「自動コース」と、手動による「手動コース」があります。
        </p>
      </div>
      <p class="copy-txt pc">
        この設定をすれば、あとは日々自動で、
        <br />
        ASPの成果データが{{ tool.mediaName }}の管理画面に反映されます！
      </p>
      <p class="copy-txt sp">
        この設定をすれば、あとは日々自動で、ASPの成果データが{{
          tool.mediaName
        }}の管理画面に反映されます！
      </p>
    </div>

    <div v-if="tool.codeKebab !== 'tools'" class="bg_color">
      <div class="wrap">
        <p class="title title-pointStyle">
          アドタスカルを使うと、
          <br class="sp" />こんな未来が待っています
        </p>

        <div class="check-cover00">
          <ul
            class="check check-icon-li"
            :style="`list-style: url('${rt}/img/lp/check-mark.png');`"
          >
            <li class="check-list">CV乖離がなくなり、自動最適化がかかる</li>
            <li class="check-list">CV乖離がなくなり、正確な分析ができる</li>
            <li v-show="tool.codeKebab === 'google-cv'" class="check-list">
              コンバージョンキーワードが分かるようになる
            </li>
            <li class="check-list">ASPへタグの設置依頼が不要になる</li>
            <li class="check-list">タグが発火されない心配から開放される</li>
            <li class="check-list">
              これらの問題の解決や対応などにもう時間を使わない、お別れできる
            </li>
          </ul>
        </div>

        <div v-if="tool.codeKebab === 'google-cv'">
          <UiVoice :voiceIdList="['1', '2', '3', '4', '5', '6', '7', '8']" />
        </div>

        <div v-if="tool.codeKebab === 'yahoo-cv'">
          <UiVoice :voiceIdList="['1', '12', '13', '10', '11']" />
        </div>
      </div>
    </div>

    <div class="bg_color_blue">
      <div class="wrap">
        <p class="title">開発経緯と開発者の思い</p>
        <p class="txt_size point-space-padding">
          アドタスカルの開発は、アドアフィリエイトをする中で、Google社の担当者からオフラインコンバージョンについて教えてもらい、それを応用したことがきっかけでした。Google広告としても推奨している方法であり、それをツール化することで、今、アドアフィリエイターが抱えている問題を解決できるのではないかと考えました。
          <span
            v-if="tool.codeKebab === 'tools' || tool.codeKebab === 'yahoo-cv'"
            >そして、この仕組みをYahoo!検索広告・Microsoft広告にも展開しました。</span
          ><span v-if="tool.codeKebab === 'microsoft-cv'"
            >そして、この仕組みをMicrosoft広告・Yahoo!検索広告にも展開しました。</span
          >アドタスカルによって、多くのアドアフィリエイターを助けることも、ASPを助けることもできると自負しています。アドアフィリエイタ―目線での開発にこだわり、かゆいところに手が届くツールになっています。
        </p>
      </div>
    </div>

    <div class="wrap">
      <CvQA :type="'B'" />
    </div>

    <div class="bg_color">
      <div class="wrap" id="cost">
        <div class="CostPlan">
          <p class="title">料金プラン</p>
          <Cost :productId="productId" />
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="pc">
        <Lineup />
      </div>
      <div class="sp cooperation">
        <div class="medium-cover">
          <div class="medium" style="padding-bottom: 0">
            <p class="category-title">対応済の媒体</p>
            <div class="medium-logo-intend sp-img">
              <img
                :src="rt + '/img/lp/google-cv/google-logo-sp.jpg'"
                alt="google-cv"
              />
              <img
                :src="rt + '/img/lp/yahoo-cv/yahoo-logo-sp.jpg'"
                alt="yahooJapan"
              />
              <img
                :src="rt + '/img/lp/facebook-cv/fb-logo-sp.jpg'"
                alt="facebook"
              />

              <img
                :src="rt + '/img/tool12.png'"
                alt="microsoft"
                style="width: 140px!important"
              />
              <!-- <img
                :src="rt + '/img/lp/microsoft-cv/ms-logo-sp.jpg'"
                alt="microdoft"
              /> -->

              <!-- <img :src="rt + '/img/youtube.jpg'" alt="youtube" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="CostPlan2">
        <p class="title">
          使えば使うほど得する
          <br class="sp" />ボーナスポイントもあります！
        </p>
        <p class="txt_size">
          アドタスカルを使用すると、以下のボーナスポイントも付与されます。
        </p>
        <BonusPoint />
      </div>
    </div>

    <div id="register-and-settle"></div>
    <div class="bg_color">
      <div class="wrap">
        <div id="price"></div>
        <div class="Trial_plan">
          <p v-if="tool.codeKebab === 'tools'" class="title">
            まずは初回100円で
            <br class="sp" />アドタスカルをお試しください！
          </p>
          <p v-else class="title">
            まず30日間、100円で
            <br class="sp" />アドタスカルをお試しください！
          </p>
          <Trial :productId="productId" />
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="Privileges">
        <p class="title">さらに、特典もプレゼント中です</p>
        <div v-for="privilegeData in privilegeList" :key="privilegeData">
          <Privileges :privilegeData="privilegeData" />
        </div>
      </div>
    </div>

    <div class="wrap">
      <p class="title">会員情報の登録と決済</p>
      <div style="display: flex; justify-content: center">
        <div class="plan-step">
          <PlanStep :step="1" />
        </div>
      </div>
      <PlanTable :productId="productId" />
      <RegisterAndSettle :productId="productId" />
      <!-- <RegisterAndSettle v-if="$route.query.secret === 'VndDwNFvG9Lag9rx'" :productId="productId" />
      <p></p>
      <h2 style="text-align: center">現在、一時的に申し込みを停止しています</h2>
      <p>
        現在、アドタスカルの新春キャンペーンをTwitter上で行っています（※キャンペーンへの応募は締切となっています）。当キャンペーン中は、一時的に当サイトからの一般のお申し込みを停止しています。お申し込みをご希望の場合は、<router-link to="/contact-form">お問い合わせフォーム</router-link>から「契約希望」のご連絡をお願いいたします。キャンペーンは適用されない通常の契約をご案内いたします。
      </p>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Lineup from "@/components/ToolLineup.vue";
// import BusinessOwner from "@/components/BusinessOwner.vue";
// import Template01 from "@/components/Template01.vue";
// import Template012 from "@/components/Template012.vue";
// import Template02 from "@/components/Template02.vue";
import Cost from "@/components/Cost.vue";
// import CostQA from "@/components/CostQA.vue";
import CvQA from "@/components/CvQA.vue";
import Trial from "@/components/Trial.vue";
import Trial00 from "@/components/Trial00.vue";
// import About from "@/components/AboutAdtasukaru.vue";
import BonusPoint from "@/components/BonusPoint.vue";
import PlanTable from "@/components/PlanTable.vue";
import PlanStep from "@/components/PlanStep.vue";
import RegisterAndSettle from "@/components/RegisterAndSettle.vue";
import UiVoice from "@/components/Ui/Voice.vue";
// import GreatValue from "@/components/GreatValue.vue";
// import GoogleCvFunction from "@/components/GoogleCvFunction.vue";
// import Trial00 from "@/components/Trial00.vue";
import Privileges from "@/components/Privileges.vue";

export default {
  components: {
    Lineup,
    // BusinessOwner,
    // Template01,
    // Template02,
    Cost,
    // CostQA,
    CvQA,
    Trial,
    Trial00,
    // Template012,
    BonusPoint,
    PlanTable,
    PlanStep,
    RegisterAndSettle,
    // GreatValue,
    // GoogleCvFunction,
    // Trial00
    UiVoice,
    Privileges,
  },
  props: {
    toolCodeKebab: String,
    toolCodeCamel: String,
    productId: String,
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools", "apMinValue"]),
    productData() {
      return this.$store.getters.productData(this.productId);
    },
  },
  data() {
    return {
      privilegeList: [
        {
          no: 1,
          title: "Microsoft広告セミナー特典",
          text1: "アドタスカルの申し込みでもれなくプレゼント",
          text2:
            "株式会社Catch the Web主催の月刊ウェブマーケティングにて、Microsoft広告のリスティングセミナーを開催しました。\r\nそのときのセミナー動画（1時間27分）を、アドタスカルの申し込み特典として、全ユーザーにプレゼント。\r\nこれから伸びていくことが予測されるMicrosoft広告を実際に運用して検証した結果データを用いて開設した戦略的なノウハウです。",
          imagePath: "privileges01.png",
          imageAlt: "Microsoft広告セミナー特典",
        },
        {
          no: 2,
          title: "管理画面でROAS、利益を表示する方法",
          text1:
            "アドタスカルのいずれかの成果連携ツールの自動インポート成功でプレゼント",
          text2:
            "Google広告の表示項目「カスタム列」を使用することで、Google広告管理画面上でROAS、利益を表示する方法です。\r\n管理画面上で、ROASや利益を表示することで、業務効率を上げることができます。\r\nアドタスカルのいずれかの成果連携ツールに自動インポート成功でプレゼントとなります。",
          imagePath: "privileges02.png",
          imageAlt: "Google広告",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.MAIN_IMG {
  img {
    width: 100%;
  }
}
.wrap {
  margin-top: 80px;
  @media screen and (max-width: $max-width) {
    margin-top: 20px;
  }
}
.plan-step {
  width: 50%;
  @media screen and (max-width: $max-width) {
    width: 80%;
  }
}
:deep(.sp-max) {
  @media screen and (max-width: 767px) {
    width: 100%;
    // padding: 0 10px;
  }
}
.btn {
  margin-top: 30px;
  text-align: center;
}

.pointstyle {
  @media screen and (max-width: $max-width) {
    font-size: 26px;
    margin: 0 !important;
  }
}
.feature {
  display: flex;
  align-items: center;
  .feature_title {
    font-size: 28px;
    font-weight: bold;
    @media screen and (max-width: $max-width) {
      font-size: 24px;
    }
  }
  img {
    width: 50px;
    margin-right: 30px;
  }
}

.RespectPoint {
  padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 50px;
  }
  .title {
    @media screen and (max-width: $max-width) {
      margin-top: 40px;
    }
  }
}
.CostPlan {
  margin: 50px 0 100px;
  @media screen and (max-width: $max-width) {
    margin: 0 0 60px;
  }
  .title {
    margin-bottom: 0;
  }
}
.CostPlan2 {
  padding-bottom: 100px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 30px;
  }
}

.pin_style {
  color: #1c6db5;
}

.center {
  text-align: center;
}
.ResultAndEffect {
  padding-bottom: 20px;
  .title {
    @media screen and (max-width: $max-width) {
      margin-top: 0;
    }
  }
}
.Trial_plan {
  .title {
    @media screen and (max-width: $max-width) {
      font-size: 23px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .Trial {
    @media screen and (max-width: $max-width) {
      padding-bottom: 30px;
    }
  }
}

.check-cover {
  padding-bottom: 90px;
  .cv-tool-txt {
    font-size: 24px;
    text-align: center;
  }
}

.trial-cv-txt {
  text-align: center;
  margin-bottom: 80px;
  letter-spacing: 0.05em;
  @media screen and (max-width: $max-width) {
    margin-bottom: 40px;
    text-align: left;
  }
}
.cv-txt {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 80px;
  letter-spacing: 0.05em;
  // color: #1c6db5;
  @media screen and (max-width: $max-width) {
    font-size: 21px;
    margin-bottom: 30px;
    text-align: left;
  }
}

.cooperation {
  padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 20px;
  }
  .medium-cover {
    .medium {
      padding-bottom: 50px;
      @media screen and (max-width: $max-width) {
        padding-bottom: 20px;
      }
      .category-title {
        font-weight: bold;
        font-size: 20px;
        color: white;
        text-align: center;
        // border: 1px solid #ccc;
        // background: #e1ecf6;
        background: #1c6db5;
        margin-bottom: 0;
        letter-spacing: 0.05em;
        padding: 10px 0;
      }
      .medium-logo {
        background-color: white;
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;
        // align-items: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px 60px;
        border: 1px solid #ccc;
        border-top: none;
        @media screen and (max-width: $max-width) {
          padding: 30px 0;
        }
        img {
          margin: 15px 5px;
          width: 23%;
          @media screen and (max-width: $max-width) {
            box-sizing: border-box;
            padding: 5px;
            margin: 0;
            width: 50%;
          }
        }
        .cart-logo{
          width: 32%;
          @media screen and (max-width: $max-width) {
            box-sizing: border-box;
            padding: 5px;
            margin: 0;
            width: 50%;
          }
        }
      }
      .medium-logo-intend {
        // background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px 60px;
        border: 1px solid #ccc;
        border-top: none;
        @media screen and (max-width: $max-width) {
          padding: 20px 15px;
        }
        .medium-logo-intend-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: $max-width) {
          }
        }
        img {
          width: 80px;
          margin: 0 10px;
          image-rendering: -webkit-optimize-contrast;
          @media screen and (max-width: $max-width) {
            margin: 0;
            width: 18%;
          }
        }
        img.developing {
          @media screen and (max-width: $max-width) {
            width: 80px;
          }
        }
      }
    }
  }
}

.check-cover00 {
  display: flex;
  justify-content: center;
  .check {
    .check-list {
      font-size: 24px;
      margin-bottom: 15px;
      @media screen and (max-width: $max-width) {
        font-size: 20px;
        margin-bottom: 18px;
      }
    }
  }
}
.check-txt {
  background-color: #e1ecf6;
  padding: 30px 50px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 8px;
  letter-spacing: 0.05em;
  line-height: 1.8em;
  margin-bottom: 55px;
  @media screen and (max-width: $max-width) {
    padding: 20px;
    margin-bottom: 30px;
  }
}
.copy-txt {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  color: #1c6db5;
  margin-bottom: 100px;
  @media screen and (max-width: $max-width) {
    font-size: 25px;
    margin-bottom: 30px;
    margin-top: 0;
  }
}

.movie-space {
  margin-bottom: 80px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
  }
  iframe {
    width: 1100px;
    height: 650px;
    @media screen and (max-width: $max-width) {
      width: 100%;
      height: 200px;
    }
  }
}

.howto {
  padding-bottom: 60px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 10px;
  }
  .howto-title {
    font-size: 25px;
    font-weight: bold;
    border-left: 13px solid #1c6db5;
    border-bottom: 1px solid #1c6db5;
    padding-left: 15px;
    padding-bottom: 8px;
    margin-bottom: 50px;
  }
  .howto-step {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
    span {
      background-color: #1c6db5;
      color: white;
      font-size: 20px;
      border-radius: 8px 0 0 8px;
      padding: 10px 25px;
      margin-right: 25px;
      font-weight: normal;
      @media screen and (max-width: $max-width) {
        display: block;
        padding: 2px;
        margin-right: 0;
        text-align: center;
        width: 60px;
        border-radius: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.howto02 {
  padding-bottom: 60px;
  margin-top: 80px;
  @media screen and (max-width: $max-width) {
    margin-top: 30px;
    padding-bottom: 0;
  }
  .howto-title {
    font-size: 25px;
    font-weight: bold;
    border-left: 13px solid #1c6db5;
    border-bottom: 1px solid #1c6db5;
    padding-left: 15px;
    padding-bottom: 8px;
    margin-bottom: 50px;
  }
  .howto-step {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #1c6db5;
    padding: 5px 0;
    span {
      background-color: #1c6db5;
      color: white;
      font-size: 20px;
      border-radius: 8px 0 0 0;
      padding: 10px 25px;
      margin-right: 25px;
      font-weight: normal;
      @media screen and (max-width: $max-width) {
        display: block;
        padding: 2px;
        margin-right: 0;
        text-align: center;
        width: 60px;
        border-radius: 0;
        margin-bottom: 10px;
      }
    }
  }
  .txt_size {
    padding-bottom: 50px;
    background-color: #f2f2f2;
    margin-top: 0;
    margin-bottom: 50px;
    padding: 25px 20px 25px 90px;
    @media screen and (max-width: $max-width) {
      padding: 15px;
      margin-bottom: 30px;
    }
  }
}

.voice-cover {
  width: 900px;
  margin: 0 auto;
  margin-top: 60px;
  box-sizing: border-box;
  padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 5px;
  }
  .voice-img {
    text-align: right;
    @media screen and (max-width: $max-width) {
      text-align: center;
    }
    img {
      width: 300px;
    }
  }
  .voice {
    padding: 70px;
    background-color: white;
    margin-bottom: 60px;
    @media screen and (max-width: $max-width) {
      padding: 20px;
      padding-bottom: 50px;
    }
    .voice-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 15px 0;
      @media screen and (max-width: $max-width) {
        display: block;
      }
      p {
        margin: 0;
      }
      .voice-title-cover {
        display: flex;
        align-items: stretch;
        @media screen and (max-width: $max-width) {
          display: block;
        }
        .volume {
          color: white;
          font-weight: bold;
          font-size: 18px;
          background-color: #1c6db5;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          margin-right: 10px;
          @media screen and (max-width: $max-width) {
            width: 100%;
            padding: 5px 0;
          }
        }
        .voice-title {
          font-weight: bold;
          font-size: 25px;
          color: #1c6db5;
          @media screen and (max-width: $max-width) {
            font-size: 22px;
            text-align: left;
            padding: 10px 0;
          }
        }
      }
    }
    .name {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.05em;
    }
  }
}
.point-space-padding {
  @media screen and (max-width: $max-width) {
    padding-bottom: 0;
  }
}
.pointstyle {
  text-align: right;
  @media screen and (max-width: $max-width) {
    text-align: left;
    font-size: 14px;
  }
}
.small-txt {
  font-size: 13px;
}
.txt-pointstyle {
  font-size: 15px;
  letter-spacing: 0.08em;
  margin-top: 40px;
  @media screen and (max-width: $max-width) {
    padding: 0 20px;
  }
  a {
    border-bottom: 1px solid #1c6db5;
    padding-bottom: 2px;
    font-weight: bold;
    color: #1c6db5;
  }
}
.kara {
  width: 176px;
}
.title-pointStyle {
  margin-top: 0;
  margin-bottom: 25px;
}
.check-icon-li {
  li {
    // list-style: url();
    img {
      width: 35px;
      margin-right: 5px;
      @media screen and (max-width: $max-width) {
        width: 28px;
      }
    }
  }
}
:deep(.twitter-tweet) {
  margin: 0 auto;
}

:deep(.sp) {
  table {
    width: 100%;
    border-collapse: collapse;
    color: #4d4d4d;
    & + table {
      margin-top: 10px;
    }
    tr {
      th {
        width: 550px;
        border: 1px solid #ccc;
        padding: 13px 10px;
        font-size: 18px;
        background-color: #e1ecf6;
        @media screen and (max-width: $max-width) {
          padding: 10px 8px;
          width: 55%;
          font-size: 17px;
        }
        span {
          font-size: 15px;
          @media screen and (max-width: $max-width) {
            font-size: 13px;
          }
        }
      }
      td {
        border: 1px solid #ccc;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        background-color: white;
        @media screen and (max-width: $max-width) {
          padding: 10px;
          font-size: 16px;
        }
        span {
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }
    .title {
      background-color: #1c6db5;
      color: white;
      font-size: 20px;
      font-weight: normal;
      padding: 5px;
      border: 1px solid #1c6db5;
      border-right: 1px solid #ccc;
      @media screen and (max-width: $max-width) {
        font-size: 18px;
      }
    }
  }
}
.sp-img {
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    img {
      width: 18% !important;
    }
  }
}
</style>
