<template>
  <div>
    <header>
      <div class="left">
        <router-link to="/" style="display: flex; align-items: center">
          <img
            class="lp_logo"
            :src="`${rt}/img/lp/logo.png`"
            alt="アドタスカル"
          />
        </router-link>
      </div>
      <template
        v-if="
          affiliaterData.givesPrivilege === false &&
          privilegeData.isProviderNameDisplay === false
        "
      >
        <!-- 非表示 -->
      </template>
      <template v-else>
        <div v-if="affiliaterData.userId !== '0'" class="privilege-alert">
          <p
            class="
              animate__animated animate__flash animate__slower animate__infinite
            "
          >
            <span v-if="affiliaterData.givesPrivilege === true">
              {{ privilegeData.displayProviderName }}様の<br
                class="sp"
              />特典付きの申し込みが<span v-if="$route.name === 'complete'"
                >完了しました</span
              ><span v-else>できます</span>
            </span>
            <span v-else>
              {{ privilegeData.displayProviderName }}様からの<br
                class="sp"
              />ご紹介でのお申し込み<span v-if="$route.name === 'complete'"
                >が完了しました</span
              ><span v-else>となります</span>
            </span>
          </p>
        </div>
      </template>
      <div v-if="displayOnetimeOffer === false" class="right">
        <a
          v-scroll-to="'#register-and-settle'"
          href="#register-and-settle"
          class="link-btn st-reflection"
          >初回100円お試し実施中</a
        >
        <img
          @click="drawer = true"
          class="hamburger"
          :src="`${rt}/img/lp/drawer-button.jpg`"
          alt="ハンバーガーメニュー"
        />
      </div>
    </header>
    <el-drawer
      class="close"
      title="閉じる"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div class="drawer-list">
        <div @click="jump('/')" class="item">トップ</div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/google-cv')" class="item">
          Google広告成果連携ツール
        </div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/yahoo-cv')" class="item">
          Yahoo!検索広告成果連携ツール
        </div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/yda-cv')" class="item">
          YDA成果連携ツール
        </div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/microsoft-cv')" class="item">
          Microsoft広告成果連携ツール
        </div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/kiyaku')" class="item">利用規約</div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/tokuteisho')" class="item">
          特定商取引法に基づく表記
        </div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/privacy-policy')" class="item">プライバシーポリシー</div>
      </div>
      <div class="drawer-list">
        <div @click="jump('/contact-form')" class="item">お問い合わせ</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      loggedIn: 0,
      drawer: false,
      direction: "rtl",
      displayOnetimeOffer: true,
    };
  },
  computed: {
    ...mapGetters(["rt", "affiliaterData", "tool", "privilegeData"]),
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === "complete") {
        this.getThanksData(newRoute.params.temporaryRegisterToken);
      } else {
        this.displayOnetimeOffer = false;
      }
    },
  },
  methods: {
    register() {
      location.href = "/register/";
    },

    login() {
      location.href = "/login/";
    },

    info() {
      location.href = "/account/";
    },

    contact(val) {
      location.href = "/contact/" + val;
    },

    logout() {
      this.$axios
        .get(`${this.rt}/api/logout.php`)
        .then((res) => {
          console.log(res);
          this.loggedIn = 0;
          location.href = "/logout/";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose(done) {
      done();
    },

    jump(path) {
      this.drawer = false;
      this.$router.push(path);
    },

    getThanksData(temporaryRegisterToken) {
      this.$axios
        .get(
          `${this.rt}/l/api/register/get-thanks-data?temporary_register_token=${temporaryRegisterToken}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.displayOnetimeOffer = response.data.displayOnetimeOffer;
          } else {
            this.$router.push("/");
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
header {
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 70px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  @media screen and (max-width: $max-width) {
    height: 50px;
  }
  .left {
    display: flex;
    align-items: center;
    p {
      // margin-left: 100px;
      margin-left: 50px;
      font-size: 18px;
      color: #4d4d4d;
      @media screen and (max-width: $max-width) {
        display: none;
      }
    }
  }
  .privilege-alert {
    @media screen and (max-width: $max-width) {
      position: fixed !important;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: #fff;
      opacity: 0.8;
    }
    p {
      font-size: 24px;
      color: #f15e62;
      @media screen and (max-width: $max-width) {
        font-size: 16px;
        text-align: center;
        margin: 5px 0;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    img {
      margin: 0 10px;
      cursor: pointer;
    }
    p {
      font-size: 20px;
      color: #4d4d4d;
      font-weight: bold;
      margin-right: 50px;
      @media screen and (max-width: $max-width) {
        display: none;
      }
    }
  }
  .lp_logo {
    width: 255px;
    @media screen and (max-width: $max-width) {
      width: 180px;
    }
    @media screen and (max-width: 320px) {
      width: 140px;
    }
  }
  .hamburger {
    width: 53px;
    border: solid 2px #ddd;
    @media screen and (max-width: $max-width) {
      display: none;
    }
  }
}
:deep(.el-drawer) {
  outline: none;
  width: 16% !important;
  text-align: right;
  font-size: 14px;
  @media screen and (max-width: $max-width) {
    width: 65% !important;
  }
  header {
    span {
      outline: none;
    }
  }
  .drawer-list {
    font-size: 18px;
    padding: 10px 20px;
    border-top: 1px solid #ccc;
    text-align: left;
    padding-left: 10px;
    &:last-child {
      border-bottom: 1px solid #ccc;
    }
    a,
    .item {
      color: #4d4d4d !important;
      border-left: 3px solid #1c6db5;
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
.contact_btn {
  border: 1px solid #ccc;
  border-radius: 50px;
  font-size: 20px;
  color: #4d4d4d;
  font-weight: bold;
  padding: 10px 40px;
  margin-right: 30px;
}

.sub-txt {
  margin-left: 35px !important;
  font-size: 14px !important;
}

.link-btn {
  width: 100%;
  font-size: 16px;
  padding: 10px 30px;
  margin-right: 15px;
  @media screen and (max-width: $max-width) {
    font-size: 12px;
    padding: 7px 10px;
    margin-right: 0;
  }
}
</style>