<template>
  <div class="ContactFormCheck">
    <div class="wrap">
      <p class="title">確認画面</p>
      <table class="form">
        <tr>
          <th>企業名・屋号名</th>
        </tr>
        <tr>
          <td>{{data.company}}</td>
        </tr>
        <tr>
          <th>担当者名</th>
        </tr>
        <tr>
          <td class="name_box">{{data.name_sei +" "+ data.name_mei}}</td>
        </tr>
        <tr>
          <th>返信用メールアドレス</th>
        </tr>
        <tr>
          <td>{{data.email}}</td>
        </tr>
        <tr>
          <th>お問い合わせ区分</th>
        </tr>
        <tr>
          <td>{{data.kubun}}</td>
        </tr>
        <tr>
          <th>お問い合わせ内容詳細</th>
        </tr>
        <tr>
          <td><span v-html="comment_view"></span></td>
        </tr>
      </table>

      <div class="button_cover">
        <button @click="send">送信</button>
        <br>
        <br>
        <button @click="ret">修正する</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";

export default {
  name: "ContactFormCheck",
  props: {},
  data() {
    return {
      rt: store.state.rt,
      data: {
        company: "",
        name_sei: "",
        name_mei: "",
        email: "",
        kubun: "Google広告成果連携ツール",
        comment: "",
      },
      comment_view: "",
    };
  },
  components: {
  },
  created: function () {
    this.data = JSON.parse(sessionStorage.getItem("ConfirmData"));

    this.comment_view = this.data.comment.replace(/\n/g, "<br/>");
  },
  mounted:function(){
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  },
  methods: {
    ret:function(){
      this.$router.push({name:'contact-form'});
    },
    send: function () {
      console.log("send来た");
      console.log(JSON.stringify(this.data));
      var fd = new FormData();
      fd.append("data", JSON.stringify(this.data));
      this.$axios
        .post(this.rt + "/api/lp/Contact/sendContact.php", fd)
        .then((response) => {
          console.log(response);
          if(response.data.mess=="OK"){
            this.$router.push({name:'contact-form-complete'});
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.MAIN_IMG {
  img {
    width: 100%;
  }
}
.title {
  // margin-top: 100px;
  margin-block-start: 0;
  padding-top: 20px;
  font-size: 25px;
  margin-bottom: 50px;
  letter-spacing: 0.1em;
  margin-top: 40px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    // margin-top: 80px;
  }
}
.form {
  width: 100%;
  text-align: left;
  border: 1px solid #ccc;
  border-collapse: collapse;
  th {
    background-color: #f2f2f2;
    font-size: 18px;
    font-weight: normal;
    padding: 10px 25px;
    box-sizing: border-box;
    letter-spacing: 0.05em;
  }
  td {
    padding: 25px;
    border-bottom: 1px solid #ccc;
    input {
      width: 60%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px 15px;
      @media screen and (max-width: $max-width) {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}
.button_cover {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  button {
    cursor: pointer;
    background-color: #1c6db5;
    border: none;
    color: white;
    font-size: 20px;
    letter-spacing: 0.5em;
    width: 200px;
    height: 55px;
    border-radius: 50px;
    padding-left: 15px;
  }
  a:hover {
    opacity: 0.8;
  }
}
</style>
