<template>
    <div class="yda-cv">
      <Tools02Contents
        :productId="mainProductId"
      />
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import Tools02Contents from "@/components/Tools02/Contents.vue";
  
  export default {
    name: 'YdaCv',
    components: {
      Tools02Contents
    },
    props: {},
    data() {
      return {};
    },
    computed: {
      ...mapGetters(["rt", "tools", "mainProductId"]),
    },
    methods: {},
  };
  </script>
  
  
  
  
  
  
  <style lang="scss" scoped>
  </style>
  