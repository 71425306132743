<template>
  <div class="BusinessOwner">
    <div class="bg_color">
      <div class="wrap">
        <p class="title pc">こんな事業主様が利用しています</p>
        <p class="title sp">
          こんな事業主様が
          <br />利用しています
        </p>
        <div class="business_owner_cover">
          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/ppc_illust.png'" alt="PPCアフィリエイター" />
            </div>
            <p>アドアフィリエイター</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/company_illust.png'" alt="記事広告の運用代理店" />
            </div>
            <p>広告の運用代理店</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/graph_illust.png'" alt="企業の広告運用担当者" />
            </div>
            <p>SEOアフィリエイター</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/webstaff_illust.png'" alt="企業の広告運用担当者" />
            </div>
            <p>ウェブ制作会社</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/pc_illust.png'" alt="PPCアフィリエイター" />
            </div>
            <p>メディア運営者</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/stuff2_illust.png'" alt="記事広告の運用代理店" />
            </div>
            <p>企業の広告運用担当者</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/bloger_illust.png'" alt="企業の広告運用担当者" />
            </div>
            <p>ブロガー</p>
          </div>

          <div class="business_owner">
            <div class="owner_cover">
              <img :src="rt+'/img/lp/stuff_illust.png'" alt="企業の広告運用担当者" />
            </div>
            <p>企業のウェブ担当者</p>
          </div>
        </div>

      
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "BusinessOwner",
  props: {},
  data() {
    return {
      rt: store.state.rt
    };
  }
};
</script>

<style lang="scss" scoped>

.business_owner_cover {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  @media screen and (max-width: $max-width) {
    justify-content: space-around;
  }
  .business_owner {
    text-align: center;
    .owner_cover {
      img {
        width: 240px;
        border-radius: 50%;
      @media screen and (max-width: $max-width) {
          width: 140px;
        }
      }
    }
    p {
      font-weight: bold;
      color: #4d4d4d;
      font-size: 18px;
      letter-spacing: 0.05em;
      @media screen and (max-width: $max-width) {
        margin-bottom: 40px;
        font-size: 14px;
  }
    }
  }
  .business_owner:nth-child(n+5) {
    margin-top: 50px;
    @media screen and (max-width: $max-width) {
    margin-top: 0;
  }
}
}

</style>