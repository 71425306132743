<template>
  <div class="payment-onetime-offer-product">
    <div v-if="type === 'onetimeOfferComplete'">
      <h1 class="top-space">
        ポイントのまとめ買い、<br class="sp" />ありがとうございました。
      </h1>
      <p>
        ポイントのまとめ買いについて、ご登録のメールアドレスに購入完了の旨の通知をいたしました。
      </p>
    </div>
    <div>
      <h1 class="top-space">
        会員登録完了と<br class="sp" />ログイン情報の発行
      </h1>
      <div v-if="type === 'default'">
        <p>
          初回100円お試しのお申し込みが完了しました。ご登録のメールアドレスに、アドタスカル管理画面のログイン情報と、決済完了のお知らせをお送りいたしました。
        </p>
        <p>アドタスカル管理画面にログインし、利用を開始してください。</p>
        <p>
          なお、アドタスカルからメールが届いていない場合は、迷惑メールボックスの中身をご確認ください。
        </p>
      </div>
      <div v-else-if="type === 'onetimeOfferComplete'">
        <p>
          ご登録のメールアドレスに、アドタスカル管理画面のログイン情報と、決済完了のお知らせをお送りいたしました。
        </p>
      </div>
    </div>
    <div>
      <h1 class="top-space">
        お試しのポイントの<br class="sp" />有効期限は3ヶ月です
      </h1>
      <p>
        通常時のポイント<span v-show="type === 'onetimeOfferComplete'"
          >、および、まとめ買い時のポイント</span
        >の有効期限は、購入後1年間ですが、お試しのみ3ヶ月間となります。
      </p>
      <p>そのため、今すぐにアドタスカルのご利用開始をおすすめいたします。</p>
    </div>
    <div>
      <h1 class="top-space">アドタスカル連絡チャットへの登録をお願いします</h1>
      <p>
        アドタスカルの更新やメンテナンスに関する全体連絡は、チャットワークグループでも行っております。
      </p>
      <p>
        以下のURLからチャットワークグループへの参加、および、関連メンバーの招待をお願いいたします。
      </p>

      <p style="margin-bottom: 0">▼アドタスカル連絡チャット</p>
      <a
        href="https://www.chatwork.com/g/hfko5vyiwklxw2"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.chatwork.com/g/hfko5vyiwklxw2</a
      >
      <p>
        ※プライバシーも考慮して、他の参加者様に、ご自身が参加していることは分からない仕様になっております。
      </p>
    </div>
    <div class="red-bold" style="margin: 100px 0">
      <p>ここまでの内容についてご確認ありがとうございました。</p>
      <br class="sp" />
      <p>ご確認後、<br class="sp" />当ページをお閉じください。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.top-space {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
</style>
