<template>
  <div class="kiyaku-cover">
    <p class="kiyaku-big-title">利用規約</p>
    <div class="txt">
      <p>
        株式会社グレイトヘルプ（以下、「当社」といいます。）の本サービスを利用する前に、本利用規約をお読みいただいた上で、本利用規約に同意いただく必要があります。
      </p>
      <div class="block">
        <p class="kiyaku-title">第１条（適用）</p>
        <p>
          <span class="number">1.</span
          >本利用規約は、本サービスの提供条件および本サービスの利用に関する当社とお客様（利用登録の有無を問いません。以下同じ。）との間の権利義務関係を定めることを目的とし、当社とお客様との間の本サービスの利用に関わる一切の関係に適用されます。
        </p>
        <p>
          <span class="number">2.</span
          >本利用規約とは別途当社が定めた他の規程は本利用規約の一部を構成しますが、本利用規約の内容と、他の規程における本サービスの説明等とが異なる場合、本利用規約が優先して適用されるものとします。
        </p>
      </div>
      <div class="block">
        <p class="kiyaku-title">第２条（当社について）</p>
        <p>
          当社は、ウェブ制作およびシステム開発の事業を行っており、インターネット広告事業者に向け、インターネット広告で有用とされる広告機能を提供する本サービスを運営しています。
        </p>
      </div>
      <div class="block">
        <p class="kiyaku-title">第３条（申込み）</p>
        <p>
          <span class="number">1.</span
          >本サービスの利用を申込む者（以下、「申込者」といいます。）は、本利用規約を遵守することに同意し、かつ当社所定の情報（以下、「登録事項」といいます。）を当社に提供することにより、本サービスの利用登録の申込みを行うものとします。
        </p>
        <p>
          <span class="number">2.</span
          >決済の完了をもって登録が完了し、本サービス利用契約が申込者と当社の間に成立します（以下、登録がなされた申込者を「契約者」といいます。）。
        </p>
        <p>
          <span class="number">3.</span
          >当社は、申込者が以下の各号のいずれかの事由に該当すると判断した場合には、登録を拒否することができます。その場合でも、当社は上記判断にかかる理由について一切開示する義務を負いません。
        </p>

        <div class="small-block">
          <p>
            <span class="small-number">(１)</span
            >当社に提供した登録事項の全部または一部につき、虚偽、誤記または記載漏れがあった場合
          </p>
          <p>
            <span class="small-number">(２)</span
            >過去に当社との契約に違反した者またはその関係者であると当社が判断した場合
          </p>
          <p>
            <span class="small-number">(３)</span
            >法人の代表権を有する者の同意を得ていなかった場合
          </p>
          <p>
            <span class="small-number">(４)</span
            >申込者が反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力その他これに準ずる者。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等、反社会的勢力等と何らかの関与を行っていると当社が判断した場合
          </p>
          <p>
            <span class="small-number">(５)</span
            >その他、当社が登録を適当でないと判断した場合
          </p>
        </div>
      </div>

      <div class="block">
        <p class="kiyaku-title">第４条（登録事項の変更）</p>
        <p>
          契約者は、登録事項に変更があった場合、当社の定める方法により遅滞なく登録事項の変更手続を行うものとします。
        </p>
      </div>
      <div class="block">
        <p class="kiyaku-title">第５条（IDおよびパスワードの管理）</p>
        <p>
          <span class="number">1.</span
          >当社は、契約者の本サービス利用開始日までに、本サービスに関するログインIDおよびログインパスワードを発行し、契約者に通知します。
        </p>
        <p>
          <span class="number">2.</span
          >契約者は、自己の責任において、本サービスに関するログインIDおよびログインパスワードを適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買、担保提供等をしてはならないものとします。
        </p>
        <p>
          <span class="number">3.</span
          >ログインIDまたはログインパスワードの管理不十分、使用上の過誤、第三者の使用等によって契約者に生じた損害については、当社は一切責任を負わないものとします。
        </p>
      </div>
      <div class="block">
        <p class="kiyaku-title">第６条（料金および支払方法）</p>
        <p>
          <span class="number">1.</span
          >契約者は、当社に対し、本サービスの利用の対価として、別途当社が定める利用料金を、当社が指定する支払方法により、当社の定める支払期日までに支払うものとします。なお、銀行振込手数料その他支払いに要する諸費用は、契約者の負担とします。
        </p>
        <p>
          <span class="number">2.</span
          >本利用登録の有効期間中に契約者が本サービスの提供を受けられなくなった場合、または受ける必要がなくなった場合であっても、その理由の如何を問わず、当社は利用料金の返還を行わないこととします。
        </p>
        <p>
          <span class="number">3.</span
          >契約者が利用料金の支払いを遅滞した場合、契約者は年１４．６％の割合による遅延損害金を当社に支払うものとします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第７条（禁止事項）</p>
        <p>
          契約者は、本サービスの利用にあたり、以下の各号のいずれかに該当し、または該当すると当社が判断する行為をしてはなりません。
        </p>

        <div class="small-block">
          <p><span class="small-number">(１)</span>法令に違反する行為</p>
          <p>
            <span class="small-number">(２)</span
            >当社、本サービスの他の利用者その他の第三者に対する詐欺または脅迫行為等を含む、犯罪に関連する行為
          </p>
          <p><span class="small-number">(３)</span>公序良俗に反する行為</p>
          <p>
            <span class="small-number">(４)</span
            >当社、本サービスの利用者その他の第三者の知的財産権等、肖像権、プライバシー権、名誉権その他の権利または利益を侵害する行為
          </p>
          <p>
            <span class="small-number">(５)</span
            >本サービスのネットワークまたはシステム等に過度な負荷をかける行為
          </p>
          <p>
            <span class="small-number">(６)</span
            >当社が提供する本サービスその他のシステムに対するリバースエンジニアリングその他の解析行為
          </p>
          <p>
            <span class="small-number">(７)</span
            >本サービスの運営・維持を妨げる行為
          </p>
          <p>
            <span class="small-number">(８)</span
            >当社のネットワークまたはシステム等への不正アクセス
          </p>
          <p><span class="small-number">(９)</span>第三者になりすます行為</p>
          <p>
            <span class="small-number">(10)</span
            >本サービスにより利用し得る情報を改ざんする行為
          </p>
          <p>
            <span class="small-number">(11)</span>反社会的勢力等への利益供与
          </p>
          <p>
            <span class="small-number">(12)</span
            >前各号の行為を直接または間接に惹起し、または容易にする行為
          </p>
          <p>
            <span class="small-number">(13)</span
            >その他、当社が不適切と判断する行為
          </p>
        </div>
      </div>

      <div class="block">
        <p class="kiyaku-title">第８条（個人情報保護の責任）</p>
        <p>本サービス利用に際し、サイト訪問者からの個人情報、Cookie情報等の個人関連情報、その他の情報の取得に関し、個人情報の保護に関する法律及び関連する法令・ガイドライン等が規定する利用目的の通知、公表、情報提供者からの同意取得、その他必要な措置は、広告主、お客様その他本サービスを利用する方において行うものとします。</p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第９条（権利帰属）</p>
        <p>
          <span class="number">1.</span
          >当社ウェブサイトおよび本サービスに関する知的財産権等は、すべて当社に帰属します。
        </p>
        <p>
          <span class="number">2.</span
          >本利用規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社の知的財産権等に関し、いかなる権利も付与するものではありません。
        </p>
        <p>
          <span class="number">3.</span
          >契約者は、投稿する文言について、自らが投稿その他送信することについての適法な権利を有していること、および投稿する文言が第三者の権利を侵害していないことについて、当社に表明し、保証するものとします。
        </p>
        <p>
          <span class="number">4.</span
          >契約者は、本サービスに対して、リバースエンジニアリング、逆コンパイル、または逆アセンブラ、その他本サービスを解析しようと試みてはならないものとします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１０条（契約解除等）</p>
        <p>
          <span class="number">1.</span
          >当社は、契約者が以下の各号のいずれかの事由に該当すると当社が判断した場合には、事前に通知することなく、本サービスの全部または一部の利用を停止し、または本契約を解除することができます。
        </p>

        <div class="small-block">
          <p>
            <span class="small-number">(１)</span
            >本契約のいずれかの条項に違反した場合
          </p>
          <p>
            <span class="small-number">(２)</span
            >第３条第３項各号に該当することが判明した場合
          </p>
          <p>
            <span class="small-number">(３)</span
            >支払停止若しくは支払不能となり、又は、破産、民事再生手続開始、会社更生手続開始、特別清算手続開始若しくはこれらに類する手続きの開始の申立てがあった場合
          </p>
          <p>
            <span class="small-number">(４)</span
            >当社からの問い合わせその他回答を求める連絡に対して３０日間以上応答がない場合
          </p>
          <p>
            <span class="small-number">(５)</span
            >その他、当社が本サービスの利用を適当でないと判断した場合
          </p>
        </div>

        <p>
          <span class="number">2.</span
          >前項各号のいずれかの事由に該当した場合、契約者は、当社に対して負っている債務の一切について等然に期限の利益を失い、直ちに当社に対して全ての債務の履行をしなければなりません。
        </p>
        <p>
          <span class="number">3.</span
          >契約者が本条第１項に基づく本サービスの利用停止の措置を受けている場合であっても、本契約が継続している限り、契約者は利用料金を支払う義務を負うものとします。
        </p>
        <p>
          <span class="number">4.</span
          >当社は、本条に基づく当社の行為によって契約者に生じた損害について、一切の責任を負いません。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１１条（解約）</p>
        <p>
          <span class="number">1.</span
          >契約者は、当社所定の手続の完了により、いつでも本契約を解除することができます。
        </p>
        <p>
          <span class="number">2.</span
          >解除に際し、当社に対して負っている債務が残っている場合には、契約者は、その一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の履行をしなければなりません。
        </p>
        <p>
          <span class="number">3.</span
          >当社と契約者との間の本契約が解除された場合であっても、契約者は、既払金の返金を求めることはできません。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１２条（本サービスの内容の変更、中断、終了）</p>
        <p>
          <span class="number">1.</span
          >当社は、当社の都合により、いつでも本サービスの内容を変更し、または提供を中断、終了することができます。
        </p>
        <p>
          <span class="number">2.</span
          >当社は、前項記載の本サービスの内容の変更または提供の中断を、予告なく行うことができます。
        </p>
        <p>
          <span class="number">3.</span
          >当社が本サービスの提供を終了するにあたっては、当社は契約者に対し、相当期間をもって事前に予告するものとします。ただし、緊急やむを得ない場合には、この限りではありません。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１３条（保証の否認及び免責）</p>
        <p>
          <span class="number">1.</span
          >当社は、本サービスが契約者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性・改善効果を有すること、本サービスが継続的に利用できること、および不具合が生じないことについて、明示または黙示を問わず、何ら保証するものではありません。
        </p>
        <p>
          <span class="number">2.</span
          >当社は、本サービスの提供に関連して、契約者が被った損害について、一切の賠償責任を負いません。
        </p>
        <p>
          <span class="number">3.</span
          >当社が契約者に対して、消費者契約法の適用その他の理由により、当社が契約者に対して損害賠償責任を負う場合においても、賠償すべき損害の範囲は、契約者に現実に発生した通常の損害（付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害は含みません。）に限るものとし、賠償すべき損害の額は、当該損害発生時までに契約者が当社に現実に支払った利用料金の直近１ヶ月間の総額を限度とします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１４条（本サービスの中断及び停止）</p>
        <p>
          当社は、Amazon社が提供するAmazon Web
          Servicesを使用して、安定したサービス提供に最善を尽くしていますが、以下の各号のいずれかに該当する場合には、利用者に事前に通知することなく一時的に本サービスを中断する場合があります。<br /><br />
          また、当社は以下の各号のいずれかに該当する事由により本サービスの提供の遅延または中断が発生したとしても、これに起因する利用者または他の第三者が被った損害について一切の責任を負いません。<br /><br />
          但し、当社は、当社の判断でサービス提供中断期間に応じた特典をユーザーに提供する場合があります。
        </p>
        <div class="small-block">
          <p class="small-title">
            <span class="small-number">(１)</span
            >サーバ、通信回線もしくはその他の設備の故障、障害の発生またはその他の事由により本サービスの提供ができなくなった場合
          </p>
          <p class="small-title">
            <span class="small-number">(２)</span
            >システム(サーバ、通信回線や電源、それらを収容する建築物などを含む)の保守、点検、修理、変更を定期的にまたは緊急に行う場合
          </p>
          <p class="small-title">
            <span class="small-number">(３)</span
            >火災、停電などにより本サービスの提供ができなくなった場合
          </p>
          <p class="small-title">
            <span class="small-number">(４)</span
            >地震、噴火、洪水、津波などの天災により本サービスの提供ができなくなった場合
          </p>
          <p class="small-title">
            <span class="small-number">(５)</span
            >戦争、変乱、暴動、騒乱、労働争議等その他不測の事態により本サービスの提供ができなくなった場合
          </p>
          <p class="small-title">
            <span class="small-number">(６)</span
            >法令による規制、司法命令等が適用された場合
          </p>
          <p class="small-title">
            <span class="small-number">(７)</span
            >その他、運用上、技術上当社が本サービスの一時的な中断を必要と判断した場合
          </p>
        </div>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１５条（契約者の損害賠償責任等）</p>
        <p>
          <span class="number">1.</span
          >契約者は、本サービスの利用に関連して当社に損害を与えた場合、当社に対し、その損害を賠償するものとします。
        </p>
        <p>
          <span class="number">2.</span
          >契約者が、本サービスに関連して顧客を含む第三者からクレームを受け、または顧客を含む第三者との間で紛争が生じた場合、契約者は、直ちにその内容を当社に通知するものとします。当該クレームまたは紛争については、契約者の費用と責任において処理することとし、その進捗および結果を当社に報告するものとします。
        </p>
        <p>
          <span class="number">3.</span
          >前項の場合において、クレームの処理または紛争の解決のために当社が支払いを余儀なくされた費用（弁護士費用を含みますがこれに限られません。）その他の損害がある場合には、契約者は、当社に対し、その損害を賠償するものとします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１６条（秘密保持）</p>
        <p>
          <span class="number">1.</span
          >契約者は、本サービスに関連して当社が契約者に対して秘密として取り扱うことを明示的に求めた非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密として取り扱うものとします。
        </p>
        <p>
          <span class="number">2.</span
          >当社は、本サービスの提供の過程でサービスの提供に必要な範囲で契約者のサイト情報等を取得することがあります。当社は、本サービスの提供の過程で取得した契約者の情報を秘密として取り扱い、本サービスの提供に関連する目的のみに利用します。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１７条（契約者の登録情報の取扱い）</p>
        <p>
          当社は、契約者の登録情報を、通常要求される合理的な方法で適切に取り扱うものとします。契約者は、当社が当該方法に従って契約者の登録情報を取り扱うことについて同意するものとします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１８条（本利用規約の変更）</p>
        <p>
          <span class="number">1.</span
          >当社は、当社が必要と認めた場合には、本利用規約を変更できるものとします。
        </p>
        <p>
          <span class="number">2.</span
          >当社は、本利用規約を変更する場合、変更後の利用規約の効力発生日および内容を、当社ウェブサイト上または本サービス上での掲示その他適切な方法により、効力発生日の相当期間前に契約者に周知し、または通知します。ただし、やむを得ず事前の周知または通知ができない場合には、周知または通知が可能となった後、速やかに行うこととします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第１９条（連絡、通知）</p>
        <p>
          <span class="number">1.</span
          >本サービスに関する問い合わせその他契約者から当社に対する連絡は、当社所定の問い合わせ窓口宛てに、電子メールを送信する方法で行うものとします。
        </p>
        <p>
          <span class="number">2.</span
          >当社から契約者への連絡または通知は、書面の送付、電子メールの送信その他の当社が適当と判断する方法によって行うものとします。
        </p>
        <p>
          <span class="number">3.</span
          >前項の連絡または通知は、契約者が書面を受領したとき、または電子メールが当社から発信されたときに、契約者に受領されたものとみなします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第２０条（契約上の地位の譲渡等）</p>
        <p>
          <span class="number">1.</span
          >契約者は、当社の書面による事前の承諾なく、本契約上の地位または本利用規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。
        </p>
        <p>
          <span class="number">2.</span
          >当社は、本サービスに係る事業を第三者に譲渡（通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。）した場合には、当該事業譲渡に伴い、本契約上の地位又は本利用規約に基づく権利もしくは義務並びに登録事項、送信情報、登録情報、その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、契約者は、かかる譲渡に予め同意します。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第２１条（完全合意）</p>
        <p>
          本利用規約は、本契約に係る当事者間の完全な合意を構成し、本契約の締結以前に当事者間でなされた本契約に関連する書面、口頭、その他いかなる方法による合意も、本利用規約に取って代わられます。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第２２条（分離可能性）</p>
        <p>
          本利用規約のいずれかの条項またはその一部が、関係する法令等により違法、無効または執行不能であると判断された場合においても、当該条項の残りの部分および本利用規約の他の条項は有効に存続し、完全に効力を有するものとします。違法、無効または執行不能と判断された条項またはその条項の一部については、当該条項または当該部分の趣旨に最も近い有効な規定に置き換えて適用するものとします。
        </p>
      </div>

      <div class="block">
        <p class="kiyaku-title">第２３条（準拠法および管轄裁判所）</p>
        <p>
          <span class="number">1.</span
          >本利用規約および本契約の準拠法は、日本法とします。
        </p>
        <p>
          <span class="number">2.</span
          >本利用規約または本契約に起因し、または関連して契約者と当社との間で生じた一切の紛争については、名古屋地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "kiyaku",
  title: "利用規約",
  components: {},
  data() {
    return {
      tool: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  methods: {},
};
</script>






<style lang="scss" scoped>
.kiyaku-cover {
  width: 1100px;
  margin: 70px auto 120px;
  @media screen and (max-width: $max-width) {
    width: 100%;
    margin: 50px auto 80px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  p {
    line-height: 1.8em;
  }
}
.kiyaku-big-title {
  text-align: center;
  font-size: 28px;
}
.txt {
  margin: 40px 0;
}
.block {
  margin: 50px 0;
  .kiyaku-title {
    font-size: 18px;
    font-weight: bold;
    margin-block-end: 0;
    // color: #1c6db5;
  }
  .number {
    // font-size: 18px;
    // font-weight: bold;
    // color: #1c6db5;
    margin-right: 5px;
  }
  p {
    margin-block-end: 0;
    margin-block-start: 0;
  }
  .small-block {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0 20px;
  }
}
</style>
