<template>
  <div class="complete2">
    <div class="wrap">
      <Notification :type="'onetimeOfferComplete'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Notification from "@/components/Complete/Notification.vue";

export default {
  components: {
    Notification,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
