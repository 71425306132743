var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tokuteisho"},[_c('div',{staticClass:"wrap"},[_c('h1',[_vm._v("特定商取引法に基づく表記")]),_c('table',[_c('tbody',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('tr',[_c('th',[_vm._v("販売価格帯")]),_c('td',[_vm._v(_vm._s(_vm._f("localeNum")(_vm.productData.price2))+"円～18,000円（税別）")])]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("販売会社名")]),_c('td',[_vm._v("株式会社グレイトヘルプ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("運営総括責任者")]),_c('td',[_vm._v("小関貴久")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("所在地")]),_c('td',[_vm._v("岐阜県岐阜市加納桜田町2-5-2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("電話番号")]),_c('td',[_vm._v("058-214-9005")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("メールアドレス")]),_c('td',[_vm._v("inquiry@adtasukaru.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("販売URL")]),_c('td',[_vm._v("https://adtasukaru.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("お支払い方法")]),_c('td',[_vm._v("クレジットカード")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("商品代金以外に必要な費用"),_c('br'),_vm._v("（送料、手数料、消費税等）")]),_c('td',[_vm._v("消費税")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("継続課金の解約条件・解約方法")]),_c('td',[_vm._v(" 解約は、アドタスカル管理画面から「継続課金(自動更新)の設定」を解除することで常時可能です。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("返品について")]),_c('td',[_vm._v("購入後の返品はできません。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("表現、及び、商品に関する注意書き")]),_c('td',[_vm._v(" 当サービスは、必ずしも効果や利益を保証したものではございません。 ")])])
}]

export { render, staticRenderFns }