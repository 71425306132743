import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.prototype.$store = store;
import Element from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
import VueHead from "vue-head";
import VueAwesomeCountdown from 'vue-awesome-countdown'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

//headを読み込み
var head = document.getElementsByTagName('head');

//gmoのjsを追加
var script = document.createElement('script');
if (process.env.NODE_ENV !== 'production') { //テスト環境
  script.src = 'https://stg.static.mul-pay.jp/ext/js/token.js';
} else { //本番環境
  script.src = 'https://static.mul-pay.jp/ext/js/token.js';
}
head[0].appendChild(script);

//AXIOS初期共通処理
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
Vue.prototype.$axios = axios;

//Vue.filter
Vue.filter('localeNum', function (val) {
  let int = parseInt(val);
  return int.toLocaleString()
})

Vue.config.productionTip = false
Vue.use(Element)
locale.use(lang)
Vue.use(VueHead)

// 
Vue.use(VueAwesomeCountdown)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')