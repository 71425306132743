/**
 * ページタイトル設定
 * @param pathTitle
 * @returns {string}
 */
export const setTitle = pathTitle => {
    const siteTitle = '';
    const pageTitle = !pathTitle ? siteTitle : pathTitle;
    return (window.document.title = pageTitle);
};

/**
 * Description設定
 * @param pathMeta
 */
export const setDescription = pathMeta => {
    const defaultDescription = 'This is initial description.';
    const description = pathMeta ? pathMeta : defaultDescription;
    document
        .querySelector("meta[name='description']")
        .setAttribute('content', description);
};

/**
 * Description設定
 */
export const setRobots = rootUrl => {
    // 
    let content = 'all';

    // 
    if (rootUrl === 'https://adtasukaru.com') {
        // no action.
    } else {
        content = 'noindex';
    }

    // 
    document
        .querySelector("meta[name='robots']")
        .setAttribute('content', content);
};

/**
 * Twitter設定
 * @param metaName
 */
export const setTwitterConfig = metaName => {
    let twitterTitle = "";
    let twitterDescription = "";
    let twitterImage = "";
    switch (metaName) {
        case "google-cv":
            twitterTitle = "Google広告とASPの成果乖離をなくす画期的なツール";
            twitterDescription = "CVタグの設置不要なのに、ASPの成果がGoogle広告に100％反映される。もうCVタグの発火で悩むの辞めませんか？";
            twitterImage = "https://adtasukaru.com/img/lp/google-cv/twitter-banner.png";
            break;
        case "yahoo-cv":
            twitterTitle = "Yahoo!検索広告とASPの成果乖離をなくす画期的なツール";
            twitterDescription = "CVタグの設置不要なのに、ASPの成果がYahoo!検索広告に100％反映される。もうCVタグの発火で悩むの辞めませんか？";
            twitterImage = "https://adtasukaru.com/img/lp/yahoo-cv/twitter-banner.png";
            break;

        default:
            twitterTitle = "広告媒体管理画面とASPの成果乖離をなくす画期的なツール";
            twitterDescription = "CVタグの設置不要なのに、ASPの成果がGoogle広告やYahoo!検索広告に100％反映される。もうCVタグの発火で悩むの辞めませんか？";
            twitterImage = "https://adtasukaru.com/img/lp/twitter-banner.png";
            break;
    }
    document
        .querySelector("meta[name='twitter:text:title']")
        .setAttribute('content', twitterTitle);
    document
        .querySelector("meta[name='twitter:title']")
        .setAttribute('content', twitterTitle);
    document
        .querySelector("meta[name='twitter:description']")
        .setAttribute('content', twitterDescription);
    document
        .querySelector("meta[name='twitter:image']")
        .setAttribute('content', twitterImage);
};


export const globalMixins = {
    methods: {
        setTitle,
        setDescription,
    },
};
