<template>
  <div class="popup01">
    <div class="MAIN_IMG">
      <div class="corporate-name">
        <p>提供：株式会社グレイトヘルプ</p>
      </div>
      <img
        class="main_img pc"
        :src="rt + '/img/lp/main_img_popup.jpg'"
        alt="アドタスカル離脱防止ポップアップメインイメージ画像"
      />
      <img
        class="main_img sp"
        :src="rt + '/img/lp/popup_mainSP.jpg'"
        alt="アドタスカル離脱防止ポップアップメインイメージ画像"
      />
    </div>

    <div class="wrap point-space">
      <Trial00 />
    </div>

    <div class="bg_color">
      <div class="wrap">
        <h1 class="pc">広告成果連携ツール「アドタスカル」とは</h1>
        <h1 class="sp">広告成果連携ツール<br />「アドタスカル」とは</h1>

        <div class="about_adtasukaru">
          <About />
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="title pc">ポップアップでパフォーマンス改善</div>
      <div class="title sp">ポップアップで<br />パフォーマンス改善</div>
      <div class="about_cover">
        <div class="about">
          <p class="about_title">
            離脱防止ポップアップと特定位置登場ポップアップでCV改善を実現
          </p>
          <p class="point_txt">
            広告成果連携ツール「アドタスカル」の離脱防止ポップアップと特定位置で登場するポップアップを記事広告やウェブサイトに設置するだけで、パフォーマンス改善を実現できます。ユーザーの行動によって表示するポップアップを変えること、A/Bテスト機能によって改善を追求することもできます。しかも、価格は業界最安値水準で、導入もタグ設置のみでカンタンです。
          </p>
        </div>
        <img :src="rt + '/img/lp/sp-image.png'" alt="" />
      </div>

      <div class="about_cover">
        <div class="about_popup">
          <p class="title">離脱防止ポップアップとは</p>
          <p>
            離脱防止ポップアップとは、広告サイトを訪問しているユーザーが広告サイトから去ろうとし、ブラウザの「戻るボタン」を押した際、ポップアップを表示することで、ユーザーの広告サイトからの離脱を防止し、広告のパフォーマンスを向上させるためのツールです。
          </p>
        </div>
        <img
          class="about_img pc"
          :src="rt + '/img/lp/popup_step02.png'"
          alt="離脱防止ポップアップ解説画像"
        />
        <img
          class="about_img sp"
          :src="rt + '/img/lp/popup_step02_sp.png'"
          alt="離脱防止ポップアップ解説画像"
        />
      </div>
    </div>

    <div class="bg_color">
      <div class="wrap">
        <div class="pc">
          <div class="popup_point">
            <img
              class="popup_point_img"
              :src="rt + '/img/lp/popup_step03.png'"
              alt="離脱防止ポップアップ解説画像"
            />
            <div class="popup_point_txt">
              <p class="title">離脱防止ポップアップのポイント</p>
              <img
                class="popup_point_icon"
                :src="rt + '/img/lp/point01.png'"
                alt="ポイント01"
              />
              <p class="popup_point_title">
                見てほしいページやコンテンツへのリンクを自由に指定できる！
              </p>
              <img
                class="popup_point_icon"
                :src="rt + '/img/lp/point02.png'"
                alt="ポイント02"
              />
              <p class="popup_point_title">
                ポップアップに設定したリンク先をクリックせず、閉じるボタンから閉じた場合も、ページ内にアクセスが残る！
              </p>
            </div>
          </div>
        </div>

        <div class="sp">
          <div class="popup_point_txt">
            <p class="title point_style01">
              離脱防止ポップアップ<br />のポイント
            </p>
            <img
              class="popup_point_icon"
              :src="rt + '/img/lp/point01.png'"
              alt="ポイント01"
            />
            <p class="popup_point_title">
              見てほしいページやコンテンツへのリンクを自由に指定できる！
            </p>
            <div class="popup_point_img">
              <img
                :src="rt + '/img/lp/popup_step03_sp.png'"
                alt="離脱防止ポップアップ解説画像"
              />
            </div>
            <img
              class="popup_point_icon"
              :src="rt + '/img/lp/point02.png'"
              alt="ポイント02"
            />
            <p class="popup_point_title">
              リンクをクリックせず、閉じるボタンから閉じた場合も、ページ内にアクセスが残る！
            </p>
          </div>
        </div>

        <p class="txt_size point">
          さらには、ユーザーの行動に合わせて、表示するポップアップやリンク先を変更することもできます。ファーストビューで離脱したユーザーにはポップアップAを表示し、ページの途中で離脱したユーザーにはポップアップBを表示し、ページ下部まで到達したユーザーにはポップアップCを表示する、というように、1ページにき最大で3個のポップアップを設定することができます。
        </p>
      </div>
    </div>

    <div class="yajirushi_mark">
      <img :src="rt + '/img/lp/yajirushi_mark.png'" alt="矢印マーク" />
    </div>

    <div class="wrap">
      <img
        class="secchi_img pc"
        :src="rt + '/img/lp/secchi_img.png'"
        alt="説明図"
      />
      <img
        class="secchi_img sp"
        :src="rt + '/img/lp/secchi_img_sp.png'"
        alt="説明図"
      />
      <div class="pc">
        <!-- <p class="title">
          離脱防止ポップアップを設置したサイト
        </p> -->
      </div>
      <div class="sp">
        <!-- <p class="title">
          離脱防止ポップアップを<br>設置したサイト
        </p> -->
      </div>
      <p class="txt_size">
        そして、ユーザーが到達した最下部の位置を判別してポップアップを表示する設定と、ユーザーが離脱時に滞在していた位置を判別してポップアップを表示する設定を選択することもできます。
      </p>
      <p class="txt_size">
        広告サイト全体の訪問者数に対して、戻るボタンを押すユーザーは、およそ50～70%程度の割合です。そのため、広告サイト訪問者の50～70%の離脱をポップアップにて防ぐことができます。離脱せずに広告サイト内に残ったアクセスがコンバージョンすることで、パフォーマンスが改善します。また、ポップアップ上で、キャッシュポイントや、見てもらいたいコンテンツへと誘導するリンクも設置することができるので、そのままコンバージョンへとつながり、パフォーマンス改善が期待できます。
      </p>
      <FormButton
        class="pinpoint_style"
        :ButtonText="'ポップアップのお申し込みはこちら'"
        :link="'#Trial'"
      />
    </div>

    <div class="bg_color pinpoint">
      <div class="wrap">
        <p class="title pc">特定位置登場ポップアップとは</p>
        <p class="title sp">特定位置登場<br />ポップアップとは</p>
        <p class="txt_size">
          特定位置登場ポップアップとは、サイトを訪問しているユーザーが特定の位置に到達したら、指定のポップアップを表示して、訴求したい内容を告知したり、見せたいコンテンツへ誘導したりすることで、パフォーマンスを向上できるツールです。
        </p>

        <div class="shitei_img">
          <img
            class="pc"
            :src="rt + '/img/lp/shieti_popup00.png'"
            alt="特定位置登場ポップアップ解説図"
          />
          <img
            class="sp"
            :src="rt + '/img/lp/shieti_popup00_sp.png'"
            alt="特定位置登場ポップアップ解説図"
          />
        </div>

        <div class="shitei_txt">
          <div class="shitei_txt_cover">
            <img
              class="point_icon"
              :src="rt + '/img/lp/point_icon.png'"
              alt="pointアイコン"
            />
            <p class="title">表示される到達位置も自由に設定可能！</p>
          </div>
          <p class="txt_size">
            「ここまで読んでくれた閲覧者のみに表示する」というように、ポップアップを表示させる到達位置を自由に設定できます。特定位置登場ポップアップを導入することで、読ませたいユーザーに読ませたいコンテンツを見せることができ、パフォーマンス改善へとつながります。
          </p>
        </div>

        <p class="pin_style2">
          ここまで説明したとおり、離脱防止と特定位置登場の２つのポップアップをアドタスカルではご用意しています。
        </p>
      </div>
    </div>

    <div class="wrap">
      <div class="ResultAndEffect">
        <p class="title pc">アドタスカルは成果計測とA/Bテスト機能で改善効果◎</p>
        <p class="title sp">
          アドタスカルは
          <br />成果計測とテストで<br />改善効果◎
        </p>
        <p class="txt_size pin_style3">
          アドタスカルのポップアップは、コンバージョン測定機能、A/Bテスト機能、パラメータ保持機能があるため、成果計測と改善がしやすいのも大きな特徴です。
        </p>

        <Template02
          :TemplateTitle="'コンバージョン測定機能'"
          :DataName="'captcha.png'"
          :altText="'ダミー画像です'"
          :TemplateText="'コンバージョン測定機能で発行したコンバージョンタグをサンクスページに設置することで、コンバージョンの計測をすることができます。'"
        />
        <Template02
          :TemplateTitle="'A/Bテスト機能'"
          :DataName="'captcha.png'"
          :altText="'ダミー画像です'"
          :TemplateText="'アドタスカルはA/Bテスト機能を搭載しているため、テストによってパフォーマンス改善を追求することができます。A/Bテスト機能を利用することで、広告スキルやコンテンツ制作スキルもアップします。'"
        />
        <!-- <Template02
          :TemplateTitle="'パラーメター保持機能'"
          :DataName="'captcha.png'"
          :altText="'ダミー画像です'"
          :TemplateText="'パラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入る'"
        /> -->
      </div>

      <FormButton
        class="pinpoint_style"
        :ButtonText="'ポップアップのお申し込みはこちら'"
        :link="'#Trial'"
      />
    </div>

    <div class="bg_color">
      <div class="wrap">
        <!-- <PopupFunction /> -->
      </div>
    </div>

    <Installation />

    <BusinessOwner />
    <div class="bg_color">
      <div class="wrap">
        <p class="title pinpoint02">こんな方法で収益アップを実現しています</p>
        <div class="up_cover">
          <div class="up">
            <img
              class="up_icon"
              :src="rt + '/img/lp/up.png'"
              alt="タイトルアイコン"
            />
            <p class="feature_title pc">
              自社の広告やサイトに設置して<br />パフォーマンス改善を実現！
            </p>
            <p class="feature_title sp">
              自社の広告やサイトに設置してパフォーマンス改善を実現！
            </p>
            <p>
              記事広告、SEOサイト、メディア、自社のホームページ、ブログなど、自社の媒体にアドタスカルを設置して、パフォーマンス改善による収益を実現することができます！
            </p>
          </div>
          <div class="up">
            <img
              class="up_icon"
              :src="rt + '/img/lp/up.png'"
              alt="タイトルアイコン"
            />
            <p class="feature_title pc">
              クライアントのサイトに導入し、クライアントからマネタイズ！
            </p>
            <p class="feature_title sp">
              クライアントのサイトに導入し、クライアントからマネタイズ！
            </p>
            <p>
              クライアントのサイトにアドタスカルのポップアップを導入することで、パフォーマンス改善の対価としてクライアントからマネタイズすることも、アドタスカルの利用料としてクライアントからマネタイズすることもできます！対クライアント向けの事業として、新しい収益をアドタスカルで作ることができます！
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="Trial">
      <div class="wrap" id="cost">
        <p class="title">ご利用料金</p>
        <p>
          アドタスカルは、当ページで紹介したポップアップ以外にも、広告・ウェブサイトの改善を実現できるツールを販売しています。
        </p>
        <div class="other_tools">
          <p class="title">その他の広告・広告成果連携ツール</p>
          <div class="cover">
            <p>オーバーレイ表示</p>
            <p>Goole広告成果連携ツール</p>
          </div>
        </div>
        <Cost />
      </div>
      <div class="bg-color-blue">
        <div class="wrap">
          <GreatValue />
        </div>
      </div>

      <div class="wrap">
        <div class="bounus_point">
          <p class="title">使えば使うほど得するボーナスポイントもあります！</p>
          <p class="txt_size">
            アドタスカルを使用すると、以下のボーナスポイントも付与されます。
          </p>
          <BonusPoint />
        </div>
      </div>
    </div>

    <div class="bg_color">
      <div class="wrap">
        <CostQA />
      </div>
    </div>

    <div class="wrap">
      <div id="price"></div>
      <div class="Trial_plan">
        <p class="title pointstyle">
          まずは初回100円でアドタスカルをお試しください！
        </p>
        <Trial />
      </div>
    </div>

    <div class="bg_color">
      <div class="wrap">
        <Lineup />
      </div>
    </div>
  </div>
</template>

<script>
import Lineup from "@/components/ToolLineup.vue";
import BusinessOwner from "@/components/BusinessOwner.vue";
import FormButton from "@/components/FormButton.vue";
// import PopupFunction from "@/components/PopupFunction.vue";
import Installation from "@/components/PopupInstallation.vue";
import Template02 from "@/components/Template02.vue";
import Trial from "@/components/Trial.vue";
import Cost from "@/components/Cost.vue";
import About from "@/components/AboutAdtasukaru.vue";
import BonusPoint from "@/components/BonusPoint.vue";
import CostQA from "@/components/CostQA.vue";
import Trial00 from "@/components/Trial00.vue";
import GreatValue from "@/components/GreatValue.vue";

export default {
  components: {
    Lineup,
    FormButton,
    BusinessOwner,
    // PopupFunction,
    Installation,
    Template02,
    Trial,
    Cost,
    CostQA,
    About,
    BonusPoint,
    Trial00,
    GreatValue,
  },
  data() {
    return {
      tool: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.MAIN_IMG {
  img {
    width: 100%;
  }
}
.wrap {
  margin-top: 20px !important;
}
.title {
  margin-top: 100px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 20px;
    margin-top: 60px;
    // margin-bottom: 50px;
    // margin-bottom: 50px;
  }
}

.popup01_step {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 75px 0;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    padding: 30px 0;
  }
  .img03 {
    width: 280px;
    @media screen and (max-width: $max-width) {
      width: 60%;
      padding-bottom: 20px;
    }
  }
  .img04 {
    width: 500px;
    @media screen and (max-width: $max-width) {
      width: 60%;
    }
  }
  .img05 {
    width: 80%;
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
  }
}
.feature {
  display: flex;
  align-items: center;
  .feature_title {
    font-size: 28px;
    font-weight: bold;
  }
  img {
    width: 50px;
    margin-right: 30px;
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
  }
}
.pinpoint {
  padding-bottom: 100px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 70px;
  }
  .title {
    @media screen and (max-width: $max-width) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}
.pinpoint_style {
  margin: 80px 0 100px;
  @media screen and (max-width: $max-width) {
    font-size: 23px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.pinpoint02 {
  @media screen and (max-width: $max-width) {
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

#Trial {
  padding-top: 5px !important;
}
.timing {
  width: 100%;
}
.little_title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-top: 100px;
  @media screen and (max-width: $max-width) {
    font-size: 23px;
    margin-top: 60px;
  }
}

.shitei_cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .shitei_txt {
    width: 50%;
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
    .shitei_txt_img {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 50px 0 70px;
      .img_txt {
        width: 60%;
        @media screen and (max-width: $max-width) {
          width: 100%;
        }
        .txt {
          padding: 20px;
          margin-top: 10px;
          background-color: #e1ecf6;
          border-radius: 15px;
          margin-bottom: 0;
        }
      }
    }
    .title {
      font-size: 28px;
      text-align: left;
      margin: 0;
      padding: 0 0 15px;
      border: none;
    }
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.secchi_img {
  margin: 80px 0;
  @media screen and (max-width: $max-width) {
    width: 100%;
    margin-top: 30px;
  }
}

.about_cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 40px;
  }
  .about_popup {
    width: 520px;
    background-color: #e1ecf6;
    padding: 30px 0 40px;
    border-radius: 15px;
    @media screen and (max-width: $max-width) {
      width: 100%;
      padding: 15px 18px;
    }
    p:last-child {
      width: 420px;
      margin: 0 auto;
      @media screen and (max-width: $max-width) {
        width: 100%;
      }
    }
    .title {
      margin: 0 0 20px;
      border: none;
      font-size: 24px;
    }
  }
  .about_img {
    width: 500px;
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
  }
}

.about_cover:last-child {
  .about_img {
    @media screen and (max-width: $max-width) {
      margin: 50px auto;
    }
  }
}

.other_tools {
  font-weight: bold;
  margin: 50px 0 40px;
  background-color: #f1f6fa;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  // width: 800px;
  p {
    color: #1c6db5;
    font-size: 20px;
    margin-top: 40px;
    @media screen and (max-width: $max-width) {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  p:last-child {
    margin-left: 180px;
    @media screen and (max-width: $max-width) {
      margin-left: 0;
      margin-top: 0;
    }
  }
  .title {
    font-size: 22px;
    color: #1c6db5;
    border: none;
    margin: 0;
    padding: 0;
    margin-top: -30px;
    @media screen and (max-width: $max-width) {
      font-size: 18px;
    }
  }
  .cover {
    display: flex;
    justify-content: center;
    @media screen and (max-width: $max-width) {
      display: block;
    }
  }
}

.popup_point {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  .popup_point_txt {
    width: 72%;
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
  }
  .title {
    text-align: left;
    margin: 0 0 20px;
    border: none;
  }
}
.popup_point_icon {
  margin: 0;
  @media screen and (max-width: $max-width) {
    width: 75px;
  }
}
.popup_point_title {
  font-size: 25px;
  color: #1c6db5;
  font-weight: bold;
  margin-top: 5px;
  @media screen and (max-width: $max-width) {
    font-size: 20px;
  }
}
.popup_point_img {
  width: 25%;
  @media screen and (max-width: $max-width) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  img {
    width: 75%;
    height: 75%;
  }
}
.point {
  padding-bottom: 100px;
}

.wrap {
  margin-top: 80px;
}

.yajirushi_mark {
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

.Trial_plan {
  margin-top: 80px;
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
  .title {
    margin-top: 0;
  }
}
.pin_style {
  color: #1c6db5;
}
.pin_style2 {
  margin: 50px 0 0;
  font-size: 18px;
}

.pin_style3 {
  margin-bottom: 70px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

// #Trial {
//   padding-top: 50px;
//   margin-top: -50px;
// }

.shitei_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0 100px;
  @media screen and (max-width: $max-width) {
    margin: 50px 0;
  }
  img {
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
  }
}

.shitei_txt_cover {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $max-width) {
    display: block;
    text-align: left;
  }
}

.shitei_txt {
  .title {
    border: none;
    margin: 0;
    padding-left: 30px;
    @media screen and (max-width: $max-width) {
      padding-left: 0;
      text-align: left;
    }
  }
}

.point_style01 {
  margin-top: 30px;
}

.bounus_point {
  padding-bottom: 100px;
}
.bg-color-blue {
  padding-top: 80px;
}
</style>
