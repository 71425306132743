<template>
  <div class="overlay">
    <div class="MAIN_IMG">
      <div class="corporate-name">
        <p>提供：株式会社グレイトヘルプ</p>
      </div>
      <img
        class="main_img pc"
        :src="rt + '/img/lp/overlay_main.jpg'"
        alt="アドタスカルトップページメインイメージ画像"
      />
      <img
        class="main_img sp"
        :src="rt + '/img/lp/overlay_mainSP.jpg'"
        alt="アドタスカルトップページメインイメージ画像"
      />
    </div>

    <div class="wrap point-space">
      <Trial00 />
    </div>

    <div class="bg_color">
      <div class="wrap">
        <h1 class="pc">オーバーレイ表示でパフォーマンス改善</h1>
        <h1 class="sp">
          オーバーレイ表示で
          <br />パフォーマンス改善
        </h1>

        <div class="about_adtasukaru">
          <About />
          <p class="txt_size">
            ウェブ広告改善ツール「アドタスカル」のオーバーレイ表示を広告サイトに設置するだけで、広告のパフォーマンス改善を実現することができます。A/Bテスト機能についていて、しかも、価格は業界最安値水準、ワンタグ設置で導入もカンタンです。
          </p>
        </div>
      </div>
    </div>

    <div class="wrap">
      <p class="title">オーバーレイ表示とは</p>
      <p class="txt_size">
        オーバーレイ表示とは、広告サイト上のどこを見ているときでも、常に固定の位置にボタンやテキストを表示し、見せたいページやコンテンツへユーザーを誘導したり、閲覧時の臨場感をかき立てたりすることができるツールです。
      </p>

      <div class="about_overlay01">
        <div>
          <p>ボタンタイプのオーバーレイ表示</p>
          <img
            class="img"
            :src="rt + '/img/lp/overlay_01_sp.png'"
            alt="オーバーレイ解説画像"
          />
        </div>

        <div>
          <p>テキストタイプのオーバーレイ表示</p>
          <img
            class="img"
            :src="rt + '/img/lp/overlay_02_sp.png'"
            alt="オーバーレイ解説画像"
          />
        </div>
      </div>

      <p class="padding-bottom txt_size">
        オーバーレイ表示は常にユーザーから見え、いつでもタップできるため、広告のパフォーマンス改善につながります。
      </p>
    </div>

    <!-- <div class="bg_color"> -->
    <div class="wrap" style="margin-top: 0">
      <div class="bool01">
        <p class="txt_size">
          また、オーバーレイ表示を任意のタイミングで表示させる設定もできます。たとえば、商品の紹介をした後、商品の公式サイトへのリンクを設置したオーバーレイを表示することができます。
        </p>

        <div class="about_overlay">
          <div class="cover01">
            <div class="center">
              <img
                class="img"
                :src="rt + '/img/lp/overlay_03.png'"
                alt="オーバーレイ解説画像"
              />
              <p>オーバーレイ表示なし</p>
            </div>
          </div>

          <img
            class="yajirushi pc"
            :src="rt + '/img/lp/yajirushi.png'"
            alt="矢印画像"
          />
          <img
            class="yajirushi sp"
            :src="rt + '/img/lp/yajirushi_tate.png'"
            alt="矢印画像"
          />

          <div class="cover02">
            <div class="center02">
              <img
                class="img pc"
                :src="rt + '/img/lp/overlay_04.png'"
                alt="オーバーレイ解説画像"
              />
              <img
                class="img sp"
                :src="rt + '/img/lp/overlay_04_sp.png'"
                alt="オーバーレイ解説画像"
              />
              <p>オーバーレイ表示あり</p>
            </div>
          </div>
        </div>
        <p class="txt_size" style="margin-bottom: 50px">
          さらには、閲覧者がその設定した位置より上に戻った場合、「オーバーレイの表示を止める」または「オーバーレイを表示したままにする」という細かな設定もすることができます。
        </p>
        <p class="txt_size" style="margin-bottom: 50px">
          アドタスカルのオーバーレイ表示では、「ボタンタイプ」と「テキストタイプ」のオーバーレイ表示を選択して、設定することができます。
        </p>

        <div class="about_overlay01">
          <div>
            <p>ボタンタイプのオーバーレイ表示</p>
            <img
              class="img"
              :src="rt + '/img/lp/btn_type.png'"
              alt="オーバーレイ解説画像"
            />
          </div>

          <div>
            <p>テキストタイプのオーバーレイ表示</p>
            <img
              class="img"
              :src="rt + '/img/lp/txt_type.png'"
              alt="オーバーレイ解説画像"
            />
          </div>
        </div>
        <!-- <div class="overlay_type">
            <img class="up_icon" :src="rt+'/img/lp/btn_type.png'" alt="タイトルアイコン" />
            <img class="up_icon" :src="rt+'/img/lp/txt_type.png'" alt="タイトルアイコン" />
          </div> -->

        <p class="txt_size" style="margin-bottom: 50px">
          「ボタンタイプ」は、リンク先を自由に指定することができます。「テキストタイプ」は、ご自由にテキストを設定することはもちろん、テキストと合わせて数値も設定し、その数値を自動で増減させることまでできます。
        </p>

        <FormButton
          :ButtonText="'オーバーレイのお申し込みはこちら'"
          :link="'#Trial'"
        />
      </div>
    </div>
    <!-- </div> -->

    <div class="bg_color">
      <div class="wrap" style="padding-bottom: 70px">
        <p class="title">オーバーレイ表示による効果</p>
        <p class="txt_size">
          広告サイトによって、オーバーレイボタンのクリック率は異なりますが、当事例では、全体の訪問者数に対して、オーバーレイボタンを押したユーザーは全体の8.3%程度で、成約数は対前月比で+16.8%となりました。
        </p>
        <!-- <img class="effect_img" :src="rt+'/img/lp/popup_effect_img.jpg'" alt="オーバーレイ表示による効果の図解画像" /> -->
      </div>
    </div>

    <!-- <div class="bg_color"> -->
    <div class="wrap">
      <div class="ResultAndEffect">
        <p class="title pc">アドタスカルは成果計測とA/Bテスト機能で改善効果◎</p>
        <p class="title sp">
          アドタスカルは
          <br />成果計測とテストで<br />改善効果◎
        </p>

        <Template02
          :TemplateTitle="'コンバージョン測定機能'"
          :DataName="'captcha.png'"
          :altText="'ダミー画像です'"
          :TemplateText="'コンバージョン測定機能で発行したコンバージョンタグをサンクスページに設置することで、コンバージョンの計測をすることができます。'"
        />
        <Template02
          :TemplateTitle="'A/Bテスト機能'"
          :DataName="'captcha.png'"
          :altText="'ダミー画像です'"
          :TemplateText="'アドタスカルはA/Bテスト機能を搭載しているため、テストによってパフォーマンス改善を追求することができます。A/Bテスト機能を利用することで、広告スキルやコンテンツ制作スキルもアップします。'"
        />
        <!-- <Template02
          :TemplateTitle="'パラーメター保持機能'"
          :DataName="'captcha.png'"
          :altText="'ダミー画像です'"
          :TemplateText="'パラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入る'"
        /> -->
        <FormButton
          class="pinpoint_style"
          :ButtonText="'オーバーレイのお申し込みはこちら'"
          :link="'#Trial'"
        />
      </div>
    </div>
    <!-- </div> -->

    <div class="bg_color">
      <div class="wrap">
        <!-- <OverlayFunction /> -->
      </div>
    </div>

    <Installation />

    <BusinessOwner />
    <div class="bg_color pinpoint">
      <div class="wrap">
        <p class="title">こんな方法で収益アップを実現しています</p>
        <div class="up_cover">
          <div class="up">
            <img
              class="up_icon"
              :src="rt + '/img/lp/up.png'"
              alt="タイトルアイコン"
            />
            <p class="feature_title pc">
              自社の広告やサイトに設置して<br />パフォーマンス改善を実現！
            </p>
            <p class="feature_title sp">
              自社の広告やサイトに設置してパフォーマンス改善を実現！
            </p>
            <p>
              記事広告、SEOサイト、メディア、自社のホームページ、ブログなど、自社の媒体にアドタスカルを設置して、パフォーマンス改善による収益を実現することができます！
            </p>
          </div>
          <div class="up">
            <img
              class="up_icon"
              :src="rt + '/img/lp/up.png'"
              alt="タイトルアイコン"
            />
            <p class="feature_title pc">
              クライアントのサイトに導入し、クライアントからマネタイズ！
            </p>
            <p class="feature_title sp">
              クライアントのサイトに導入し、クライアントからマネタイズ！
            </p>
            <p>
              クライアントのサイトにアドタスカルのオーバーレイを導入することで、パフォーマンス改善の対価としてクライアントからマネタイズすることも、アドタスカルの利用料としてクライアントからマネタイズすることもできます！対クライアント向けの事業として、新しい収益をアドタスカルで作ることができます！
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="Trial">
      <div class="wrap" id="cost">
        <p class="title">ご利用料金</p>
        <p>
          アドタスカルは、当ページで紹介したオーバーレイ以外にも、広告・ウェブサイトの改善を実現できるツールを販売しています。
        </p>
        <div class="other_tools">
          <p class="title">その他の広告・広告成果連携ツール</p>
          <div class="cover">
            <p>ポップアップ</p>
            <p>Goole広告成果連携ツール</p>
          </div>
        </div>
        <Cost />
      </div>
      <div class="bg-color-blue">
        <div class="wrap">
          <GreatValue />
        </div>
      </div>

      <div class="wrap">
        <div class="bounus_point">
          <p class="title">使えば使うほど得するボーナスポイントもあります！</p>
          <p class="txt_size">
            アドタスカルを使用すると、以下のボーナスポイントも付与されます。
          </p>
          <BonusPoint />
        </div>
      </div>
    </div>

    <div class="bg_color">
      <div class="wrap">
        <CostQA />
      </div>
    </div>

    <div class="wrap">
      <div id="price"></div>
      <div class="Trial_plan">
        <p class="title pointstyle">
          まずは初回100円でアドタスカルをお試しください！
        </p>
        <Trial />
      </div>
    </div>

    <div class="bg_color">
      <div class="wrap">
        <Lineup />
      </div>
    </div>
  </div>
</template>

<script>
import Lineup from "@/components/ToolLineup.vue";
// import Respect from "@/components/OverlayRespectPoint.vue";
import BusinessOwner from "@/components/BusinessOwner.vue";
import FormButton from "@/components/FormButton.vue";
// import OverlayFunction from "@/components/OverlayFunction.vue";
import Cost from "@/components/Cost.vue";
import Installation from "@/components/OverlayInstallation.vue";
// import QA from "@/components/QA.vue";
import About from "@/components/AboutAdtasukaru.vue";
import Template02 from "@/components/Template02.vue";
import CostQA from "@/components/CostQA.vue";
import BonusPoint from "@/components/BonusPoint.vue";
import Trial00 from "@/components/Trial00.vue";
import Trial from "@/components/Trial.vue";
import GreatValue from "@/components/GreatValue.vue";

export default {
  components: {
    // Apply,
    Lineup,
    // Respect,
    BusinessOwner,
    FormButton,
    // OverlayFunction,
    Cost,
    Installation,
    // QA,
    About,
    Template02,
    CostQA,
    BonusPoint,
    Trial00,
    Trial,
    GreatValue,
  },
  data() {
    return {
      tool: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.MAIN_IMG {
  img {
    width: 100%;
  }
}
.wrap {
  margin-top: 80px;
  @media screen and (max-width: $max-width) {
    margin-top: 40px;
  }
}
.title {
  margin-top: 100px;
  @media screen and (max-width: $max-width) {
    margin-top: 50px;
    font-size: 24px !important;
  }
}
.btn {
  margin-top: 30px;
  text-align: center;
}

.about_adtasukaru {
  padding-bottom: 70px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 40px;
  }
}
.about_overlay {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 80px 0;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    margin: 50px 0;
  }
  .cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 525px;
    @media screen and (max-width: $max-width) {
      flex-wrap: wrap-reverse;
    }
    .img {
      width: 200px;
      @media screen and (max-width: $max-width) {
        width: 60%;
        margin: 30px auto 50px;
      }
    }
  }
  .cover01 {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // width: 460px;
    @media screen and (max-width: $max-width) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .img {
      width: 200px;
      @media screen and (max-width: $max-width) {
        width: 60%;
      }
    }
  }
  .yajirushi {
    @media screen and (max-width: $max-width) {
      margin-bottom: 20px;
      width: 60px;
    }
  }
  .cover02 {
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    // width: 600px;
    @media screen and (max-width: $max-width) {
      flex-wrap: wrap-reverse;
    }
    .img {
      width: 340px;
      @media screen and (max-width: $max-width) {
        width: 60%;
        // margin: 30px auto 80px;
      }
    }
    .comment {
      padding: 65px 32px 32px;
      @media screen and (max-width: $max-width) {
        padding: 40px 20px 32px;
        font-size: 17px;
        margin-bottom: 30px;
      }
    }
  }
  .cover:last-child {
    .img {
      @media screen and (max-width: $max-width) {
        margin: 30px auto;
      }
    }
  }

  .comment {
    width: 290px;
    height: 200px;
    background-size: 290px 200px;
    background-repeat: no-repeat;
    text-align: center;
    padding: 25px;
    box-sizing: border-box;
    @media screen and (max-width: $max-width) {
      width: 100%;
      height: auto;
      background-size: 100% 100%;
      padding: 30px 25px 40px;
    }
    p {
      font-weight: bold;
      margin: 0 auto;
      @media screen and (max-width: $max-width) {
        width: 70%;
        margin-bottom: 20px;
      }
    }
  }
}

.about_overlay01 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0 70px;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    margin-top: 0;
  }
  img {
    width: 65%;
    margin: 0 auto;
    @media screen and (max-width: $max-width) {
      width: 90%;
    }
  }
  p {
    text-align: center;
    font-weight: bold;
    // font-size: 30pxx;
  }
  div {
    margin-top: 30px;
    text-align: center;
  }
}

.bool01 {
  padding: 0 0 70px;
}
.QA {
  margin-bottom: 80px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 40px;
  }
}
.padding-bottom {
  padding-bottom: 60px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 40px;
  }
}
.yajirushi {
  width: 150px;
}
.center {
  p {
    font-weight: bold;
    font-size: 20px;
  }
}
.center02 {
  @media screen and (max-width: $max-width) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  p {
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
    @media screen and (max-width: $max-width) {
      text-align: center;
      margin-left: 0;
    }
  }
}
.AboutAdtasukaru {
  padding-bottom: 20px;
}
.ResultAndEffect {
  padding-bottom: 80px;
}
.other_tools {
  font-weight: bold;
  margin: 50px 0 40px;
  background-color: #f1f6fa;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  // width: 800px;
  p {
    color: #1c6db5;
    font-size: 20px;
    margin-top: 40px;
    @media screen and (max-width: $max-width) {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  p:last-child {
    margin-left: 180px;
    @media screen and (max-width: $max-width) {
      margin-left: 0;
      margin-top: 0;
    }
  }
  .title {
    font-size: 22px;
    color: #1c6db5;
    border: none;
    margin: 0;
    padding: 0;
    margin-top: -30px;
    @media screen and (max-width: $max-width) {
      font-size: 18px;
    }
  }
  .cover {
    display: flex;
    justify-content: center;
    @media screen and (max-width: $max-width) {
      display: block;
    }
  }
}
.overlay_type {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 80px 0;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    width: 100%;
    margin: 40px 0 0;
  }
  img {
    width: 30%;
    @media screen and (max-width: $max-width) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.bounus_point {
  padding-bottom: 100px;
}
#Trial {
  padding-top: 10px;
  margin-top: -10px;
}
</style>
