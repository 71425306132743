<template>
  <div class="Trial00">
    <div class="box-bgc">
      <p class="text01" style="margin-top: 0">
        <span v-if="tool.codeKebab === 'tools'">まずは初回100円で</span
        ><span v-else>まずは30日間、100円で</span><br /><span
          >アドタスカルをお試しください！</span
        >
      </p>
      <a
        v-scroll-to="'#register-and-settle'"
        href="#register-and-settle"
        class="link-btn st-reflection"
        >100円キャンペーンの詳細を見る</a
      >
      <p class="text02">
        <span class="cancel"
          >通常価格{{ productData.price | localeNum }}円</span
        >
        → 100円<br /><span v-if="tool.codeKebab === 'tools'"
          >アドタスカルの全ツール・全機能を</span
        ><span v-else>{{ tools[tool.codeCamel].name2 }}の全機能を</span
        ><br /><span v-show="tool.codeKebab !== 'tools'">30日間</span
        >お試しすることができます。
      </p>
      <p style="letter-spacing: 1.2px;">※<span class="red bold">特典</span>も付きます</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Trial00",
  components: {},
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools", "mainProductId"]),
    productData() {
      return this.$store.getters.productData(this.mainProductId);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.main_img {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 0;
  padding-top: 0;
  &:hover {
    opacity: 0.8;
  }
  @media screen and (max-width: $max-width) {
    padding-top: 0;
    margin-bottom: 0;
  }
}
.trial_btn {
  padding: 12px 50px;
  font-size: 25px;
  letter-spacing: 0.05em;
  color: white;
  background-color: #35a051;
  border-radius: 10px;
  margin: 80px auto 0;
  display: block;
  width: 450px;
  text-align: center;
  @media screen and (max-width: $max-width) {
    width: 100%;
    box-sizing: border-box;
    font-size: 17px;
    padding: 12px 30px;
    margin-top: 30px;
  }
}
.box-bgc {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  p {
    font-size: 23px;
    @media screen and (max-width: $max-width) {
      font-size: 20px;
    }
  }
  p.text01 {
    font-weight: bold;
    span {
      background: linear-gradient(transparent 60%, #ff6 60%);
    }
  }
  p.text02 {
    span.cancel {
      text-decoration: line-through;
    }
  }

  .red {
    color:#F56C6C;
  }

  .bold {
    font-weight: bold;
  }
  .spaces {
    padding: 0 0.2em;
  };
}
</style>
