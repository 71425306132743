<template>
  <div class="template012_cover">
      <img :src="rt+'/img/lp/icon.png'" alt="アドタスカルアイコン画像" />
      <p class="template012_title">{{TemplateTitle}}</p>
  </div>

</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "Template",
  props: {
    TemplateNumber: String,
    TemplateTitle: String,
    TemplateText: String
  },
  data() {
    return {
      rt: store.state.rt
    };
  }
};
</script>

<style lang="scss" scoped>
.template012_cover {
  display: flex;
  align-items: flex-start;
  padding-bottom: 50px;
  img {
    margin-right: 25px;
    width: 45px;
  }
  .template012_title {
    font-size: 26px;
    color: #1c6db5;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0.05em;
  }
}
</style>
