<template>
  <div class="tools02-voice">
    <div class="voice-cover">
      <div class="voice-img">
        <img :src="`${rt}/img/lp/koe01.png`" alt="利用者様からの声" />
      </div>
      <div
        class="voice"
        v-for="(voiceListData, index) in voiceList"
        :key="voiceListData.voiceId"
      >
        <div class="voice-header">
          <div class="voice-title-cover">
            <div class="volume">vol.{{index +1}}</div>
            <p class="voice-title" v-html="voiceListData.voiceTitle"></p>
          </div>
          <p class="name">{{ voiceListData.voiceName }}&nbsp;様</p>
        </div>
        <div class="txt" v-html="voiceListData.voiceText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Tools02VoiceVoiceBox from "@/components/Tools02/Voice/VoiceBox.vue";
export default {
  name: "tools02-voice",
  components: {
    // Tools02VoiceVoiceBox,
  },
  props: {
    voiceIdList: Array,
  },
  data() {
    return {
      index:1,
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    voiceList() {
      return this.$store.getters.voiceList(this.voiceIdList);
    },
  },
};
</script>

<style lang="scss" scoped>
.voice-cover {
  width: 900px;
  margin: 0 auto;
  margin-top: 60px;
  box-sizing: border-box;
  padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 5px;
  }
  .voice-img {
    text-align: right;
    @media screen and (max-width: $max-width) {
      text-align: center;
    }
    img {
      width: 300px;
    }
  }
  .voice {
    padding: 70px;
    background-color: white;
    margin-bottom: 60px;
    @media screen and (max-width: $max-width) {
      padding: 20px;
      padding-bottom: 50px;
    }
    .voice-header {
      display: flex;
      // justify-content: space-between;
      flex-direction: column;
      // align-items: flex-end;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding-top: 15px;
      margin-bottom: 10px;
      @media screen and (max-width: $max-width) {
        display: block;
        padding: 15px 0;
        margin-bottom: 0;
      }
      p {
        margin: 0;
      }
      .voice-title-cover {
        display: flex;
        align-items: stretch;
        @media screen and (max-width: $max-width) {
          display: block;
        }
        .volume {
          color: white;
          font-weight: bold;
          font-size: 18px;
          background-color: #1c6db5;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          margin-right: 10px;
          @media screen and (max-width: $max-width) {
            width: 100%;
            padding: 5px 0;
            min-height: 0;
            height: auto;
          }
        }

        .voice-title {
          font-weight: bold;
          font-size: 24px;
          color: #1c6db5;
          @media screen and (max-width: $max-width) {
            font-size: 22px;
            text-align: left;
            padding: 10px 0;
          }
        }
      }
    }
    .name {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.05em;
      text-align: right;
    }
  }
}
</style>
