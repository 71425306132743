<template>
  <div class="register3-plan-step">
    <el-row>
      <el-col>
        <el-steps :active="step">
          <el-step title="Step 1" description="お客様情報の入力・決済"></el-step>
          <el-step
            title="Step 2"
            description="ログイン情報の発行"
          ></el-step>
        </el-steps>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "UiPlanStep",
  components: {},
  props: {
    step: Number,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
p {
  font-weight: bold;
  font-size: 20px;
  margin: 40px;
  @media screen and (max-width: 767px) {
    margin: 0;
    margin-top: 20px;
  }
}

.el-steps {
  // color: #1c6db5;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
}

:deep(.el-step__description) {
  color: rgb(77, 77, 64) !important;
  font-size: 13px;
}
</style>