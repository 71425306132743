<template>
  <div class="login" style="padding-top:100px;">
    <Login/>
  </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import Login from '@/components/Login.vue';

export default {
  name: 'LoginHome',
  title: 'ログイン',
  components: {
    Login
  },
  data() {
    return {
      email: '',
      pass: '',
      test: 0,
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
