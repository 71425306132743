<template>
  <div class="lp-tag">
    <div style="text-align: center; margin: 25px auto">
      <a href="http://adtasukaru.com" target="_blank" rel="noopener noreferrer">
        <el-button type="primary">トップへ</el-button>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tools", "mainProductId"]),
  },
  created() {
    let bodyByAdtasukaru = document.getElementsByTagName("body");
    let script = document.createElement("script");
    script.src =
      "https://adtasukaru.com/api/tools02/add-parameter-to-target-links.php?user_id=3&password=&tool_id=14&unit_token=ziMI";
    bodyByAdtasukaru[0].appendChild(script);

    //
    this.logCookies();
  },
  methods: {
    logCookies() {
      console.log("===== ↓↓ cookieList ↓↓ =====");
      // let cookiesByAdtasukaru = document.cookie;
      // let cookiesArrayByAdtasukaru = cookiesByAdtasukaru.split(";");
      // let cookieList = [];
      // for (let c of cookiesArrayByAdtasukaru) {
      //   let cArray = c.split("=");
      //   // console.log(cArray);
      //   let cookieName = cArray[0].trim();
      //   let cookieValue = cArray[1].trim();
      //   let cookieData = {
      //     name: cookieName,
      //     value: cookieValue,
      //   };
      //   cookieList.push(cookieData);
      // }
      // console.log(cookieList);

      //
      let params = new URLSearchParams();
      params.append("document_cookie", document.cookie);
      this.$axios.post(`${this.rt}/api/test/log-document-cookie.php`, params);
    },
  },
};
</script>






<style lang="scss" scoped>
</style>
