<template>
  <!-- <div class="wrap"> -->
    <div class="QA">
      <p class="title">料金についてよくある質問</p>
      <div class="box_cover">
        <div class="QA_box">
          <p class="Q">Q</p>
          <p class="txt_q">アドタスカルのポイントを購入すると、アドタスカルの全てのツールが使用できるのですか？</p>
        </div>
        <div class="QA_box">
          <p class="A">A</p>
          <p class="txt">
            はい、できます。お好きなように全てのツールをお使いくださいませ。試しに少しだけ利用してみることももちろんできます。
          </p>
        </div>
      </div>

      <div class="box_cover">
        <div class="QA_box">
          <p class="Q">Q</p>
          <p class="txt_q">A/Bテスト機能やコンバージョン測定機能は、どのプランでも使用できますか？</p>
        </div>
        <div class="QA_box">
          <p class="A">A</p>
          <p
            class="txt"
          >はい、できます。すべての機能をいかなるプランでも使用していただくことが可能です。</p>
        </div>
      </div>

      <div class="box_cover">
        <div class="QA_box">
          <p class="Q">Q</p>
          <p class="txt_q">自動更新されるタイミングはいつでしょうか？</p>
        </div>
        <div class="QA_box">
          <p class="A">A</p>
          <p class="txt">アドタスカルは、ポイント残数がなくなったら、ポイントを購入していただくことで引き続きご利用できます。自動更新については、アドタスカルの管理画面にて、「ポイント残数が●ポイントになったら自動更新する」というように、ご希望のポイント残数で自動更新がされるように設定することができます。※自動更新されるタイミングの初期値はポイント残数が1,000ポイントになったときです。</p>
        </div>
      </div>
    <!-- </div> -->

  </div>

</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "QA",
  props: {},
  data() {
    return {
      rt: store.state.rt
    };
  }
};
</script>

<style lang="scss" scoped>

.QA {
  padding-bottom: 50px;
}

.b {
  font-weight: bold;

}
.title {
    margin: 0 0 50px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    margin-top: 0;
  }
}
.box_cover {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #ccc;
  .QA_box {
    display: flex;
    align-items: flex-start;
    .Q {
      font-size: 50px;
      font-weight: bold;
      color: #1c6db5;
      margin: 0;
      @media screen and (max-width: $max-width) {
        font-size: 40px;
      }
    }
    .A {
      font-size: 50px;
      font-weight: bold;
      color: #f15e62;
      color: #ccc;
      margin: 0;
      @media screen and (max-width: $max-width) {
        font-size: 40px;
      }
    }
    .txt_q {
      font-size: 22px;
      padding-left: 35px;
      font-weight: bold;
      color: #1c6db5;
      @media screen and (max-width: $max-width) {
        font-size: 19px;
        padding-left: 25px;
        margin-block-start: 0.5em;
      }
    }
    .txt {
      font-size: 18px;
      padding-left: 35px;
      @media screen and (max-width: $max-width) {
        font-size: 16px;
        padding-left: 25px;
        margin-block-start: 0.5em;
      }
    }
  }
}
.box_cover:last-child {
  border-bottom: none;
}
</style>
