<template>
  <div class="top-button">
    <!-- <div class="scroll-button"
    :class="{'over-baseline': scrollY < 200}">
      <div class="scroll">
      <span>scroll</span>
      </div>
    </div> -->
    <div
      class="to-top-button"
      :class="{ 'over-baseline': scrollY >= 2000 }"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: 0,
      }"
    >
      <div class="top">
        <span class="txt">▲</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiToTopButton",
  props: {},
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //スクロールすると実行される
      if (this.scrollTimer === null) {
        //初期値はnull
        this.scrollTimer = setTimeout(
          //ここでnullじゃなくてsetTimeoutになる
          function () {
            //実行するセット
            this.scrollY = window.scrollY; //ウィンドウトップからの画面距離
            clearTimeout(this.scrollTimer); //scrollTimerをリセット
            this.scrollTimer = null; //初期値に戻す
          }.bind(this), //第一引数
          200 //第二引数(ミリ秒後)←0.2秒後にはじまるっていう意味
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.to-top-button,
.scroll-button {
  display: none;
  position: fixed;
  width: 50px;
  height: 50px;
  color: white;
  z-index: 999;
}
.to-top-button {
  right: 5%;
  bottom: 8%;
  font-size: 24px;
  cursor: pointer;
}
.scroll-button {
  left: 50%;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  z-index: 999;

  span {
    font-size: 27px;
  }
}
.top,
.scroll {
  display: inline-block;
  padding-top: 70px;
  position: relative;
}
.scroll-button {
  z-index: 2000;
  color: white;
  font-size: 50px;
}
.over-baseline {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top {
  background-color: #1c6db5;
  padding: 7px 13px;
  border-radius: 5px;
  border: 1px solid #fff;
}
</style>